import { useContext } from 'react';

import { ReferralContext, referralContext } from './referral-context';

export const useReferralCode = (): ReferralContext => {
  const context = useContext(referralContext);
  if (!context)
    throw new Error(
      'Can only use useReferralCode inside of <ReferralProvider />'
    );

  return context;
};
