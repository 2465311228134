import { styled } from '@ui-furniture/style';

export const TextAreaContainer = styled.divBox`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 4px;
`;
export const TextArea = styled.textareaBox`
  border: none;
  outline: none;
  background: transparent;
  padding: ${({ theme }) => theme.space.md - 4}px;
  width: 100%;
  min-height: 120px;
  resize: vertical;
  :placeholder {
    color: #414f6580;
  }
  ::-webkit-resizer {
    opacity: 0;
    /* border: 9px solid rgba(0, 0, 0, 0.1);
    border-bottom-color: rgba(0, 0, 0, 0.5);
    border-right-color: rgba(0, 0, 0, 0.5);
    outline: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.1); */
  }
`;
export const TextAreaGrabber = styled.divBox`
  pointer-events: none;
  position: absolute;
  z-index: infront;
  content: '';
  right: 4px;
  bottom: 4px;
  width: 12px;
  height: 12px;
  background-color: white;
  background-image: url(/icons/grabber.svg);
`;
