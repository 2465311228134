import { useCallback, useState } from 'react';

export const useLoadingCallback = <
  TRequestFn extends (...args: any) => Promise<any>
>(
  action: TRequestFn,
  dependencyArray: Array<any> = []
): [boolean, TRequestFn] => {
  const [isLoading, setIsLoading] = useState(false);

  const fn = async (...args) => {
    try {
      setIsLoading(true);
      return await action(...args);
    } finally {
      setIsLoading(false);
    }
  };

  const run = useCallback(fn as TRequestFn, dependencyArray);

  return [isLoading, run];
};
