import { FC, HTMLAttributes } from 'react';

import { styled } from '@ui-furniture/style';

const StyledG = styled.g<{
  isActive: boolean;
}>`
  transition: default;
  transform-origin: center;
  transform: scale(${({ isActive }) => (isActive ? 1 : 0)});
`;

export const Diamond: FC<
  {
    isActive: boolean;
  } & HTMLAttributes<any>
> = ({ isActive, ...rest }) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...rest}
  >
    <g id='Diamond' clipPath='url(#clip0_1_5)'>
      <rect
        id='outer'
        x='-0.792893'
        y='11.935'
        width='18'
        height='18'
        rx='3.5'
        transform='rotate(-45 -0.792893 11.935)'
        stroke='#D0C9C7'
      />
      <StyledG isActive={isActive}>
        <rect
          id='inner'
          x='5.57107'
          y='11.935'
          width='9'
          height='9'
          rx='2'
          transform='rotate(-45 5.57107 11.935)'
          fill='#EB6E46'
        />
      </StyledG>
    </g>
    <defs>
      <clipPath id='clip0_1_5'>
        <rect
          width='23.7'
          height='23.7'
          fill='white'
          transform='translate(0.1 0.1)'
        />
      </clipPath>
    </defs>
  </svg>
);
