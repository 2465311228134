import React, { FC, ReactNode } from 'react';

import { Props } from '@xstyled/styled-components';

import useHasMounted from '@ui-furniture/lib/hooks/useHasMounted';
import { Breakpoint, useUp, x } from '@ui-furniture/style';

export const BreakpointContainer: FC<{
  breakpoint: Breakpoint;
  whenSmaller: ReactNode;
  largerContainerProps?: Props;
  smallerContainerProps?: Props;
  whenLarger: ReactNode;
  disableRemoval?: boolean;
}> = ({
  whenSmaller,
  whenLarger,
  breakpoint,
  smallerContainerProps,
  largerContainerProps,
  disableRemoval = false,
}) => {
  const hasMounted = useHasMounted();
  const shouldRemove = hasMounted && !disableRemoval;
  const isBig = useUp(breakpoint);

  return (
    <>
      {isBig && shouldRemove ? null : (
        <x.div
          key='small'
          display={{
            _: smallerContainerProps?.display || 'block',
            [breakpoint]: 'none',
          }}
          {...smallerContainerProps}
        >
          {whenSmaller}
        </x.div>
      )}
      {!isBig && shouldRemove ? null : (
        <x.div
          key='large'
          display={{
            _: 'none',
            [breakpoint]: largerContainerProps?.display || 'block',
          }}
          {...largerContainerProps}
        >
          {whenLarger}
        </x.div>
      )}
    </>
  );
};
