import { useApolloClient } from '@apollo/client';

import {
  AuthStatus,
  AuthStatusDocument,
  GetCurrentUserDocument,
  useGetCurrentUserQuery,
} from '@graphql/requests';

import { useRefreshSsrData } from '@ui-furniture/common/hooks/use-refresh-ssr-data';

export const useCurrentUser = () => {
  const query = useGetCurrentUserQuery({
    fetchPolicy: 'cache-and-network',
  });
  const client = useApolloClient();
  const refreshSsrData = useRefreshSsrData();

  const refresh = async () => {
    await refreshSsrData();
    await client.refetchQueries({
      include: [AuthStatusDocument, GetCurrentUserDocument],
    });
  };

  return {
    authStatus: query.data?.authStatus || AuthStatus.None,
    currentProfile: query.data?.currentProfile || null,
    isAuthenticated:
      query?.data?.authStatus !== undefined &&
      query?.data?.authStatus !== AuthStatus.None,
    items: query?.data?.items || [],
    hasAccount: query?.data?.authStatus === AuthStatus.AccountHolder,
    currentUser: query.data
      ? {
          ...query.data.self,
        }
      : null,
    refresh,
  };
};
