import { useCallback } from 'react';

import { errorService } from '@ui-furniture/common/services/error';
import { useToast } from '@ui-furniture/common/toast';
import { useIsOpen } from '@ui-furniture/lib/hooks/use-is-open';

import { useKycSessionToken } from './use-kyc-session-token';

import { applySteps } from '../common/apply-steps';

export const useKycApplication = ({
  onComplete,
}: {
  onComplete: () => void;
}) => {
  const { showToast } = useToast();
  const kycModal = useIsOpen();
  const { token, refetch } = useKycSessionToken();

  const onExpiredToken = useCallback(async () => {
    showToast({
      severity: 'warning',
      message:
        'Sorry, the document upload session expired. We are fetching you a new one',
    });

    kycModal.close();
    await refetch();

    showToast({
      severity: 'info',
      message: `We've created a new session token, so please try again. If the problem persists please refresh the page or contact support@housecure.co.uk`,
    });
  }, []);
  const onError = useCallback(async (message: string) => {
    errorService.captureException(new Error(`Kyc failed: ${message}`));
    showToast({
      severity: 'error',
      message: `The document upload session failed. We don't know why but we have recorded it and will investigate futher. In the meantime, please try again.`,
    });
  }, []);

  return {
    start: token.shouldSkipKyc ? onComplete : kycModal.open,
    canStart: token.hasValue,
    isInProgress: kycModal.isOpen,
    componentProps: {
      onComplete,
      token: token.value,
      isOpen: kycModal.isOpen,
      steps: applySteps,
      onClose: kycModal.close,
      onError: async (error) => {
        switch (error.type) {
          case 'expired_token': {
            await onExpiredToken();
            break;
          }
          default: {
            await onError(error.message);
            break;
          }
        }
      },
      onUserExit: () => {
        kycModal.close();
      },
    },
  };
};
