import loadable from '@loadable/component';

import { logger } from '@ui-furniture/common/services/logger';
import { VoidStyledComponent, x } from '@ui-furniture/style';

const variants = {
  none: {},
  circled: {
    borderRadius: '50%',
    border: '1px solid currentColor',
    padding: '8px',
  },
};
const sizeMap = {
  sm: '24px',
  md: '32px',
  lg: '48px',
  xl: '60px',
  '2xl': '120px',
};
const iconMap = {
  activity: loadable(() => import('@ui-furniture/assets/iconly/Activity.svg')),
  add: loadable(() => import('@ui-furniture/assets/icons/Add.svg')),
  approvedDocument: loadable(
    () => import('@ui-furniture/assets/icons/Approved Document.svg')
  ),
  arrowLeft: loadable(
    () => import('@ui-furniture/assets/iconly/Arrow - Left.svg')
  ),
  arrowRightCircle: loadable(
    () => import('@ui-furniture/assets/iconly/Arrow - Right Circle 1.svg')
  ),
  arrowRightCircleFull: loadable(
    () => import('@ui-furniture/assets/iconly/Arrow - Right Circle 2.svg')
  ),
  bank: loadable(() => import('@ui-furniture/assets/iconly/Bank.svg')),
  bankSquare: loadable(
    () => import('@ui-furniture/assets/iconly/Bank - Square.svg')
  ),
  bankCardReceive: loadable(
    () => import('@ui-furniture/assets/iconly/Bank Card Receive.svg')
  ),
  book: loadable(() => import('@ui-furniture/assets/iconly/Book.svg')),
  bookStackNextTo: loadable(
    () => import('@ui-furniture/assets/icons/Book Stack Next To.svg')
  ),
  bookStackOnTopOf: loadable(
    () => import('@ui-furniture/assets/icons/Book Stack On Top Of.svg')
  ),
  buyer: loadable(() => import('@ui-furniture/assets/iconly/Buyer.svg')),
  calendar: loadable(() => import('@ui-furniture/assets/iconly/Calendar.svg')),
  camera: loadable(() => import('@ui-furniture/assets/iconly/Camera.svg')),
  chart: loadable(() => import('@ui-furniture/assets/iconly/Chart.svg')),
  chartIncrease: loadable(
    () => import('@ui-furniture/assets/icons/Chart Increase.svg')
  ),
  check: loadable(() => import('@ui-furniture/assets/iconly/Check.svg')),
  checkCircle: loadable(
    () => import('@ui-furniture/assets/iconly/Check Circle.svg')
  ),
  cheers: loadable(() => import('@ui-furniture/assets/iconly/Cheers.svg')),
  chevronDown: loadable(
    () => import('@ui-furniture/assets/iconly/Arrow - Down 2.svg')
  ),
  chevronLeft: loadable(
    () => import('@ui-furniture/assets/iconly/Arrow - Left 2.svg')
  ),
  chevronRight: loadable(
    () => import('@ui-furniture/assets/iconly/Arrow - Right 2.svg')
  ),
  chevronUp: loadable(
    () => import('@ui-furniture/assets/iconly/Arrow - Up 2.svg')
  ),
  clockDash: loadable(
    () => import('@ui-furniture/assets/iconly/Clock Dash.svg')
  ),
  close: loadable(() => import('@ui-furniture/assets/iconly/Close Remove.svg')),
  cloudDownload: loadable(
    () => import('@ui-furniture/assets/iconly/Cloud Download.svg')
  ),
  coinStack: loadable(
    () => import('@ui-furniture/assets/icons/Coin Stack.svg')
  ),
  copy: loadable(() => import('@ui-furniture/assets/iconly/Copy.svg')),
  countdownCalendar: loadable(
    () => import('@ui-furniture/assets/icons/Countdown Calendar.svg')
  ),
  cross: loadable(() => import('@ui-furniture/assets/iconly/Close Remove.svg')),
  dashboard: loadable(() => import('@ui-furniture/assets/iconly/Category.svg')),
  delete: loadable(() => import('@ui-furniture/assets/iconly/Delete.svg')),
  document: loadable(() => import('@ui-furniture/assets/iconly/Document.svg')),
  documentBlank: loadable(
    () => import('@ui-furniture/assets/iconly/Document Blank.svg')
  ),
  documentInvestigation: loadable(
    () => import('@ui-furniture/assets/icons/Document Investigation.svg')
  ),
  documentLock: loadable(
    () => import('@ui-furniture/assets/iconly/Document Lock.svg')
  ),
  edit: loadable(() => import('@ui-furniture/assets/iconly/Edit.svg')),
  eye: loadable(() => import('@ui-furniture/assets/icons/Eye.svg')),
  emailGround: loadable(
    () => import('@ui-furniture/assets/iconly/Email Ground.svg')
  ),
  emailOpenWithMessage: loadable(
    () => import('@ui-furniture/assets/iconly/Email Open 3.svg')
  ),
  emailSent: loadable(
    () => import('@ui-furniture/assets/iconly/Email Sending.svg')
  ),
  errorDiamond: loadable(
    () => import('@ui-furniture/assets/iconly/Error - Diamond.svg')
  ),
  fail: loadable(
    () => import('@ui-furniture/assets/iconly/Remove Hexagonal.svg')
  ),
  fireworks: loadable(() => import('@ui-furniture/assets/icons/Fireworks.svg')),
  goodFaith: loadable(
    () => import('@ui-furniture/assets/iconly/Good Faith.svg')
  ),
  handshake: loadable(() => import('@ui-furniture/assets/icons/Handshake.svg')),
  hourglass: loadable(
    () => import('@ui-furniture/assets/iconly/Hourglass.svg')
  ),
  home: loadable(() => import('@ui-furniture/assets/iconly/Home.svg')),
  house: loadable(() => import('@ui-furniture/assets/iconly/House.svg')),
  homeSale: loadable(
    () => import('@ui-furniture/assets/iconly/Home - Sale.svg')
  ),
  houseContract: loadable(
    () => import('@ui-furniture/assets/icons/House Contract.svg')
  ),
  infoCircle: loadable(
    () => import('@ui-furniture/assets/iconly/Info Circle.svg')
  ),
  infoMenu: loadable(() => import('@ui-furniture/assets/iconly/Info Menu.svg')),
  infoSquare: loadable(
    () => import('@ui-furniture/assets/iconly/Info Square.svg')
  ),
  journey: loadable(
    () => import('@ui-furniture/assets/iconly/Journey - 2.svg')
  ),
  link: loadable(() => import('@ui-furniture/assets/iconly/Link.svg')),
  lock: loadable(() => import('@ui-furniture/assets/iconly/Lock.svg')),
  lockSquare: loadable(
    () => import('@ui-furniture/assets/iconly/Lock Square.svg')
  ),
  login: loadable(() => import('@ui-furniture/assets/iconly/Login.svg')),
  logout: loadable(() => import('@ui-furniture/assets/iconly/Logout.svg')),
  mail: loadable(() => import('@ui-furniture/assets/iconly/Mail.svg')),
  maze: loadable(() => import('@ui-furniture/assets/icons/Maze.svg')),
  materialChanges: loadable(
    () => import('@ui-furniture/assets/iconly/Material Changes.svg')
  ),
  mip: loadable(() => import('@ui-furniture/assets/icons/MiP.svg')),
  moneyBag: loadable(() => import('@ui-furniture/assets/icons/Money Bag.svg')),
  moreHorizontal: loadable(
    () => import('@ui-furniture/assets/iconly/More Horizontal.svg')
  ),
  noGaz: loadable(() => import('@ui-furniture/assets/iconly/No Gaz.svg')),
  options: loadable(() => import('@ui-furniture/assets/icons/Options.svg')),
  poundCircle: loadable(
    () => import('@ui-furniture/assets/iconly/Pound Circle.svg')
  ),
  patience: loadable(
    () => import('@ui-furniture/assets/iconly/Calendar Favorite.svg')
  ),
  paperPlane: loadable(() => import('@ui-furniture/assets/iconly/Send.svg')),
  paperBold: loadable(
    () => import('@ui-furniture/assets/iconly/Paper Bold.svg')
  ),
  personMagnified: loadable(
    () => import('@ui-furniture/assets/icons/Person Magnified.svg')
  ),
  pledge: loadable(() => import('@ui-furniture/assets/icons/Pledge.svg')),
  plus: loadable(() => import('@ui-furniture/assets/iconly/Plus 4.svg')),
  referral: loadable(
    () => import('@ui-furniture/assets/iconly/Give Stars.svg')
  ),
  rightArrowCircle: loadable(
    () => import('@ui-furniture/assets/iconly/Arrow - Right Circle.svg')
  ),
  touchId: loadable(() => import('@ui-furniture/assets/iconly/Touch Id.svg')),
  touchIdCheck: loadable(
    () => import('@ui-furniture/assets/iconly/Touch Id Check.svg')
  ),
  touchIdClose: loadable(
    () => import('@ui-furniture/assets/iconly/Touch Id Close.svg')
  ),
  userCreate: loadable(
    () => import('@ui-furniture/assets/iconly/Create User.svg')
  ),
  userDelete: loadable(
    () => import('@ui-furniture/assets/iconly/Delete User.svg')
  ),
  questionMarkCircle: loadable(
    () => import('@ui-furniture/assets/iconly/Question Mark Circle.svg')
  ),
  rocketUp: loadable(() => import('@ui-furniture/assets/icons/Rocket Up.svg')),
  rocket: loadable(() => import('@ui-furniture/assets/iconly/Rocket.svg')),
  stampedDocument: loadable(
    () => import('@ui-furniture/assets/icons/Stamped Document.svg')
  ),
  safe: loadable(() => import('@ui-furniture/assets/iconly/Safe.svg')),
  searchList: loadable(
    () => import('@ui-furniture/assets/iconly/Search list.svg')
  ),
  show: loadable(() => import('@ui-furniture/assets/iconly/Show.svg')),
  seller: loadable(() => import('@ui-furniture/assets/iconly/Seller.svg')),
  smallPlus: loadable(
    () => import('@ui-furniture/assets/iconly/Small Plus.svg')
  ),
  star: loadable(() => import('@ui-furniture/assets/iconly/Star.svg')),
  stars: loadable(() => import('@ui-furniture/assets/iconly/Stars.svg')),
  searchHome: loadable(
    () => import('@ui-furniture/assets/iconly/Search - Home.svg')
  ),
  stopWatch: loadable(
    () => import('@ui-furniture/assets/icons/Stop Watch.svg')
  ),
  shieldDone: loadable(
    () => import('@ui-furniture/assets/iconly/Shield Done.svg')
  ),
  success: loadable(
    () => import('@ui-furniture/assets/iconly/Success Diamond.svg')
  ),
  tasks: loadable(() => import('@ui-furniture/assets/iconly/Board Tasks.svg')),
  thumbsUp: loadable(() => import('@ui-furniture/assets/icons/Thumbs Up.svg')),
  thumbPrint: loadable(
    () => import('@ui-furniture/assets/icons/Thumbprint.svg')
  ),
  tick: loadable(() => import('@ui-furniture/assets/icons/Tick.svg')),
  tickSquare: loadable(
    () => import('@ui-furniture/assets/iconly/Tick Square.svg')
  ),
  thinking: loadable(() => import('@ui-furniture/assets/iconly/Thinking.svg')),
  undo: loadable(
    () => import('@ui-furniture/assets/iconly/Counter Clockwise Undo.svg')
  ),
  upload: loadable(() => import('@ui-furniture/assets/iconly/Upload File.svg')),
  urgency: loadable(() => import('@ui-furniture/assets/iconly/Urgency.svg')),
  userUpgrade: loadable(
    () => import('@ui-furniture/assets/iconly/User - Upgrade.svg')
  ),
  video: loadable(() => import('@ui-furniture/assets/iconly/Video.svg')),
  wallet: loadable(() => import('@ui-furniture/assets/iconly/Wallet.svg')),
  warn: loadable(
    () => import('@ui-furniture/assets/iconly/Error Triangle.svg')
  ),
  withdraw: loadable(() => import('@ui-furniture/assets/iconly/Turn Left.svg')),
};

export type IconName = keyof typeof iconMap;
export const iconOptions = Object.keys(iconMap);

export const LineIcon: VoidStyledComponent<{
  name: IconName;
  variant?: keyof typeof variants;
  size?: string;
}> = ({ name, variant = 'none', size = 'xl', ...wrapperProps }) => {
  const variantProps = variants[variant];
  const sizeValue = sizeMap[size] || size;
  const IconComponent = iconMap[name];
  if (!IconComponent) {
    logger.error(`No Icon was found for ${name}`);
    return null;
  }
  return (
    <x.div
      display='inline-block'
      w={sizeValue}
      h={sizeValue}
      minWidth={sizeValue}
      minHeight={sizeValue}
      {...variantProps}
      {...wrapperProps}
    >
      <IconComponent width='100%' height='100%' />
    </x.div>
  );
};
