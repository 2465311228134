import { useCallback, useMemo } from 'react';

import get from 'lodash/get';
import {
  RegisterOptions,
  SetValueConfig,
  useFormContext,
} from 'react-hook-form';

export const useFieldRegistration = (
  name: string,
  registerOptions?: RegisterOptions
) => {
  const formContext = useFormContext();
  const setValue = useCallback(
    (value?: string, options: SetValueConfig = {}) => {
      formContext.setValue(name, value, options);
    },
    []
  );
  const registration = formContext.register(name, registerOptions);
  const itemValue = formContext.watch(name);
  const error = get(formContext.formState.errors, name);
  const isDirty: boolean = get(formContext.formState.dirtyFields, name);
  const fieldState = useMemo(
    () => ({
      name,
      registerOptions,
      registration,
      value: itemValue,
      error,
      isDirty,
      setValue,
    }),
    [itemValue, error, isDirty]
  );
  return fieldState;
};
