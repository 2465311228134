import { motion } from 'framer-motion';

import { StyledComponent, x } from '@ui-furniture/style';

import { variants } from './variants';

export const clickStyleVariants = {
  none: {},
  scale: {
    transition: 'base',
    cursor: 'pointer',
    transform: true,
    boxShadow: { _: 'none', hover: 'md' },
    scale: { _: 1, hover: 1.02 },
  },
  basic: {
    borderColor: { _: 'palette-border', hover: 'primary' },
  },
};

export interface CardProps {
  clickStyleVariant?: keyof typeof clickStyleVariants;
  variant?: keyof typeof variants;
  isInsideCard?: boolean;
}

export const Card: StyledComponent<
  CardProps & {
    onClick?: () => void;
  }
> = ({
  children,
  clickStyleVariant = '',
  variant = 'regular',
  isInsideCard,
  ...rest
}) => (
  <x.div
    as={motion.div}
    display='inline-block'
    borderRadius='sm'
    border='1px solid transparent'
    {...variants[variant]}
    {...clickStyleVariants[clickStyleVariant]}
    {...(isInsideCard && { radii: '4px', w: '100%' })}
    {...rest}
  >
    {children}
  </x.div>
);
