import { FC } from 'react';

import Link from 'next/link';

import { AccountButton } from '@/features/account/ui/account-button';
import { x } from '@/style';

import { AuthStatus } from '@graphql/requests';

import Logo from '@ui-furniture/assets/logos/Logo.svg';
import { HStack } from '@ui-furniture/elements';
import { useAuthStatus } from '@ui-furniture/hooks/use-auth-status';
import { usePurchases } from '@ui-furniture/hooks/use-purchases';
import { SideBarIcon } from '@ui-furniture/ui/side-bar-icon';
import { Tooltip } from '@ui-furniture/ui/tooltip';

import { useSelectedRoute } from '../hooks/use-selected-route';

export const Header: FC = () => {
  const selectedRoute = useSelectedRoute();
  const authStatus = useAuthStatus();

  const { purchases } = usePurchases();
  const firstPurchaseFound = purchases[0];

  return (
    <x.nav
      aria-label='Main Navigation'
      p='md'
      w='100%'
      borderBottom='1px solid'
      borderBottomColor='brand-beige'
      backgroundColor='brand-bg-accent'
      position='sticky'
      top='0'
      zIndex='foreground'
    >
      <HStack justifyContent='space-between'>
        <HStack>
          <x.div w='side-bar-tile' h='side-bar-tile' p='4px'>
            <Logo width='100%' height='100%' />
          </x.div>
          <Tooltip tooltipContent='Dashboard' placement='right'>
            <Link href='/'>
              <SideBarIcon
                iconName={'dashboard'}
                isSelected={selectedRoute === 'home'}
              />
            </Link>
          </Tooltip>
          <Tooltip tooltipContent='Properties' placement='right'>
            <Link
              href='/properties'
              style={{ pointerEvents: !firstPurchaseFound ? 'none' : 'auto' }}
            >
              <SideBarIcon
                iconName={'house'}
                isSelected={selectedRoute === 'properties'}
                isDisabled={!firstPurchaseFound}
              />
            </Link>
          </Tooltip>
          <Tooltip tooltipContent='Products' placement='right'>
            <Link href='/launch'>
              <SideBarIcon
                iconName={'rocket'}
                isSelected={selectedRoute === 'launch'}
              />
            </Link>
          </Tooltip>
        </HStack>
        <HStack>
          <Tooltip tooltipContent='Refer for £30' placement='right'>
            <Link
              href='/refer'
              style={{
                pointerEvents:
                  authStatus !== AuthStatus.AccountHolder ? 'none' : 'auto',
              }}
            >
              <SideBarIcon
                iconName={'referral'}
                isSelected={selectedRoute === 'refer'}
                isDisabled={authStatus !== AuthStatus.AccountHolder}
              />
            </Link>
          </Tooltip>
          <AccountButton />
        </HStack>
      </HStack>
    </x.nav>
  );
};
