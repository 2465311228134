import config from '@ui-furniture/config';

import { AnalyticsSubscriber } from '../analytics/types/analytics-subscriber';

const { gaId } = config.analytics;

export const googleAnalyticsSubscriber: AnalyticsSubscriber = ({
  eventSubject,
  objectiveSubject,
  userPropertyActionsSubject,
  createProviderObservable,
}) => {
  if (gaId) {
    eventSubject.subscribe(({ name, ...rest }) => {
      if (typeof gtag !== 'undefined') {
        gtag('event', name, rest);
      }
    });

    objectiveSubject.subscribe(({ name, data, ...rest }) => {
      if (typeof gtag !== 'undefined') {
        gtag('event', name, {
          ...data,
          ...rest,
        });
      }
    });

    userPropertyActionsSubject.subscribe(({ type, ...rest }) => {
      if (typeof gtag !== 'undefined') {
        switch (type) {
          case 'SET':
          case 'SET_DATA':
          case 'SET_ONCE':
            gtag('set', 'user_data', rest);
            break;
          default:
            break;
        }
      }
    });

    createProviderObservable('ga').subscribe(({ name, type, data }) => {
      if (typeof gtag !== 'undefined') {
        gtag(type as any, name, data);
      }
    });
  }
};
