import { MotionProps, motion } from 'framer-motion';

import { MotionComponentWithStyledProps, x } from '@ui-furniture/style';

const motionVariants = {
  staggerChildren: {
    initial: 'hide',
    animate: 'show',
    exit: 'hide',
    variants: {
      hide: {
        transition: {
          staggerChildren: 0.05,
        },
      },
      show: {
        transition: {
          staggerChildren: 0.1,
        },
      },
    },
  },
} as const satisfies Record<string, MotionProps>;

export const ParentAnimation: MotionComponentWithStyledProps<{
  animation?: keyof typeof motionVariants;
}> = ({ animation = 'staggerChildren', ...rest }) => {
  return (
    <x.div
      display='flex'
      w='100%'
      as={motion.div}
      {...motionVariants[animation]}
      {...rest}
    />
  );
};
