import { FC } from 'react';

const SocialMeta: FC<{
  baseUrl?: string;
  title?: string;
  description?: string;
  keywords?: string;
  image?: string;
  ogTitle?: string;
  ogUrl?: string;
  ogImage?: string;
  ogDescription?: string;
  twitterSite?: string;
  twitterTitle?: string;
  twitterDescription?: string;
  twitterImage?: string;
  twitterImageAlt?: string;
}> = ({
  baseUrl,
  title,
  description,
  keywords,
  image,
  ogTitle = title,
  ogUrl = baseUrl,
  ogImage = image,
  ogDescription = description,
  twitterSite = baseUrl,
  twitterTitle = title,
  twitterDescription = description,
  twitterImage = image,
  twitterImageAlt = undefined,
}) => (
  <>
    {/* <!-- Social --> */}
    {title ? <meta content={description} name='title' /> : null}
    {description ? <meta content={description} name='description' /> : null}
    {keywords ? <meta content={keywords} name='keywords' /> : null}
    {/* <!-- Schema.org markup for Google+ --> */}
    {title ? <meta content={title} itemProp='name' /> : null}
    {description ? <meta content={description} itemProp='description' /> : null}
    {image ? <meta content={image} itemProp='image' /> : null}
    {/* <!-- Twitter Card data --> */}
    {twitterSite ||
    twitterTitle ||
    twitterDescription ||
    twitterImage ||
    twitterImageAlt ? (
      <meta content='summary' name='twitter:card' />
    ) : null}
    {twitterSite ? <meta content={twitterSite} name='twitter:site' /> : null}
    {twitterTitle ? <meta content={twitterTitle} name='twitter:title' /> : null}
    {twitterDescription ? (
      <meta content={twitterDescription} name='twitter:description' />
    ) : null}
    {twitterImage ? <meta content={twitterImage} name='twitter:image' /> : null}
    {twitterImageAlt ? (
      <meta content={twitterImageAlt} name='twitter:image:alt' />
    ) : null}
    {/* <!-- Open Graph data --> */}
    {ogTitle || ogUrl || ogImage || ogDescription ? (
      <meta content='website' property='og:type' />
    ) : null}
    {ogTitle ? <meta content={ogTitle} property='og:title' /> : null}
    {ogUrl ? <meta content={ogUrl} property='og:url' /> : null}
    {ogImage ? <meta content={ogImage} property='og:image' /> : null}
    {ogDescription ? (
      <meta content={ogDescription} property='og:description' />
    ) : null}
  </>
);

export default SocialMeta;
