import { FC } from 'react';

import { AnimatePresence, motion } from 'framer-motion';

import { FormInputError } from '@ui-furniture/lib/form/types/form-input-error';
import { x } from '@ui-furniture/style';

const EnterExitAnimation = (props) => (
  <motion.div
    {...props}
    initial={{
      opacity: 0,
      height: 0,
      x: 10,
    }}
    animate={{
      opacity: 1,
      height: 'auto',
      x: 0,
    }}
    exit={{
      opacity: 0,
      height: 0,
      x: 10,
    }}
    transition={{ type: 'tween', ease: 'easeOut', duration: 0.3 }}
  />
);

export const ErrorMessageComponent: FC<{
  error: FormInputError | null;
}> = ({ error }) => (
  <AnimatePresence>
    {error ? (
      <x.div
        key='error-container'
        as={EnterExitAnimation}
        alignSelf='flex-end'
        color='brand-negative'
        textAlign='right'
        fontWeight='600'
        role='alert'
      >
        {error}
      </x.div>
    ) : null}
  </AnimatePresence>
);
