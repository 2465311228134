import { FC } from 'react';

import { PATHS } from '@ui-furniture/constants/paths';
import { Link, Text, VStack } from '@ui-furniture/elements';

export const TermsAndConditionsText: FC = () => (
  <VStack spacing='sm'>
    <Text>
      Please confirm you agree to our{' '}
      <Link href={PATHS.marketingSite.termsAndConditions} target='_blank'>
        Terms and Conditions
      </Link>
      . You can learn how we collect, use and share your data in our{' '}
      <Link href={PATHS.marketingSite.privacyPolicy} target='_blank'>
        Privacy Policy
      </Link>{' '}
      and how we use cookies and similar technology in our{' '}
      <Link href={PATHS.marketingSite.cookiePolicy} target='_blank'>
        Cookies Policy
      </Link>
      .
    </Text>
  </VStack>
);
