import { useQueryParamModalControls } from './hooks';
import { Modal } from './modal';
import { ModalComponent } from './types/modal-component';

export const QueryParamModal: ModalComponent<{
  id: string;
}> = (props) => {
  const { id } = props;
  const controls = useQueryParamModalControls({
    id,
  });
  return <Modal controls={controls} {...props} />;
};
