const scale = {
  sm: '1.6',
  md: '1.75',
  lg: '1.33',
  xl: '1.66',
  '2xl': '1.75',
  '3xl': '1.6',
  '4xl': '1.4',
  '5xl': '1.125',
};

export default {
  ...scale,
  h1: scale['5xl'],
  h2: scale['4xl'],
  h3: scale['3xl'],
  title: scale['2xl'],
  subTitle: scale.xl,
  bodyLarge: scale.lg,
  body: scale.md,
  bodySmall: scale.sm,
  button: '20px',
  input: '20px',
  smallLabel: '10px',
};
