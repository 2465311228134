import { useCallback, FC, PropsWithChildren } from 'react';

import times from 'lodash/times';

import { Text } from '@ui-furniture/elements';
import { VoidStyledComponent, x } from '@ui-furniture/style';

import { Diamond } from './diamond';

const diamondSize = 24;

const assertActiveBreadcrumbIsValid = (activePos, count) => {
  if (activePos < 0) throw new Error('Active breadcrumb is too low');
  if (activePos >= count) throw new Error('Active breadcrumb is too high');
};

const LineJoinContainer: FC<
  PropsWithChildren<{
    contentWidth: number;
    lineLength: number;
  }>
> = ({ lineLength, contentWidth, children }) => (
  <x.div
    position='relative'
    pr={`${lineLength}px`}
    display='flex'
    alignItems='center'
    overflow='hidden'
  >
    <x.div
      position='absolute'
      h='1px'
      w={`${lineLength}px`}
      bg='palette-border'
      zIndex='1'
      left={contentWidth}
    />
    {children}
  </x.div>
);

const MobileBreadcrumbs: VoidStyledComponent<{
  count: number;
  activePos: number;
}> = ({ count, activePos }) => (
  <x.div aria-label='steps'>
    <Text color='primary' variant='bodyBig'>
      Step: {activePos + 1} of {count}
    </Text>
  </x.div>
);

const DesktopBreadcrumbs: VoidStyledComponent<{
  count: number;
  activePos: number;
  lineLength?: number;
  tabPrefix?: string;
  panelPrefix?: string;
}> = ({
  count,
  activePos,
  lineLength = 74,
  tabPrefix = 'step-',
  panelPrefix = 'screen-',
}) => {
  assertActiveBreadcrumbIsValid(activePos, count);
  const getIsActive = useCallback(
    (pos: number) => pos <= activePos,
    [activePos]
  );

  const breadcrumbs = times(count, (index) => ({
    Breadcrumb: Diamond,
    pos: index,
    isLast: index === count - 1,
  }));

  return (
    <x.div
      display='flex'
      flexDirection='row'
      alignItems='center'
      whiteSpace='nowrap'
      role='tablist'
      aria-label='breadcrumbs'
    >
      {breadcrumbs.map(({ pos, Breadcrumb, isLast }) => {
        const isActive = getIsActive(pos);
        const isCurrent = pos === activePos;
        const props = {
          key: pos,
          id: `${tabPrefix}${pos + 1}`,
          role: 'tab',
          'aria-selected': isCurrent,
          'aria-controls': `${panelPrefix}${pos + 1}`,
          tabIndex: isCurrent ? 0 : 1,
          isActive,
        };
        if (isLast) return <Breadcrumb {...props} />;
        return (
          <LineJoinContainer
            key={pos}
            contentWidth={diamondSize}
            lineLength={lineLength}
          >
            <Breadcrumb {...props} />
          </LineJoinContainer>
        );
      })}
    </x.div>
  );
};

export const Breadcrumbs: VoidStyledComponent<{
  count: number;
  activePos: number;
  lineLength?: number;
  tabPrefix?: string;
  panelPrefix?: string;
}> = ({
  count,
  activePos,
  lineLength = 74,
  tabPrefix = 'step-',
  panelPrefix = 'screen-',
}) => (
  <>
    <x.div display={{ _: 'flex', tablet: 'none' }} justifyContent='center'>
      <MobileBreadcrumbs count={count} activePos={activePos} />
    </x.div>
    <x.div display={{ _: 'none', tablet: 'block' }}>
      <DesktopBreadcrumbs
        count={count}
        activePos={activePos}
        lineLength={lineLength}
        tabPrefix={tabPrefix}
        panelPrefix={panelPrefix}
      />
    </x.div>
  </>
);
