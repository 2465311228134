import { ComponentProps, ReactNode, forwardRef } from 'react';

import MuiTooltip from '@mui/material/Tooltip';

import { StyledComponent, StyledProps, styled, x } from '@ui-furniture/style';

type TooltipProps = ComponentProps<typeof MuiTooltip>;

const StyledTooltip = styled(MuiTooltip)`
  .MuiTooltip-popper {
    padding: sm;
  }
`;

const Content: any = forwardRef((props, ref: any) => (
  <x.span {...props} ref={ref} />
));

const labelVariants = {
  none: {},
  text: {
    color: 'palette-primary',
    fontWeight: 600,
  },
} as const satisfies Record<string, StyledProps>;

export const Tooltip: StyledComponent<
  {
    tooltipContent: ReactNode;
    labelVariant?: keyof typeof labelVariants;
  } & Pick<TooltipProps, 'placement'>
> = ({
  tooltipContent,
  labelVariant = 'none',
  children,
  placement,
  ...rest
}) => (
  <StyledTooltip
    title={tooltipContent || ''}
    disableInteractive={tooltipContent === null}
    arrow
    placement={placement}
    componentsProps={{
      popper: { style: { zIndex: 9999999 } },
      tooltip: {
        style: { fontSize: '12px', padding: '10px', lineHeight: '1.3' },
      },
    }}
  >
    <Content cursor='pointer' {...labelVariants[labelVariant]} {...rest}>
      {children}
    </Content>
  </StyledTooltip>
);
