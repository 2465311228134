import { createContext } from 'react';

import { Callback, CallbackNames } from './types';

interface FlowManagerContext {
  callback: Callback | undefined;
  registerCallback: (
    callbackName: CallbackNames,
    options?: Partial<Callback>
  ) => Callback | null;
  resetStorage: () => void;
}

export const flowManagerContext = createContext<FlowManagerContext>(
  undefined as any
);
