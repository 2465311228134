import { z, ZodRawShape } from 'zod';

import {
  useZodForm as useForm,
  useFullResetOnSuccess,
} from '@ui-furniture/lib/form';

import { BasicFormProps } from './types/props';

import { Form } from '../form';

export function BasicForm<TValues extends ZodRawShape>({
  schema = z.object({}) as any,
  defaultValues,
  onSubmit,
  onError,
  children,
  resetOnSuccess,
}: BasicFormProps<TValues>) {
  const form = useForm(schema, {
    defaultValues,
  });
  useFullResetOnSuccess(form, resetOnSuccess);
  return (
    <Form form={form} onSubmit={form.handleSubmit(onSubmit as any, onError)}>
      {children}
    </Form>
  );
}
