import { FC } from 'react';

import { Breakpoint } from '@/style';

import { TextButtonWithIcon } from '@ui-furniture/ui/buttons/text-button-with-icon';

export const PreviousButton: FC<{
  decrementPos: () => void;
  activePos: number;
  totalPos: number;
  isDisabled: boolean;
  isPreviousDisabledOnLastStep: boolean;
  showTextBreakpoint?: Breakpoint;
}> = ({
  activePos,
  totalPos,
  decrementPos,
  isDisabled,
  isPreviousDisabledOnLastStep,
  showTextBreakpoint,
}) => {
  const isVisiblePosition = activePos !== 0;
  const isLastPos = totalPos === activePos - 1;
  const isLastStepAndDisabled = isLastPos && isPreviousDisabledOnLastStep;
  const isVisible = !isDisabled && isVisiblePosition && !isLastStepAndDisabled;

  return (
    <TextButtonWithIcon
      iconName='arrowLeft'
      text='Previous'
      onClick={decrementPos}
      isDisabled={!isVisible}
      showTextBreakpoint={showTextBreakpoint}
    />
  );
};
