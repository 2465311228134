import { GetComponentProps } from '@support/types/utility/get-component-props';

import { ComponentRef, forwardRef, PropsWithChildren } from 'react';

import { Breakpoint, StyledProps, x } from '@ui-furniture/style';
import { SpaceProp } from '@ui-furniture/style/types/styled-prop';

type Dir = 'row' | 'column' | 'row-reverse' | 'column-reverse';

type TStack = GetComponentProps<typeof x.div> & {
  /**
   * @deprecated Use `flexDirection` instead
   */
  dir?: Dir;
  /**
   * @deprecated Use `gap` instead
   */
  spacing?: SpaceProp;
};
export const Stack = forwardRef<ComponentRef<typeof x.div>, TStack>(
  ({ dir = 'row', spacing = 'md', flexDirection, gap, ...rest }, ref) => (
    <x.div
      ref={ref as any}
      display='flex'
      flexDirection={flexDirection || dir}
      gap={gap || spacing}
      {...rest}
    />
  )
);

export const VStack = forwardRef<
  ComponentRef<typeof Stack>,
  GetComponentProps<typeof Stack>
>(({ dir = 'column', ...rest }, ref) => (
  <Stack ref={ref} dir={dir} {...rest} />
));

export const HStack = forwardRef<
  ComponentRef<typeof Stack>,
  GetComponentProps<typeof Stack>
>(({ dir = 'row', ...rest }, ref) => <Stack ref={ref} dir={dir} {...rest} />);

export const Row = ({
  breakpoint = 'phablet',
  ...rest
}: PropsWithChildren<
  {
    breakpoint?: Breakpoint;
  } & StyledProps
>) => (
  <Stack
    {...rest}
    flexDirection={{
      _: 'column',
      [breakpoint]: 'row',
    }}
  />
);

export const RowItem = (props: PropsWithChildren<StyledProps>) => (
  <x.div flex={1} {...props} />
);
