import { StorybookPlugin } from './base';

export class ChromaticStorybookPlugin<T> extends StorybookPlugin<T> {
  private _setChromaticParameter = (value: any) => {
    this._definition.setParameter({
      chromatic: value,
    });
    return this;
  };

  disableSnapshot = (disable = true) =>
    this._setChromaticParameter({
      disableSnapshot: disable,
    });

  delay = (delayMs: number) => this._setChromaticParameter({ delay: delayMs });
}
