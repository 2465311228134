export const SEO = {
  title: 'Housecure',
  description: 'The easiest way to buy or sell a property',
  keywords:
    'house, secure, purchase, buy, sell, ownership, home, exchange, estate agent',
  imageUrl: 'https://housecure.co.uk/logos/Housecure.png',
  companyName: 'Housecure',
  socialProfiles: {
    twitter: {
      user: '@housecure',
      url: 'https://twitter.com/housecure',
    },
    linkedIn: {
      url: 'https://www.linkedin.com/company/housecure/',
    },
  },
  tileColor: '#EB6E46',
};
