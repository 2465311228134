import { FC, ReactNode, useCallback, useId, useState } from 'react';

import { LayoutGroup, motion } from 'framer-motion';

import { ScreenProp, SizeProp, StyledProps, x } from '@ui-furniture/style';

const variants = {
  small: {
    h: '38px',
    px: 'lg',
  },
  regular: {
    h: 'input',
    px: 'xl',
  },
} as const satisfies Record<string, StyledProps>;

export const useRadioButtonControls = <TValue extends string>({
  defaultValue,
  options,
  onSelectedChange,
}: {
  defaultValue: TValue;
  options: Array<{
    label: ReactNode;
    value: TValue;
  }>;
  onSelectedChange?: (value: TValue) => void;
}) => {
  const [selected, _setSelected] = useState<TValue>(
    defaultValue || options[0].value
  );
  const setSelected = useCallback(
    (newSelection: TValue) => {
      _setSelected(newSelection);
      onSelectedChange?.(newSelection);
    },
    [onSelectedChange, _setSelected]
  );

  return {
    options,
    selected,
    setSelected,
  };
};

export const RadioButtons: FC<{
  w?: SizeProp;
  controls: ReturnType<typeof useRadioButtonControls>;
  variant?: keyof typeof variants;
  stackVerticallyBeforeBreakpoint?: ScreenProp;
}> = ({
  w,
  variant = 'regular',
  stackVerticallyBeforeBreakpoint,
  controls: { options, setSelected, selected },
}) => {
  const id = useId();

  return (
    <LayoutGroup id={id}>
      <x.div
        as={motion.div}
        layoutRoot
        display='flex'
        alignItems='center'
        style={{
          isolation: 'isolate',
        }}
        w={w}
      >
        <x.div
          as={motion.div}
          display='flex'
          borderRadius='8px'
          w={w}
          backgroundColor='#F5F4F3'
          boxShadow='0px 1px 2px 0px rgba(0, 0, 0, 0.08) inset'
          position='relative'
          p='3px'
          mx='auto'
          overflowX='auto'
          {...(stackVerticallyBeforeBreakpoint
            ? {
                flexDirection: {
                  _: 'column',
                  [stackVerticallyBeforeBreakpoint as string]: 'row',
                },
              }
            : {
                flexDirection: 'row',
              })}
        >
          {options.map(({ label, value }) => (
            <x.div flex='1' position='relative' key={value}>
              {value === selected ? (
                <x.div
                  as={motion.div}
                  borderRadius='sm'
                  layout
                  layoutId='selected-radio'
                  position='absolute'
                  top='0'
                  left='0'
                  right='0'
                  bottom='0'
                  background='rgba(255, 255, 255, 0.70)'
                  boxShadow='0px 1px 2px 0px rgba(0, 0, 0, 0.12)'
                />
              ) : null}
              <x.button
                w='100%'
                type='button'
                position='relative'
                zIndex='1'
                onClick={() => setSelected(value)}
                bg='transparent'
                display='flex'
                alignItems='center'
                justifyContent='center'
                border='1px solid'
                borderRadius='sm'
                whiteSpace={'nowrap'}
                borderColor={{
                  _: 'transparent',
                  focus: 'palette-primary',
                }}
                {...variants[variant]}
              >
                {label}
              </x.button>
            </x.div>
          ))}
        </x.div>
      </x.div>
    </LayoutGroup>
  );
};
