import { Modal, useQueryParamModalControls } from '@ui-furniture/lib/modal';

import { KycFlow } from './kyc-flow';

export const KycApplyModal = () => {
  const controls = useQueryParamModalControls({
    id: 'kyc',
  });
  return (
    <Modal controls={controls} disableClose>
      <KycFlow />
    </Modal>
  );
};
