import { style } from '@xstyled/styled-components';

export interface FixPaddingInScrollContainerProps {
  fixPaddingInScrollContainer?: boolean;
}

export const fixPaddingInScrollContainer = style({
  prop: 'fixPaddingInScrollContainer',
  css: () => ({
    '&::after': {
      content: '',
      display: 'block',
      height: '50px',
      width: '100%',
      opacity: 0,
    },
  }),
});
