import axios from 'axios';

import { API_KEY_QUERY_PARAM_NAME, GET_ADDRESS_BASE_URL } from './constants';
import { createAutocompleteHandler } from './handlers/autocomplete-handler';
// import { createGetHandler } from './handlers/get-handler';

export const createService = (apiKey: string) => {
  const client = axios.create({
    baseURL: GET_ADDRESS_BASE_URL,
    params: {
      [API_KEY_QUERY_PARAM_NAME]: apiKey,
    },
  });

  return {
    // getById: createGetHandler(client),
    autocomplete: createAutocompleteHandler(client),
  };
};
