import type { SdkHandle, SdkOptions } from 'onfido-sdk-ui';

import { useEffect, useRef } from 'react';

import { useImport } from '@ui-furniture/lib/hooks/use-import';

export const useOnfido = (sdkOptions: SdkOptions) => {
  const [onfido, requestOnfido] = useImport(() => import('onfido-sdk-ui'));
  const onfidoOut = useRef<SdkHandle | null>(null);

  const canImportOnfido = !!sdkOptions.token && sdkOptions.isModalOpen;
  const canInitialise = onfido?.init && !onfidoOut.current;

  useEffect(() => {
    if (canImportOnfido) {
      requestOnfido().catch(() => {
        throw new Error('Unable to load Onfido');
      });
    }
  }, [canImportOnfido]);

  useEffect(() => {
    if (canInitialise) {
      onfidoOut.current = onfido.init(sdkOptions);
      return () => {
        if (onfidoOut.current) {
          onfidoOut.current.tearDown();
          onfidoOut.current = null;
        }
      };
    }
    // eslint-disable-next-line no-empty-function, @typescript-eslint/no-empty-function
    return () => {};
  }, [canInitialise]);

  // Set options on isModalOpen changed
  useEffect(() => {
    if (onfidoOut.current) {
      onfidoOut.current.setOptions({
        isModalOpen: sdkOptions.isModalOpen,
      });
    }
  }, [sdkOptions.isModalOpen]);
};
