import { useDashboard } from '@/features/layout/state/dashboard';

import { Button } from '@ui-furniture/elements';
import { useCurrentUser } from '@ui-furniture/hooks/use-current-user';

import { AccountIcon } from './account-icon';

export const AccountButton = () => {
  const { setActiveDrawer, activeDrawer, closeDrawer } = useDashboard();
  const { hasAccount } = useCurrentUser();

  return (
    <Button
      type='button'
      variant='blank'
      onClick={() =>
        activeDrawer ? closeDrawer() : setActiveDrawer('account')
      }
    >
      <AccountIcon
        borderColor={
          activeDrawer
            ? 'palette-primary'
            : {
                _: 'palette-text',
                hover: 'palette-primary',
              }
        }
        profileColor={hasAccount ? 'palette-primary' : 'palette-text'}
      />
    </Button>
  );
};
