import { FC } from 'react';

import { useRouter } from 'next/router';

import { useAnalyticsReportingService } from '@ui-furniture/common/analytics';
import { HStack, VStack, Text } from '@ui-furniture/elements';
import { Clickable } from '@ui-furniture/ui/clickable';
import { LineIcon } from '@ui-furniture/ui/line-icon';
import { SolidIcon } from '@ui-furniture/ui/solid-icon';

type Props = {
  withIcon?: boolean;
};

export const CreateAccountBanner: FC<Props> = ({ withIcon }) => {
  const { push } = useRouter();
  const { reportEvent } = useAnalyticsReportingService();

  return (
    <Clickable
      w='100%'
      action={{
        type: 'button',
        onClick: () => {
          reportEvent({
            name: 'Click',
            on: 'Create account banner',
          });
          push('/?drawer=sign-up');
        },
      }}
    >
      <HStack
        p='md'
        bg='brand-orange'
        color='brand-white'
        alignItems='center'
        borderRadius='sm'
      >
        {withIcon && <SolidIcon name='profileSignedIn' size='lg' pr='sm' />}
        <VStack justifyContent='center' margin='0 auto 0 0' textAlign='left'>
          <Text variant='button' color='white' mb='sm'>
            Create your free account
          </Text>
          <Text variant='smallComment' color='white' m='0'>
            To save your progress and retrieve it later, you need to create an
            account.
          </Text>
        </VStack>
        <LineIcon name='arrowRightCircleFull' size='sm' />
      </HStack>
    </Clickable>
  );
};
