import { css } from '@ui-furniture/style';

const versionsCss = {
  primary: css`
    background-color: brand-orange;
    outline-color: brand-orange;
    color: brand-bright-white;
    :hover,
    :focus {
      outline-color: brand-dark-orange;
      border-color: brand-dark-orange;
    }
    :active {
      background-color: brand-dark-orange;
    }
  `,
  secondary: css`
    background-color: brand-bright-white;
    outline-color: brand-blue;
    color: brand-blue;
    :hover,
    :focus {
      outline-color: brand-orange;
      border-color: brand-orange;
      color: brand-orange;
    }
    :active {
      outline-color: brand-blue;
      border-color: brand-blue;
    }
  `,
};

export const outlineButtonCss = css<{
  version?: keyof typeof versionsCss;
}>`
  display: inline-block;
  border-radius: default;
  transition: slow;
  font-weight: 500;
  font-family: mont;
  transition: default;
  font-size: 15px;
  line-height: button;
  outline: 1px solid;
  border: 1px solid transparent;

  :active {
    transition: fast;
  }
  :disabled {
    opacity: 0.7;
  }
  ${({ version = 'primary' }) => versionsCss[version]}
`;
