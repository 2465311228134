import { useContext } from 'react';

import { analyticsServiceContext } from './analytics-service-context';
import { AnalyticsReportingService } from './types/analytics-reporting-service';

export const useAnalyticsReportingService = (): AnalyticsReportingService => {
  const context = useContext(analyticsServiceContext);
  if (!context)
    throw new Error(
      'Can only use AnalyticsReportingService in AnalyticsServiceProvider'
    );

  return context.report;
};
