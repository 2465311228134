import { style } from '@xstyled/styled-components';

import colors from '../theme/colors';

const variants = {
  review: {
    border: `1px solid ${colors['palette-border']}`,
  },
};

export interface BorderVariantProps {
  borderVariant?: keyof typeof variants;
}

export const borderVariant = style({
  prop: 'borderVariant',
  css: (variant) => variants[variant],
});
