import { getIsAnalyticsDisabled } from '@support/utils/analytics';

import { FullStory, init } from '@fullstory/browser';

import config from '@ui-furniture/config';

import { AnalyticsSubscriber } from '../analytics/types/analytics-subscriber';

const { fullStoryId } = config.analytics;

export const fullStorySubscriber: AnalyticsSubscriber = ({
  eventSubject,
  objectiveSubject,
  userIdSubject,
  userPropertyActionsSubject,
  resetSubject,
}) => {
  if (fullStoryId && !getIsAnalyticsDisabled()) {
    init({ orgId: fullStoryId });

    eventSubject.subscribe(({ name, ...rest }) => {
      FullStory('trackEvent', {
        name,
        properties: {
          ...rest,
        },
      });
    });

    objectiveSubject.subscribe(({ name, data, ...rest }) => {
      FullStory('trackEvent', {
        name,
        properties: {
          ...data,
          ...rest,
        },
      });
    });

    userIdSubject.subscribe((userId) => {
      if (userId !== null) FullStory('setIdentity', { uid: `${userId}` });
    });

    userPropertyActionsSubject.subscribe((action) => {
      switch (action.type) {
        case 'SET_DATA': {
          FullStory('setProperties', {
            type: 'user',
            properties: { ...action.data },
          });
          break;
        }
        case 'SET': {
          FullStory('setProperties', {
            type: 'user',
            properties: { [action.key]: action.value },
          });
          break;
        }
        default:
          break;
      }
    });

    resetSubject.subscribe((doReset) => {
      if (doReset) FullStory('setProperties', { type: 'user', properties: {} });
    });
  }
};
