import { Variant, motion } from 'framer-motion';

import { movement } from '@ui-furniture/lib/utils';
import { MotionComponentWithStyledProps, x } from '@ui-furniture/style';

const motionVariants = {
  none: {
    hide: {},
    show: {},
  },
  fadeUp: {
    hide: {
      opacity: 0,
      y: movement.md,
    },
    show: {
      opacity: 1,
      y: 0,
    },
  },
  expandWidth: {
    hide: {
      width: 0,
    },
    show: {
      width: 'auto',
      transition: {
        delayChildren: 0.2,
        staggerChildren: 0.1,
      },
    },
  },
} as const satisfies Record<
  string,
  {
    hide: Variant;
    show: Variant;
  }
>;

export const ChildAnimation: MotionComponentWithStyledProps<{
  animation?: keyof typeof motionVariants;
  hideName?: string;
  showName?: string;
}> = ({
  animation = 'fadeUp',
  hideName = 'hide',
  showName = 'show',
  ...rest
}) => {
  const variant = motionVariants[animation];
  return (
    <x.div
      {...{ as: motion.div }}
      variants={{
        [hideName]: variant.hide,
        [showName]: variant.show,
      }}
      {...rest}
    />
  );
};
