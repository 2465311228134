import { style } from '@xstyled/styled-components';

import radii from '../theme/radii';
import space from '../theme/space';

const variants = {
  clear: {
    borderColor: '#E8E2E0',
  },
  dark: {
    borderColor: '#E8E2E0',
    backgroundColor: 'overlay-weak',
  },
};

export interface GroupBorderProps {
  groupBorder?: keyof typeof variants;
}

export const groupBorder = style({
  prop: 'groupBorder',
  css: (variant) => ({
    border: '1px solid',
    padding: space.md,
    borderRadius: radii.md,
    ...variants[variant],
  }),
});
