import { FC, PropsWithChildren } from 'react';

import { Button } from '@ui-furniture/elements';
import { x } from '@ui-furniture/style';
import { ChildAnimation } from '@ui-furniture/ui/animation/child';
import { IconName } from '@ui-furniture/ui/solid-icon';

import { variantProps } from './variants';

export const SideMenuButton: FC<
  PropsWithChildren<{
    onClick: () => void;
    iconName?: IconName;
    variant?: keyof typeof variantProps;
    isSelected?: boolean;
  }>
> = ({ children, onClick, variant = 'primary', isSelected }) => {
  const buttonStyleProps = isSelected
    ? variantProps[variant].button?.selected
    : variantProps[variant].button?.notSelected;

  const divStyleProps = isSelected
    ? variantProps[variant].div?.selected
    : variantProps[variant].div?.notSelected;

  return (
    <ChildAnimation>
      <x.div paddingLeft='md' {...divStyleProps}>
        <Button
          type='button'
          h='56px'
          minWidth='100%'
          borderRadius='sm'
          variant='blank'
          onClick={onClick}
          px='md !important'
          py='sm !important'
          textAlign='left'
          transition={{ _: 'slow', hover: 'base' }}
          {...buttonStyleProps}
        >
          {children}
        </Button>
      </x.div>
    </ChildAnimation>
  );
};
