import { defaultTheme, rpxTransformers } from '@xstyled/styled-components';
import merge from 'lodash/merge';

import colors from './colors';
import fontSizes from './fontSizes';
import fonts from './fonts';
import lineHeights from './lineHeights';
import radii from './radii';
import createScreens from './screens';
import shadows from './shadows';
import sizes from './sizes';
import space from './space';
import transitions from './transitions';
import zIndices from './zIndices';

export const theme = merge(defaultTheme, {
  colors,
  fontSizes,
  fonts,
  lineHeights,
  radii,
  screens: createScreens(defaultTheme.screens),
  shadows,
  sizes,
  space,
  transformers: {
    ...rpxTransformers,
  },
  transitions,
  zIndices,
});
