import { PropsWithChildren, ReactElement } from 'react';

import { x } from '@/style';

import { withProviders } from '@ui-furniture/common/hoc/with-providers';
import { VStack } from '@ui-furniture/elements';
import { ContentLayout } from '@ui-furniture/ui/layout/content-layout/content-layout';

import { DashboardProvider, useDashboard } from './state/dashboard';
import { Header } from './ui/header';

import { AccountDrawerWrapper } from '../account/ui/account-drawer-wrapper';

export const DashboardLayout = withProviders<
  PropsWithChildren<{
    menu?: ReactElement;
    mobileMenu?: ReactElement;
  }>
>(DashboardProvider, ({ children, menu, mobileMenu }) => {
  const { activeDrawer } = useDashboard();
  return (
    <>
      <VStack
        w='100%'
        id='root-layout'
        h='100%'
        display='flex'
        zIndex='0'
        gap='0'
      >
        <Header />
        <x.div flex='1' position='relative' overflow='hidden'>
          <x.div
            h='100%'
            filter={!activeDrawer ? '' : 'blur(3px)'}
            transition='base'
            display='flex'
            justifyContent='stretch'
          >
            <ContentLayout menu={menu} mobileMenu={mobileMenu}>
              {children}
            </ContentLayout>
          </x.div>
        </x.div>
      </VStack>
      <x.div
        position='fixed'
        top='0'
        left='0'
        right='0'
        bottom='0'
        zIndex='foreground'
        pointerEvents={activeDrawer ? 'auto' : 'none'}
      >
        <AccountDrawerWrapper />
      </x.div>
    </>
  );
});
