import { TextareaHTMLAttributes } from 'react';

import {
  FormInputComponent,
  useFieldRegistration,
  FormInput,
} from '@ui-furniture/lib/form';

import {
  TextAreaContainer,
  TextArea as TextAreaElement,
  TextAreaGrabber,
} from './components';

import { Label, LabelText, InputBorder, ErrorMessage } from '../common';

export const TextArea: FormInputComponent<
  {
    label: string;
    isOptional?: boolean;
    informationTooltip?: JSX.Element;
  } & TextareaHTMLAttributes<any>
> = ({
  name,
  registerOptions,
  label,
  isOptional = false,
  informationTooltip,
  className = '',
  ...rest
}) => {
  const { registration, error } = useFieldRegistration(name, registerOptions);

  return (
    <FormInput error={error?.message}>
      <Label>
        <LabelText
          isOptional={isOptional}
          informationTooltip={informationTooltip}
        >
          {label}
        </LabelText>
        <InputBorder>
          <TextAreaContainer>
            <TextAreaElement
              className={className ? `read ${className}` : 'read'}
              {...rest}
              {...registration}
            />
            <TextAreaGrabber />
          </TextAreaContainer>
        </InputBorder>
        <ErrorMessage />
      </Label>
    </FormInput>
  );
};
