import { FC, PropsWithChildren, ReactNode } from 'react';

import { AnimatePresence } from 'framer-motion';

import { x } from '@ui-furniture/style';
import { ParentAnimation } from '@ui-furniture/ui/animation/parent';

import { ContentLayoutDrawer } from './content-layout-drawer';
import { ContentLayoutMenu } from './content-layout-menu';

export const ContentLayout: FC<
  PropsWithChildren<{
    menu?: ReactNode;
    mobileMenu?: ReactNode;
    drawer?: ReactNode;
  }>
> = ({ menu, mobileMenu, children, drawer }) => {
  return (
    <x.div
      flex='1'
      maxW='100%'
      pointerEvents='none'
      display='flex'
      flexDirection={{ _: 'column', tablet: 'row' }}
    >
      {menu && <ContentLayoutMenu menu={menu} mobileMenu={mobileMenu} />}
      {drawer && <ContentLayoutDrawer drawer={drawer} />}
      <AnimatePresence>
        {children ? (
          <x.div
            flex='1'
            overflow='auto'
            pointerEvents='auto'
            position='relative'
            display='flex'
          >
            <ParentAnimation flex='1'>{children}</ParentAnimation>
          </x.div>
        ) : null}
      </AnimatePresence>
    </x.div>
  );
};
