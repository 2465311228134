import { FC } from 'react';

import { MultiStepFlow } from '@/common/multi-step-flow';

import { withProviders } from '@ui-furniture/common/hoc/with-providers';
import { PATHS } from '@ui-furniture/constants/paths';
import { Link, Text, VStack } from '@ui-furniture/elements';
import { useModalControls } from '@ui-furniture/lib/modal/context/modal-controls';
import { BasicMessage } from '@ui-furniture/ui/basic-message';
import { LoadingInline } from '@ui-furniture/ui/loading/inline';

import { CompleteStep } from './complete-step';
import { FormStep } from './form-step';
import { IntroductionStep } from './introduction-step';
import { DocumentsStep } from './user-identity-step';

import {
  KycFlowManagerProvider,
  useKycFlowManager,
} from '../context/kyc-flow-manager';

const KycFlowContent: FC = () => {
  const { status } = useKycFlowManager();
  const { close } = useModalControls();

  if (status === 'loading') return <LoadingInline isLoading />;
  if (status === 'completed')
    return (
      <BasicMessage title={`Your KYC is complete`} iconName='touchId'>
        <VStack gap='xl'>
          <Text>
            You’ve already submitted your KYC documents. Click the button below
            to view progress.
          </Text>
          <Link variant='rounded' href={PATHS.webApp.kycView}>
            KYC Results
          </Link>
        </VStack>
      </BasicMessage>
    );
  return (
    <MultiStepFlow
      flowName='kyc'
      close={close}
      steps={[
        {
          id: 'intro',
          Screen: IntroductionStep,
        },
        {
          id: 'form',
          Screen: FormStep,
        },
        {
          id: 'documents',
          Screen: DocumentsStep,
        },
        {
          id: 'complete',
          Screen: CompleteStep,
        },
      ]}
    />
  );
};

export const KycFlow = withProviders(KycFlowManagerProvider, KycFlowContent);
