import { createTheme } from '@mui/material/styles';

import { theme } from '@ui-furniture/style';

export const muiTheme = createTheme({
  typography: {
    fontFamily: theme.fonts.base,
  },
  palette: {
    primary: {
      main: '#EB6E46',
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiTooltip: {
      styleOverrides: {
        arrow: {
          color: theme.colors['overlay-opaque'],
        },
        tooltip: {
          padding: theme.space.sm,
          backgroundColor: theme.colors['overlay-opaque'],
          color: theme.colors['overlay-text'],
        },
      },
    },
  },
});
