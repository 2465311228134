import { useMemo } from 'react';

import { createHandleErrorLink } from '@ui-furniture/common/gql-links/create-handle-error-link';
import { errorService } from '@ui-furniture/common/services/error';
import { useToast } from '@ui-furniture/common/toast';

export const useBadUserInputReportingLink = () => {
  const { showToast } = useToast();
  return useMemo(
    () =>
      createHandleErrorLink('BAD_USER_INPUT', (errors) => {
        errors.forEach((error) => {
          const errorResponse: any = error.extensions.response;
          if (errorResponse?.statusCode === 400) {
            showToast({
              severity: 'warning',
              message: `There was a problem with the information provided: \n${errorResponse.message?.join(
                ',\n'
              )}`,
            });
            errorService.captureMessage(error.message, {
              level: 'warning',
              extra: {
                error,
              },
            });
          } else {
            showToast({
              severity: 'error',
              message: `There was a problem with the submission. We’ve been notified and are looking into it.`,
            });
            errorService.captureException(error);
          }
        });
      }),
    []
  );
};
