export const brandColors = {
  buyer: '#F5C963',
  seller: '#EB6E46',
  'estate-agent': '#3A6E6E',
  'brand-info': '#374668',
  'brand-info-faded': '#F2F6FF',
  'brand-success': '#6CB135',
  'brand-success-faded': '#F1F5F3',
  'brand-fail': '#A11212',
  'brand-fail-faded': '#FFD9D9',
  'brand-warn': '#F0CD7C',
  'brand-warn-faded': '#FFF9ED',
  'brand-error': '#F65555',
  'brand-error-faded': '#FFE8E8',
  'brand-disabled': '#c9c9c9',
  'brand-light-green': '#F1F5F3',
  'brand-green': '#3A6E6E',
  'brand-light-yellow': '#FFF9ED',
  'brand-yellow': '#F5C963',
  'brand-bg-accent': '#F9F8F4',
  'brand-light-orange': '#FFF6F3',
  'brand-orange': '#EB6E46',
  'brand-dark-orange': '#CB5029',
  'brand-light-red': '#FFE8E8',
  'brand-dark': '#414F65',
  'brand-dark-50': 'rgba(65, 79, 101, 0.5)',
  'brand-blue': '#374668',
  'brand-beige': '#E8E2E0',
  'brand-beige-light': '#F4F1EF',
  'brand-beige-dark': '#D0C9C7',
  'brand-white': '#FAF8F7',
  'brand-bright-white': '#FDFBFA',
  'brand-grey': '#D0C9C7',
  'brand-black': '#202020',
  'brand-text': '#374668',
  'brand-negative': 'rgba(246, 85, 85, 1)',
  'brand-positive': '#6CB135',
};
