import { GqlErrorCode } from '@support/types/housecure-api';

import { GraphQLError } from 'graphql';

export class ErrorIdentifier {
  constructor(private readonly _gqlError: GraphQLError) {}

  get code(): GqlErrorCode {
    return this._gqlError.extensions.code as GqlErrorCode;
  }

  get isAuthenticationError() {
    return this.code === 'UNAUTHENTICATED';
  }

  get isServerError() {
    return this.code === 'INTERNAL_SERVER_ERROR';
  }
}

export const identifyGqlError = (gqlError: GraphQLError) =>
  new ErrorIdentifier(gqlError);
