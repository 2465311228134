import { FC } from 'react';

import { SpinnerCircular } from 'spinners-react';

import { useColor } from '@ui-furniture/style';

export const Spinner: FC<{
  isLoading: boolean;
  size?: number;
  color?: string;
  secondaryColor?: string;
}> = ({
  size = 30,
  color = 'currentColor',
  secondaryColor = 'transparent',
  isLoading,
}) => {
  const colorValue = useColor(color);
  const secondaryColorValue = useColor(secondaryColor);
  return (
    <SpinnerCircular
      size={size}
      color={colorValue?.toString()}
      secondaryColor={secondaryColorValue?.toString()}
      enabled={isLoading}
    />
  );
};
