import { FC, ReactNode } from 'react';

import { x } from '@ui-furniture/style';
import { ChildAnimation } from '@ui-furniture/ui/animation/child';
import { ParentAnimation } from '@ui-furniture/ui/animation/parent';
import { BreakpointContainer } from '@ui-furniture/ui/breakpoint-container';

export const ContentLayoutMenu: FC<{
  menu?: ReactNode;
  mobileMenu?: ReactNode;
}> = ({ menu, mobileMenu }) => {
  return (
    <>
      <BreakpointContainer
        whenSmaller={mobileMenu}
        breakpoint='tablet'
        whenLarger={
          <x.nav
            aria-label='Sub menu'
            borderRight='1px solid'
            borderRightColor='brand-beige'
            backgroundColor='brand-bg-accent'
            flex='1'
            position='relative'
            minH='100%'
            overflow='auto'
            pointerEvents='auto'
          >
            <ParentAnimation>
              <ChildAnimation
                style={{ overflow: 'hidden', height: '100%' }}
                animation={'none'}
              >
                {menu}
              </ChildAnimation>
            </ParentAnimation>
          </x.nav>
        }
        smallerContainerProps={{
          display: 'flex',
          alignItems: 'center',
          p: 'sm',
          w: '100%',
          ariaLabel: 'Sub menu',
          pointerEvents: 'auto',
        }}
      />
    </>
  );
};
