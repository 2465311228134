import { getIsAnalyticsDisabled } from '@support/utils/analytics';

import { FC } from 'react';

import { ActiveCampaign } from './active-campaign';
import { GoogleAnalytics } from './google-analytics';
import { Meta } from './meta';

export const AnalyticsServices: FC = () => (
  <>
    {!getIsAnalyticsDisabled() && (
      <>
        <ActiveCampaign />
        <GoogleAnalytics />
        <Meta />
      </>
    )}
  </>
);
