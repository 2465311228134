import { getIsAnalyticsDisabled } from '@support/utils/analytics';

import amplitude from 'amplitude-js';

import config from '@ui-furniture/config';

import { AnalyticsSubscriber } from '../analytics/types/analytics-subscriber';

const { amplitudeApiKey } = config.analytics;

export const amplitudeSubscriber: AnalyticsSubscriber = ({
  eventSubject,
  objectiveSubject,
  userIdSubject,
  userPropertyActionsSubject,
  resetSubject,
  sessionIdSubject,
}) => {
  if (amplitudeApiKey && !getIsAnalyticsDisabled()) {
    amplitude.getInstance().init(amplitudeApiKey, undefined, {
      platform: 'app',
      includeUtm: true,
    });

    sessionIdSubject.next({
      source: 'Amplitude',
      id: amplitude.getInstance().getSessionId().toString(),
    });

    eventSubject.subscribe(({ name, ...rest }) => {
      amplitude.getInstance().logEvent(name, {
        ...rest,
      });
    });

    objectiveSubject.subscribe(({ name, data, ...rest }) => {
      amplitude.getInstance().logEvent(name, {
        ...data,
        ...rest,
      });
    });

    userIdSubject.subscribe((userId) => {
      if (userId !== null) amplitude.getInstance().setUserId(`${userId}`);
    });

    userPropertyActionsSubject.subscribe((action) => {
      const identify = new amplitude.Identify();
      switch (action.type) {
        case 'SET_DATA': {
          amplitude.getInstance().setUserProperties(action.data);
          break;
        }
        case 'SET': {
          identify.set(action.key, action.value);
          break;
        }
        case 'SET_ONCE': {
          identify.setOnce(action.key, action.value);
          break;
        }
        case 'ADD': {
          identify.add(action.key, action.value);
          break;
        }
      }
      amplitude.identify(identify);
    });

    resetSubject.subscribe((doReset) => {
      if (doReset) amplitude.clearUserProperties();
    });
  }
};
