import { style } from '@xstyled/styled-components';

export interface EllipsisProps {
  ellipsis?: boolean;
}

export const ellipsis = style({
  prop: 'ellipsis',
  css: (value) =>
    value
      ? {
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        }
      : {},
});
