import { css } from '@ui-furniture/style';

import { roundedButtonCss } from '../css/rounded-button-css';
import { simpleLinkCss } from '../css/simple-link-css';

export const variants = {
  blank: css`
    text-decoration: none;
  `,
  color: css`
    text-decoration: none;
    transition: base;
    :hover {
      color: palette-primary;
    }
  `,
  simpleLink: css`
    ${simpleLinkCss}
  `,
  rounded: css`
    ${roundedButtonCss}
    padding: md lg;
  `,
};
