import { ComponentProps, FC } from 'react';

import { x } from '@xstyled/styled-components';

import { ColorSwatch } from './swatch';

export const ColorSwatchList: FC<{
  colors: Array<ComponentProps<typeof ColorSwatch>>;
}> = ({ colors }) => (
  <x.div
    display='flex'
    flexWrap='wrap'
    w='100%'
    alignItems='center'
    justifyContent='center'
  >
    {colors.map((props) => (
      <ColorSwatch key={props.color} {...props} />
    ))}
  </x.div>
);
