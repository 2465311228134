import {
  StyledComponent,
  StyledProps,
  createComponentVariants,
  x,
} from '@/style';

import { VStack } from '@ui-furniture/elements';

const variants = createComponentVariants({
  standard: {
    outer: {
      overflow: 'auto',
      flex: '1',
    },
    inner: {},
  },
  screenHeight: {
    outer: {
      maxH: 'screen-height',
      flex: '1',
      overflow: 'hidden',
    },
    inner: {
      h: '100%',
    },
  },
});

export const StepContainer: StyledComponent<{
  isCentered?: boolean;
  variant?: keyof typeof variants;
  contentProps?: StyledProps;
  maxWidth?: string;
}> = ({
  variant = 'standard',
  isCentered = true,
  maxH,
  children,
  contentProps = {},
  maxWidth,
  ...rest
}) => (
  <x.div
    display='flex'
    justifyContent='center'
    {...variants[variant].outer}
    {...rest}
  >
    <VStack
      p='md'
      pb='xl'
      maxW={maxWidth || 'content-width-xs'}
      w='100%'
      my='auto'
      flex='1'
      maxH={maxH}
      {...variants[variant].inner}
      {...(isCentered
        ? {
            textAlign: 'center',
            alignItems: 'center',
          }
        : {})}
      {...contentProps}
    >
      {children}
    </VStack>
  </x.div>
);
