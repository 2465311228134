import { OptionsObject, useSnackbar } from 'notistack';

import { ToastMessage } from '../types/message';

export const useToast = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const showToast = ({
    severity = 'info',
    message,
    snackbarKey,
    ...rest
  }: {
    severity?: ToastMessage['severity'];
    message: ToastMessage['message'];
    snackbarKey?: string;
  } & OptionsObject) => {
    return enqueueSnackbar(message, {
      variant: severity,
      key: snackbarKey,
      ...rest,
    });
  };

  const showToastForError = ({
    error,
    defaultMessage,
  }: {
    error: Error;
    defaultMessage: string;
  }) => {
    showToast({
      severity: 'error',
      message: error.message || defaultMessage,
    });
  };

  const closeToast = (snackbarKey?: string) => {
    closeSnackbar(snackbarKey);
  };

  return {
    showToast,
    showToastForError,
    closeToast,
  };
};
