import { FC, PropsWithChildren, ReactNode } from 'react';

import { Text, VStack } from '@ui-furniture/elements';
import { LineIcon, IconName } from '@ui-furniture/ui/line-icon';

export const BasicMessage: FC<
  PropsWithChildren<{
    iconName?: IconName;
    title: ReactNode;
  }>
> = ({ iconName, title, children }) => (
  <VStack alignItems='center' textAlign='center' m='auto'>
    {iconName ? (
      <LineIcon name={iconName} size='2xl' color='brand-orange' mb='lg' />
    ) : null}
    {typeof title === 'string' ? (
      <Text as='h3' variant='subTitle'>
        {title}
      </Text>
    ) : (
      <>{title}</>
    )}
    <VStack maxW='content-width-xs' mt='xl'>
      {children}
    </VStack>
  </VStack>
);
