import { FC, ReactNode } from 'react';

import { useParamOnMount } from '@ui-furniture/hooks/use-param-on-mount';
import { x } from '@ui-furniture/style';
import { ChildAnimation } from '@ui-furniture/ui/animation/child';
import { ParentAnimation } from '@ui-furniture/ui/animation/parent';

export const ContentLayoutDrawer: FC<{
  drawer?: ReactNode;
}> = ({ drawer }) => {
  const activeDrawer = useParamOnMount('drawer', 'string');

  const showActiveDrawer =
    activeDrawer === 'account' || activeDrawer === undefined;

  return (
    <x.div
      display={{ _: showActiveDrawer ? 'block' : 'none', tablet: 'block' }}
    >
      <x.nav
        aria-label='Sub menu'
        borderRight='1px solid'
        borderRightColor='brand-beige'
        backgroundColor='brand-bg-accent'
        position='relative'
        h='100%'
        overflow='auto'
        pointerEvents='auto'
      >
        <ParentAnimation>
          <ChildAnimation
            style={{ overflow: 'hidden' }}
            animation={'expandWidth'}
          >
            {drawer}
          </ChildAnimation>
        </ParentAnimation>
      </x.nav>
    </x.div>
  );
};
