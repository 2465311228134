import { useContext } from 'react';

import { rootContext } from '../context/root';

export const useRootModalContext = () => {
  const modalsContext = useContext(rootContext);
  if (!modalsContext)
    throw new Error(
      'Cannot use Modals context outside of Root Modals provider'
    );
  return modalsContext;
};
