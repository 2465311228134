import { FC } from 'react';

import dayjs from 'dayjs';

import { StepComponentAnimator } from '@/common/multi-step-flow';

import {
  Input,
  RowItem,
  AddressInput,
  DatePicker,
  BasicForm,
  ButtonBarFooter,
} from '@ui-furniture/common/form';
import { USER } from '@ui-furniture/constants/user';
import { Stack } from '@ui-furniture/elements';
import { useCurrentUser } from '@ui-furniture/hooks/use-current-user';
import { useKycUserIdentityCheck } from '@ui-furniture/hooks/use-kyc-user-identity-check';
import { useFocusByIdOnMount } from '@ui-furniture/lib/hooks/use-focus-by-id-on-mount';

import { kycSchema } from '../common/schema';
import { SubmitFormHandler } from '../types/submit-form-handler';

export const KycForm: FC<{
  buttons: JSX.Element;
  submit: SubmitFormHandler;
}> = ({ buttons, submit }) => {
  const focusId = useFocusByIdOnMount();
  const { currentUser } = useCurrentUser();
  const { hasLoaded, kyc } = useKycUserIdentityCheck();
  if (!hasLoaded) {
    return null;
  }

  return (
    <BasicForm
      schema={kycSchema}
      onSubmit={submit}
      defaultValues={{
        firstName: kyc?.firstName || currentUser?.firstName || undefined,
        lastName: kyc?.lastName || currentUser?.lastName || undefined,
        middleNames: kyc?.middleNames || undefined,
        dob: kyc?.dob ? dayjs(kyc.dob) : undefined,
        address: kyc?.address
          ? {
              id: kyc.address.id,
              line1: kyc.address.line1,
              line2: kyc.address.line2 || undefined,
              line3: kyc.address.line3 || undefined,
              city: kyc.address.city,
              postcode: kyc.address.postcode,
            }
          : undefined,
      }}
    >
      <StepComponentAnimator>
        <Stack flexDirection={{ _: 'column', tablet: 'row' }}>
          <RowItem>
            <Input id={focusId} name='firstName' label='Legal First Name' />
          </RowItem>
          <RowItem>
            <Input name='lastName' label='Legal Last Name' />
          </RowItem>
        </Stack>
      </StepComponentAnimator>
      <StepComponentAnimator>
        <Input name='middleNames' label='Middle Names' />
      </StepComponentAnimator>
      <StepComponentAnimator>
        <DatePicker
          name='dob'
          label='Date of Birth'
          openTo='year'
          views={['year', 'month', 'day']}
          maxDate={dayjs().subtract(15, 'years')}
          defaultDate={dayjs().subtract(
            USER.averageAgeOfFirstTimeBuyer,
            'years'
          )}
        />
      </StepComponentAnimator>
      <StepComponentAnimator>
        <AddressInput
          name='address'
          label='Your current address'
          autoCompleteType='home'
        />
      </StepComponentAnimator>
      <StepComponentAnimator>
        <ButtonBarFooter>{buttons}</ButtonBarFooter>
      </StepComponentAnimator>
    </BasicForm>
  );
};
