import { FC, PropsWithChildren } from 'react';

import { ApolloProvider } from '@apollo/client';

import { useApiGqlClient } from '@ui-furniture/hooks/use-api-gql-client';
import { useAuthenticationErrorLink } from '@ui-furniture/hooks/use-authentication-error-link';
import { useBadUserInputReportingLink } from '@ui-furniture/hooks/use-bad-user-input-reporting-link';
import { useServerErrorLink } from '@ui-furniture/hooks/use-server-error-link';

export const ApiApolloProvider: FC<PropsWithChildren> = ({ children }) => {
  const authenticationErrorLink = useAuthenticationErrorLink();
  const serverErrorLink = useServerErrorLink();
  const badUserInputReportingLink = useBadUserInputReportingLink();
  const apiClient = useApiGqlClient({
    links: [
      authenticationErrorLink,
      serverErrorLink,
      badUserInputReportingLink,
    ],
  });

  return <ApolloProvider client={apiClient}>{children}</ApolloProvider>;
};
