import { useGetKycUserIdentityCheckStatusQuery } from '@graphql/requests';

export const useKycUserIdentityCheck = () => {
  const userIdentityCheck = useGetKycUserIdentityCheckStatusQuery({
    fetchPolicy: 'cache-and-network',
  });

  const kyc = userIdentityCheck.data?.kyc || undefined;

  return {
    hasLoaded: !userIdentityCheck.loading,
    hasSubmittedACheck: !!kyc?.userIdentityCheck,
    kyc,
  };
};
