import { useMemo } from 'react';

import { Disclaimer } from '@ui-furniture/ui/disclaimer';

export const useEmailDeliverabilityDisclaimer = (email?: string | null) => {
  return useMemo(() => {
    if (email) {
      const emailComponents = email.toLowerCase().split('@');
      if (emailComponents.length > 1) {
        const domain = emailComponents[1];
        const domainWithoutTld = domain.split('.')[0];
        if (domainWithoutTld.length < 3) return null;

        const badDomains = ['outlook', 'live', 'hotmail'];
        const matchingDomain = badDomains.find((badDomain) =>
          badDomain.startsWith(domainWithoutTld)
        );
        if (matchingDomain)
          return (
            <Disclaimer type='warn'>
              If possible we recommend using a different email provider as we
              are currently experiencing issues with {matchingDomain} addresses
            </Disclaimer>
          );
      }
    }
    return null;
  }, [email]);
};
