const scale = {
  xs: '0.6875rem',
  sm: '0.8125rem',
  md: '0.9rem',
  base: '0.9rem',
  default: '0.9rem',
  lg: '1.125rem',
  xl: '1.5rem',
  '2xl': '2rem',
  '3xl': '2.5rem',
  '4xl': '3.5rem',
  '5xl': '4rem',
};

export default {
  ...scale,
  h1: scale['5xl'],
  h2: scale['4xl'],
  h3: scale['3xl'],
  title: scale['2xl'],
  subTitle: scale.xl,
  bodyLarge: scale.lg,
  body: scale.md,
  bodySmall: scale.sm,
  button: '15px',
  input: '16px',
  smallLabel: '10px',
};
