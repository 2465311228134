export const chunkBy = <TArrayItem, TValue>(
  items: Array<TArrayItem>,
  getValue: (item: TArrayItem) => TValue
) => {
  let previousValue: TValue | null = null;
  const chunks: Array<Array<TArrayItem>> = [];

  items.forEach((item, index) => {
    const currentValue = getValue(item);

    if (index === 0) {
      chunks.push([item]);
    } else if (currentValue === previousValue) {
      chunks[chunks.length - 1].push(item);
    } else {
      chunks.push([item]);
    }

    previousValue = currentValue;
  });

  return chunks;
};
