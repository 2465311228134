import { useMemo } from 'react';

import { useColor } from '@xstyled/styled-components';
import tc, { Instance } from 'tinycolor2';

export const useColorWithAdjustment = (
  colorText: string,
  adjustment: (color: Instance) => Instance = (c) => c
) => {
  const baseColor = useColor(colorText);
  const newColor = useMemo(
    () => adjustment(tc(baseColor?.toString())),
    [baseColor?.toString()]
  );

  return newColor;
};
