import { FC, PropsWithChildren } from 'react';

import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { muiTheme } from './theme';

export const MuiProvider: FC<PropsWithChildren> = ({ children }) => (
  <ThemeProvider theme={muiTheme}>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {children}
    </LocalizationProvider>
  </ThemeProvider>
);
