import { styled } from '@ui-furniture/style';

import { variants } from './variants';

export const Link = styled.aBox<{
  variant?: keyof typeof variants;
  version?: 'primary' | 'secondary';
  target?: string;
  disabled?: boolean;
}>`
  -webkit-user-drag: none;
  background: transparent;
  text-decoration: none;
  color: inherit;
  text-align: center;
  cursor: pointer;
  :focus {
    color: inherit;
  }
  :disabled {
    pointer-events: none;
  }
  ${({ variant = 'simpleLink' }) => variants[variant]}
  ${({ disabled }) => disabled && 'pointer-events: none;'}
`;
