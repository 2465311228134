import { FC } from 'react';

import { StepComponentAnimator, StepContainer } from '@/common/multi-step-flow';

import { HStack, Button, Text, VStack } from '@ui-furniture/elements';
import { useFlowControls } from '@ui-furniture/hooks/use-flow-controls';
import { useModalControls } from '@ui-furniture/lib/modal/context/modal-controls';

export const CompleteStep: FC = () => {
  const { completeFlowSuccessfully } = useFlowControls();
  const { close } = useModalControls();
  return (
    <StepContainer>
      <StepComponentAnimator>
        <Text variant='title'>That’s it! 🎉🎉🎉</Text>
      </StepComponentAnimator>
      <StepComponentAnimator>
        <VStack my='xl'>
          <Text fontWeight='bold'>
            We’ll review what you sent us and let you know the results.
          </Text>
          <Text>
            When you share your information in the future you will be able to
            include your enhanced KYC results.
          </Text>
        </VStack>
      </StepComponentAnimator>
      <HStack>
        <Button
          type='button'
          onClick={() =>
            completeFlowSuccessfully({
              callbackName: 'kyc',
              conversion: true,
              close,
            })
          }
        >
          Confirm
        </Button>
      </HStack>
    </StepContainer>
  );
};
