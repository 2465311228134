import { CSSObjectWithLabel, StylesConfig } from 'react-select';

import { useColorWithAdjustment } from '@ui-furniture/lib/xstyled/use-color-with-adjustment';
import { useColor, useSpace, useTransition } from '@ui-furniture/style';

const useOptionColors = () => {
  const textColor = useColor('palette-text')?.toString();
  const selectedColor = useColor('palette-primary')?.toString();
  const selectedTextColor = useColor('palette-primary-text')?.toString();
  const hoverColor = useColorWithAdjustment('palette-primary', (color) =>
    color.setAlpha(0.3)
  ).toString();

  const optionStyles = {
    default: {
      background: 'transparent',
      color: 'inherit',
    },
    focused: {
      background: hoverColor,
      color: textColor,
    },
    selected: {
      background: selectedColor,
      color: selectedTextColor,
    },
    disabled: {},
  };

  return ({
    isSelected,
    isFocused,
    isDisabled = false,
    isHovering = false,
  }: {
    isSelected: boolean;
    isFocused: boolean;
    isDisabled?: boolean;
    isHovering?: boolean;
  }) => {
    if (isDisabled) return optionStyles.disabled;
    if (isHovering) return optionStyles.focused;
    if (isFocused) return optionStyles.focused;
    if (isSelected) return optionStyles.selected;

    return optionStyles.default;
  };
};

export type OverrideStyleConfig = {
  container?: CSSObjectWithLabel;
  menu?: CSSObjectWithLabel;
};
export const useOverrideStyles = ({
  container = {},
  menu = {},
}: OverrideStyleConfig = {}): StylesConfig => {
  const baseTransition = useTransition('base')?.toString();
  const slowTransition = useTransition('slow')?.toString();
  const mdSpace = useSpace('md')?.toString();
  const borderColor = useColor('palette-border')?.toString();
  const getOptionColors = useOptionColors();

  return {
    container: (provided) => ({
      ...provided,
      width: '100%',
      border: 'none',
      boxShadow: 'none',
      outline: 'none',
      ...container,
    }),
    control: (provided) => ({
      ...provided,
      width: '100%',
      background: 'transparent',
      boxShadow: 'none',
      border: 'none',
      outline: 'none',
    }),
    input: (provided) => ({
      ...provided,
      color: 'inherit',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    }),
    placeholder: (provided) => ({
      ...provided,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    }),
    valueContainer: (provided) => ({
      ...provided,
      paddingLeft: mdSpace,
      paddingRight: mdSpace,
    }),
    option: (provided, { isSelected, isFocused, isDisabled }) => ({
      ...provided,
      ...getOptionColors({
        isSelected,
        isFocused,
        isDisabled,
        isHovering: false,
      }),
      transition: slowTransition,
      paddingLeft: mdSpace,
      paddingRight: mdSpace,
      '&:hover': {
        transition: baseTransition,
        ...getOptionColors({
          isSelected,
          isFocused,
          isDisabled,
          isHovering: true,
        }),
      },
      width: '100%',
    }),
    menu: (provided) => ({
      ...provided,
      width: '100%',
      maxWidth: '70vw',
      ...menu,
    }),
    multiValue: (styles) => {
      return {
        ...styles,
        borderRadius: '3px',
        backgroundColor: 'transparent',
        borderColor,
        borderWidth: '1px',
      };
    },
    multiValueLabel: (styles) => ({
      ...styles,
      paddingLeft: '8px',
      paddingRight: '8px',
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      borderRadius: 0,
    }),
  } as StylesConfig;
};
