import { useUpdateLegalInfoMutation } from '@graphql/requests';

import { useAnalyticsReportingService } from '@ui-furniture/common/analytics';
import { useLoadingCallback } from '@ui-furniture/hooks/use-loading-callback';

import { SubmitFormHandler } from '../types/submit-form-handler';

export const useSubmitKycInfo = ({
  onSuccess,
}: {
  onSuccess: () => Promise<void>;
}) => {
  const [updateLegalDetails] = useUpdateLegalInfoMutation();
  const { setUserPropertyAction } = useAnalyticsReportingService();

  const [isLoading, submit] = useLoadingCallback<SubmitFormHandler>(
    async (values) => {
      setUserPropertyAction({
        type: 'SET_DATA',
        data: {
          dob: values.dob.format('YYYY-MM-DD'),
        },
      });

      await updateLegalDetails({
        variables: {
          legalInfo: {
            firstName: values.firstName,
            lastName: values.lastName,
            middleNames: values.middleNames,
            dob: values.dob.format('YYYY-MM-DD'),
            addressId: values.address.id,
          },
        },
      });

      await onSuccess();
    },
    [onSuccess]
  );

  return {
    submit,
    isLoading,
  };
};
