import { style } from '@xstyled/styled-components';

export interface BorderRadiusProps {
  borderRadiusTopLeft?: number | string;
  borderRadiusTopRight?: number | string;
  borderRadiusBottomLeft?: number | string;
  borderRadiusBottomRight?: number | string;
}

export const borderRadiusTopLeft = style({
  prop: 'borderRadiusTopLeft',
  css: (x) => ({
    'border-top-left-radius': x,
  }),
});

export const borderRadiusTopRight = style({
  prop: 'borderRadiusTopRight',
  css: (x) => ({
    'border-top-right-radius': x,
  }),
});

export const borderRadiusBottomLeft = style({
  prop: 'borderRadiusBottomLeft',
  css: (x) => ({
    'border-bottom-left-radius': x,
  }),
});

export const borderRadiusBottomRight = style({
  prop: 'borderRadiusBottomRight',
  css: (x) => ({
    'border-bottom-right-radius': x,
  }),
});
