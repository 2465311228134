import { useEffect } from 'react';

export const useDisableBodyScrollWhenMounted = () => {
  useEffect(() => {
    const html = document.getElementsByTagName('html')[0];
    const body = document.getElementsByTagName('body')[0];
    html.classList.add('disable-scroll');
    body.classList.add('disable-scroll');
    return () => {
      html.classList.remove('disable-scroll');
      body.classList.remove('disable-scroll');
    };
  }, []);
};
