import { useCallback } from 'react';

import { useRouter } from 'next/router';

import { logger } from '@ui-furniture/common/services/logger';

function useRouterState<TOption extends string | undefined = string>(
  paramName: string,
  {
    defaultValue = undefined,
    isBlank = (newValue: any) => !newValue,
    shallow = true,
  }: {
    defaultValue?: TOption;
    isBlank?: (newValue: any) => boolean;
    shallow?: boolean;
  } = {}
): [TOption | undefined, (newValue?: TOption) => Promise<boolean>] {
  const { query, push } = useRouter();
  const removeValue = useCallback(() => {
    const path = new URL(window.location.href);
    path.searchParams.delete(paramName);
    const url = path.pathname + path.search;
    return push(url, url, { shallow });
  }, [push, paramName, shallow]);
  const setValue = useCallback(
    (newValue?: TOption) => {
      if (isBlank(newValue)) return removeValue();

      const path = new URL(window.location.href);
      path.searchParams.set(paramName, newValue!);
      const url = path.pathname + path.search;
      return push(url, url, { shallow });
    },
    [push, isBlank, removeValue, paramName, shallow]
  );

  const paramValue = query[paramName];
  let singleParamValue: TOption | undefined;
  if (Array.isArray(paramValue)) {
    logger.warn(
      'Router state should only have a single param value, selecting the first'
    );
    // eslint-disable-next-line prefer-destructuring
    singleParamValue = paramValue[0] as TOption;
  } else {
    singleParamValue = paramValue as TOption;
  }

  return [singleParamValue || defaultValue, setValue];
}

export default useRouterState;
