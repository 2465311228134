import { createStoryFactory } from '@support/common/storybook';

import { Card } from './card';

const { definition, createStory, fakeData } = createStoryFactory(Card, {
  category: 'Elements',
  folder: 'Card',
});

export default {
  title: 'Elements/Card',
  ...definition,
};

export const Default = createStory()
  .setProp({
    name: 'children',
    value: <>{fakeData.lorem.paragraph()}</>,
    disableEdit: true,
  })
  .setProp({
    name: 'p',
    value: 'md',
  })
  .setProp({
    name: 'maxW',
    value: '400px',
  })
  .render();
