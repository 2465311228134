import { useCallback, useState } from 'react';

let history: number[] = [0];

export const useFlowHistory = () => {
  const [pos, _setPos] = useState(0);

  const increment = useCallback((step: number) => {
    history = [...history, step];
    _setPos(step);
  }, []);
  const decrement = useCallback(() => {
    history = history.slice(0, -1);
    _setPos(history[history.length - 1]);
  }, []);

  return {
    pos,
    increment,
    decrement,
  };
};
