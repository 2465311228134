import loadable from '@loadable/component';

import { logger } from '@ui-furniture/common/services/logger';
import { VoidStyledComponent, x } from '@ui-furniture/style';

const variants = {
  none: {},
  circled: {
    borderRadius: '50%',
    border: '1px solid currentColor',
    padding: '8px',
  },
};
const sizeMap = {
  sm: '24px',
  md: '32px',
  lg: '48px',
  xl: '60px',
  '2xl': '120px',
};
const iconMap = {
  AIContractReview: loadable(
    () => import('@ui-furniture/assets/icons/AI Contract Review.svg')
  ),
  brain: loadable(() => import('@ui-furniture/assets/icons/Brain.svg')),
  checkSquare: loadable(
    () => import('@ui-furniture/assets/icons/Check Square.svg')
  ),
  check: loadable(() => import('@ui-furniture/assets/icons/Solid Check.svg')),
  clockDash: loadable(
    () => import('@ui-furniture/assets/iconly/solid/Clock Dash.svg')
  ),
  coins: loadable(() => import('@ui-furniture/assets/iconly/solid/Coins.svg')),
  cross: loadable(() => import('@ui-furniture/assets/icons/Solid Cross.svg')),
  circle: loadable(() => import('@ui-furniture/assets/icons/Circle.svg')),
  coinStack: loadable(
    () => import('@ui-furniture/assets/icons/Coin Stack.svg')
  ),
  display: loadable(
    () =>
      import(
        '@ui-furniture/assets/iconly/solid/monitor display stand 2 - 6.svg'
      )
  ),
  error: loadable(() => import('@ui-furniture/assets/icons/Error Hexagon.svg')),
  forbidden: loadable(() => import('@ui-furniture/assets/icons/Forbidden.svg')),
  homeHeart: loadable(
    () => import('@ui-furniture/assets/iconly/solid/Home Heart.svg')
  ),
  info: loadable(() => import('@ui-furniture/assets/icons/Info Diamond.svg')),
  locationPin: loadable(
    () => import('@ui-furniture/assets/iconly/solid/Location - 3 1.svg')
  ),
  person: loadable(
    () => import('@ui-furniture/assets/icons/Person Magnified.svg')
  ),
  profileSignedIn: loadable(
    () => import('@ui-furniture/assets/icons/Profile Circle Primary.svg')
  ),
  profileSignedOut: loadable(
    () => import('@ui-furniture/assets/icons/Profile Circle.svg')
  ),
  question: loadable(
    () => import('@ui-furniture/assets/icons/Interrogation Mark.svg')
  ),
  reservationAgreement: loadable(
    () => import('@ui-furniture/assets/icons/ReservationAgreement.svg')
  ),
  scales: loadable(
    () => import('@ui-furniture/assets/iconly/solid/Scales.svg')
  ),
  shield: loadable(() => import('@ui-furniture/assets/icons/Shield.svg')),
  stars: loadable(() => import('@ui-furniture/assets/iconly/solid/Stars.svg')),
  success: loadable(
    () => import('@ui-furniture/assets/icons/Success Circle.svg')
  ),
  triangle: loadable(() => import('@ui-furniture/assets/icons/Triangle.svg')),
  triangleActive: loadable(() => import('./triangle-active-icon')),
  verified: loadable(() => import('@ui-furniture/assets/icons/Verified.svg')),
  verifiedLight: loadable(
    () => import('@ui-furniture/assets/icons/Verified Light.svg')
  ),
  warning: loadable(
    () => import('@ui-furniture/assets/icons/Warning Triangle.svg')
  ),
};

export type IconName = keyof typeof iconMap;
export const iconOptions = Object.keys(iconMap);

const preferredColor = {
  clockDash: '#1d1db4',
  coins: '#b5b5b5',
  homeHeart: '#e41f1f',
  stars: '#dddd19',
} as Record<IconName, string | undefined>;

export const SolidIcon: VoidStyledComponent<{
  name: IconName;
  variant?: keyof typeof variants;
  size?: string;
}> = ({ name, variant = 'none', size = 'xl', ...wrapperProps }) => {
  const variantProps = variants[variant];
  const sizeValue = sizeMap[size] || size;
  const IconComponent = iconMap[name];
  if (!IconComponent) {
    logger.error(`No Icon was found for ${name}`);
    return null;
  }
  return (
    <x.div
      display='inline-block'
      w={sizeValue}
      h={sizeValue}
      minHeight={sizeValue}
      minWidth={sizeValue}
      color={preferredColor[name]}
      {...variantProps}
      {...wrapperProps}
    >
      <IconComponent width='100%' height='100%' />
    </x.div>
  );
};
