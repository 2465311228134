import { FC, forwardRef } from 'react';

import useMediaQuery from '@mui/material/useMediaQuery';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { Dayjs } from 'dayjs';

import { InputBorder } from '@ui-furniture/common/form/common';

import { CalendarButton } from './calendar-button';
import { GlobalStyles } from './global-styles';
import { DatePickerFieldProps } from './types/field-props';

import { BlankInput } from '../common/blank-input';

export const StyledDatePickerField: FC<
  DatePickerFieldProps & {
    setOpen: (open: boolean) => void;
    defaultDate?: Dayjs;
  }
> = forwardRef((props, ref) => {
  const { onBlur, onCalendarClick, placeholder, defaultDate, value, ...rest } =
    props;
  const desktopMediaQuery = '(pointer: fine)';
  const isDesktopVersion = useMediaQuery(desktopMediaQuery);

  return (
    <>
      <GlobalStyles />
      <MuiDatePicker
        {...rest}
        ref={ref}
        value={value || defaultDate}
        desktopModeMediaQuery={`@media ${desktopMediaQuery}`}
        PopperProps={{
          placement: 'bottom',
        }}
        renderInput={({ inputRef, inputProps }) => (
          <InputBorder
            onClick={() => {
              if (!isDesktopVersion) props.setOpen(true);
            }}
          >
            <BlankInput
              {...inputProps}
              placeholder={placeholder}
              ref={inputRef}
              flex='1'
              onBlur={() => {
                onBlur?.();
              }}
            />
            <CalendarButton
              isHighlighted={props.open}
              onClick={() => {
                onCalendarClick?.();
              }}
            />
          </InputBorder>
        )}
      />
    </>
  );
});
