import { useEffect } from 'react';

import { NextRouter, useRouter } from 'next/router';

export const usePathChange = ({
  onPathChange,
}: {
  onPathChange: (path: string, router: NextRouter) => any;
}) => {
  const router = useRouter();
  const { asPath, query } = router;

  useEffect(() => onPathChange(asPath, router), [asPath, query]);
};
