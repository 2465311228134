import { useCallback } from 'react';

import { useRouter } from 'next/router';

import { PATHS } from '@ui-furniture/constants/paths';

const { createRoute, ...pageToPathMap } = PATHS.webApp;

export const useGoToPage = () => {
  const router = useRouter();

  const goToPage = useCallback((pageName: keyof typeof pageToPathMap): void => {
    const path = pageToPathMap[pageName];
    router.push(path).catch((err) => {
      throw err;
    });
  }, []);

  return {
    goToPage,
  };
};
