import { Dayjs } from 'dayjs';
import { RawCreateParams, z } from 'zod';

import outcodesList from '@ui-furniture/common/outcodes/data.json';

import { currencyFormatter } from './data-transform/currency-formatter';

export type ZodOptions = Record<
  string,
  Array<{ label: string; value: string }>
>;

const contactDetails = ({
  isPhoneOptional = true,
}: {
  isPhoneOptional?: boolean;
}) => {
  return z.object({
    firstName: z.string().min(2, 'You name must be at least 2 letters'),
    lastName: z.string().min(2, 'You name must be at least 2 letters'),
    email: z
      .string()
      .email({ message: `This doesn't look like a valid email address` }),
    // eslint-disable-next-line no-use-before-define
    phone: phone({
      isOptional: isPhoneOptional,
    }),
    updateDefaultContactDetails: z.boolean().optional(),
  });
};

const date = ({
  errorMessage = 'Please enter a date',
  optional = false,
  rules = undefined,
}: {
  optional?: boolean;
  errorMessage?: string;
  rules?: {
    min?: Dayjs;
    max?: Dayjs;
  };
} = {}) =>
  z.custom<Dayjs>(
    (value: Dayjs) => {
      if (!value) return optional;

      if (!value.isValid) return false;
      if (rules) {
        if (rules.min && value.isBefore(rules.min)) return false;
        if (rules.max && value.isAfter(rules.max)) return false;
      }
      return value.isValid();
    },
    {
      message: errorMessage,
    }
  );

const phoneRegex =
  /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?#(\d{4}|\d{3}))?$/;

const phone = ({
  errorMessage = 'Please enter a valid phone number',
  isOptional = false,
}: {
  errorMessage?: string;
  isOptional?: boolean;
} = {}) =>
  z
    .custom(
      (data: string) => {
        if (!data && isOptional) return true;
        return phoneRegex.test(data);
      },
      {
        message: errorMessage,
      }
    )
    .transform((data: string) => {
      if (data) {
        return data;
      }
      return undefined;
    });

const currency = ({
  validation = (currencySchema) => currencySchema,
  ...numberConfig
}: {
  validation?: (currencySchema: z.ZodNumber) => z.ZodNumber;
} & Parameters<typeof z.number>[0] = {}) =>
  z.preprocess(currencyFormatter.parse, validation(z.number(numberConfig)));

const select = (
  value: z.ZodTypeAny = z.string(),
  label: z.ZodTypeAny = z.string()
) => {
  return z.object({
    label,
    value,
  });
};

const radioFromList = <TValue extends string>([
  firstOption,
  ...restOptions
]: Array<{
  label: string;
  value: TValue;
}>) => {
  return z.enum([
    firstOption.value,
    ...restOptions.map((option) => option.value as TValue),
  ]);
};

const selectFromList = <TValue extends string>(
  [firstOption, ...restOptions]: Array<{
    label: string;
    value: TValue;
  }>,
  rawCreateParams: RawCreateParams = {}
) => {
  return z.object(
    {
      label: z.enum([
        firstOption.label,
        ...restOptions.map(({ label }) => label),
      ]),
      value: z.enum([
        firstOption.value,
        ...restOptions.map(({ value }) => value as TValue),
      ]),
    },
    {
      invalid_type_error: 'Please select an option',
      ...rawCreateParams,
    }
  );
};

const selectFromNestedList = (
  list: Array<{
    label: string;
    options: Array<{
      label: string;
      value: string;
    }>;
  }>
) => {
  const [firstOption, ...restOptions] = list.flatMap((group) => group.options);

  return z.object({
    label: z.enum([
      firstOption.label,
      ...restOptions.map((option) => option.label),
    ]),
    value: z.enum([
      firstOption.value,
      ...restOptions.map((option) => option.value),
    ]),
  });
};

const [firstOutcode, ...restOutcodes] = outcodesList.map(
  ({ outcode }) => outcode
);
const outcode = (params?: RawCreateParams) =>
  select(z.enum([firstOutcode, ...restOutcodes], params), z.any());

const address = () =>
  z.object({
    id: z.string({
      required_error: 'Please select an option from the dropdown',
    }),
    line1: z
      .string({
        required_error: 'The first line of the address is required',
      })
      .min(1, { message: 'The first line of the address is required' }),
    line2: z.string().optional(),
    line3: z.string().optional(),
    city: z
      .string({
        required_error: 'The town/city is required',
      })
      .min(1, { message: 'The town/city is required' }),
    postcode: z
      .string({
        required_error: 'The postcode is required',
      })
      .min(1, { message: 'The postcode is required' }),
  });

type Address = {
  id: string;
  line1: string;
  line2?: string;
  line3?: string;
  city: string;
  postcode: string;
};
const oneLineAddress = ({
  errorMessage = 'Please select an address from the dropdown',
}: {
  errorMessage?: string;
} = {}) =>
  z.custom<Address>(
    (data: Address | undefined) => {
      if (!data) return false;
      if (!data.id) return false;
      return true;
    },
    {
      message: errorMessage,
    }
  );

export const zod = {
  address,
  contactDetails,
  currency,
  date,
  oneLineAddress,
  outcode,
  phone,
  radioFromList,
  select,
  selectFromList,
  selectFromNestedList,
};
