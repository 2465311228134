import config from '@ui-furniture/config';

import { AnalyticsSubscriber } from '../analytics/types/analytics-subscriber';
import { logger } from '../services/logger';

const { shouldLog } = config.analytics;

export const consoleSubscriber: AnalyticsSubscriber = ({
  eventSubject,
  objectiveSubject,
  userIdSubject,
  userPropertyActionsSubject,
  resetSubject,
}) => {
  if (shouldLog) {
    logger.debug('ANALYTICS - LOGGING ENABLED');

    eventSubject.subscribe((event) => {
      logger.debug('ANALYTICS - EVENT:', event);
    });

    objectiveSubject.subscribe((event) => {
      logger.debug('ANALYTICS - OBJECTIVE:', event);
    });

    userIdSubject.subscribe((userId) => {
      if (userId !== null) logger.debug('ANALYTICS - USER ID:', userId);
    });
    userPropertyActionsSubject.subscribe(({ type, ...rest }) => {
      logger.debug('ANALYTICS - USER PROPERTY ACTIONS:', type, rest);
    });
    resetSubject.subscribe((doReset) => {
      if (doReset) logger.debug('ANALYTICS - RESET');
    });
  }
};
