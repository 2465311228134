import {
  StoryFactory,
  ChromaticStorybookPlugin,
  StoryPluginDecorator,
  PluginConfig,
} from '@support/lib/storybook';
import { StoryFactoryOptions } from '@support/lib/storybook/factory/types/story-factory-options';

import { FC } from 'react';

import { initFakeDataFromWord } from '../fake-data/init-from-word';

const createPlugins = <T>(config: PluginConfig<T>) => ({
  chromatic: new ChromaticStorybookPlugin(config),
});

export const createStoryFactory = <TComponent extends FC<any> | FC<any>>(
  component: TComponent,
  options: StoryFactoryOptions
) => {
  const factory = new StoryFactory(component, options);
  return {
    fakeData: initFakeDataFromWord(factory.title),
    ...factory,
    ...new StoryPluginDecorator(factory, createPlugins),
  };
};
