import { ReactNode } from 'react';

import {
  ControlledFormInput,
  FormInputComponent,
} from '@ui-furniture/lib/form';
import { ScreenProp, SystemProps } from '@ui-furniture/style';
import { RadioButtons } from '@ui-furniture/ui/radio-buttons';

import { Label, LabelText, InputBorder, ErrorMessage } from '../common';

export const RadioButtonGroup: FormInputComponent<{
  label: string;
  informationTooltip?: ReactNode;
  isOptional?: boolean;
  w?: SystemProps['w'];
  stackVerticallyBeforeBreakpoint?: ScreenProp;
  options: Array<{
    label: ReactNode;
    value: any;
  }>;
}> = ({
  name,
  isOptional = false,
  registerOptions,
  label,
  stackVerticallyBeforeBreakpoint,
  informationTooltip,
  options,
  w,
}) => {
  return (
    <ControlledFormInput
      name={name}
      registerOptions={registerOptions}
      render={({ field }) => (
        <Label as='div' w={w}>
          <LabelText
            isOptional={isOptional}
            informationTooltip={informationTooltip}
          >
            {label}
          </LabelText>
          <InputBorder>
            <RadioButtons
              variant='small'
              w='100%'
              stackVerticallyBeforeBreakpoint={stackVerticallyBeforeBreakpoint}
              controls={{
                options,
                selected: field.value,
                setSelected: field.onChange,
              }}
            />
          </InputBorder>
          <ErrorMessage />
        </Label>
      )}
    />
  );
};
