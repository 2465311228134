import { ComponentLike, forwardRef } from 'react';

import { x } from '@ui-furniture/style';

export const BlankInput: ComponentLike<typeof x.input> = forwardRef(
  ({ className, ...rest }, ref) => (
    <x.input
      ref={ref}
      className={className ? `read ${className}` : 'read'}
      border='none'
      outline='none'
      background='transparent'
      padding='md'
      w='100%'
      color={{
        ':placeholder': 'placeholder',
      }}
      {...rest}
    />
  )
);
