import { useCallback, useEffect, useState } from 'react';

export const useIsInProgress = () => {
  const [isInProgress, setIsInProgress] = useState(false);
  useEffect(() => {
    setIsInProgress(false);
    return () => setIsInProgress(false);
  }, []);
  const setProgressing = useCallback(() => setIsInProgress(true), []);
  return [isInProgress, setProgressing] as const;
};
