import { AxiosError } from 'axios';
import { useRouter } from 'next/router';

import { apiRestClient } from '@ui-furniture/clients/api-rest-client';
import { errorService } from '@ui-furniture/common/services/error';
import { useToast } from '@ui-furniture/common/toast';
import { useLoadingCallback } from '@ui-furniture/hooks/use-loading-callback';

export const useCodeVerification = () => {
  const { push } = useRouter();
  const { showToast } = useToast();

  const [isVerifying, verify] = useLoadingCallback(
    async ({ code, token }: { code: string; token: string }) => {
      try {
        const res = await apiRestClient.useToken({
          code,
          token,
        });
        return {
          success: true,
          redirect: res.data.redirect,
        };
      } catch (error) {
        const { response } = error as AxiosError;
        if (response?.status === 403) {
          showToast({
            severity: 'warning',
            message: 'The code you entered is invalid. Please try again.',
          });
        } else {
          showToast({
            severity: 'error',
            message: `Something went wrong. Please try again later.`,
          });
          errorService.captureException(error);
        }
        return {
          success: false,
        };
      }
    },
    [apiRestClient, showToast, push]
  );

  return {
    isVerifying,
    verify,
  };
};
