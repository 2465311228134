import { AnimatePresence, motion } from 'framer-motion';
import { Portal } from 'react-portal';

import { StyledProps } from '@ui-furniture/style';
import { DisableBodyScrollWhenMounted } from '@ui-furniture/ui/disable-body-scroll-when-mounted';

import { ModalControlsProvider } from './context/modal-controls';
import { useCloseOnEscape } from './hooks/use-close-on-escape';
import { ModalControls } from './types/controls';
import { ModalComponent } from './types/modal-component';
import { variants } from './variants';

export const Modal: ModalComponent<{
  controls: ModalControls;
  disableClose?: boolean;
  contentPadding?: string | number;
  contentProps?: StyledProps;
  scrollContainerId?: string;
}> = ({
  controls,
  disableClose = false,
  variant: variantName = 'fullScreen',
  contentPadding,
  contentProps,
  children,
  scrollContainerId,
}) => {
  const { Container, Overlay, Content, CloseButton, animations } =
    variants[variantName];

  useCloseOnEscape(controls, {
    isDisabled: disableClose,
  });

  return (
    <Portal>
      <ModalControlsProvider controls={controls}>
        <AnimatePresence mode='wait'>
          {controls.isOpen ? (
            <DisableBodyScrollWhenMounted>
              <Container
                animate='visible'
                exit='hidden'
                initial='hidden'
                variants={animations.container}
                id={scrollContainerId}
              >
                <Overlay onClick={controls.close} />
                <Content
                  role='dialog'
                  id={controls.id}
                  as={motion.div as any}
                  variants={animations.content}
                  p={contentPadding}
                  {...contentProps}
                >
                  {!disableClose && (
                    <CloseButton display='block' onClick={controls.close} />
                  )}
                  {children}
                </Content>
              </Container>
            </DisableBodyScrollWhenMounted>
          ) : null}
        </AnimatePresence>
      </ModalControlsProvider>
    </Portal>
  );
};
