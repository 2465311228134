import { FC, PropsWithChildren } from 'react';

import { x } from '@ui-furniture/style';

import { Spinner } from './spinner';

export const LoadingInline: FC<
  PropsWithChildren<{
    isLoading: boolean;
    display?: string;
  }>
> = ({ isLoading, display = 'inline-block', children }) => (
  <x.span position='relative' display={display}>
    <x.span
      display={display}
      pointerEvents={isLoading ? 'none' : 'auto'}
      opacity={isLoading ? 0 : 1}
      transition='fast'
    >
      {children}
    </x.span>
    <x.span
      pointerEvents='none'
      position='absolute'
      top={0}
      left={0}
      right={0}
      bottom={0}
      display='flex'
      justifyContent='center'
      alignItems='center'
      opacity={isLoading ? '1' : '0'}
      transition='fast'
    >
      <Spinner isLoading={isLoading} />
    </x.span>
  </x.span>
);
