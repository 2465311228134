import { createContext, PropsWithChildren, useContext } from 'react';

import { useIsInProgress } from '@ui-furniture/common/hooks/use-is-in-progress';
import { useKycUserIdentityCheck } from '@ui-furniture/hooks/use-kyc-user-identity-check';

const KycFlowManagerContext = createContext<{
  setProgressing: () => void;
  status: 'loading' | 'completed' | 'incomplete';
}>(null as any);

const getStatus = ({
  isInProgress,
  hasLoaded,
  hasSubmittedACheck,
}: {
  isInProgress: boolean;
  hasLoaded: boolean;
  hasSubmittedACheck: boolean;
}): 'loading' | 'completed' | 'incomplete' => {
  if (isInProgress) return 'incomplete';
  if (!hasLoaded) return 'loading';
  if (!hasSubmittedACheck) return 'incomplete';

  return 'completed';
};

export const KycFlowManagerProvider = (props: PropsWithChildren) => {
  const { hasSubmittedACheck, hasLoaded } = useKycUserIdentityCheck();
  const [isInProgress, setProgressing] = useIsInProgress();
  return (
    <KycFlowManagerContext.Provider
      {...props}
      value={{
        setProgressing,
        status: getStatus({ isInProgress, hasLoaded, hasSubmittedACheck }),
      }}
    />
  );
};

export const useKycFlowManager = () => {
  const context = useContext(KycFlowManagerContext);
  if (!context) {
    throw new Error(
      'useKycFlowManager must be used within a KycFlowManagerProvider'
    );
  }
  return context;
};
