/* eslint-disable @typescript-eslint/member-ordering */
import { ComponentProps, FC, PropsWithChildren } from 'react';

import { StoryFn } from '@storybook/react';

import { StoryBuilder } from './story-builder';
import { StoryDefinition } from './story-definition';
import { StoryFactoryOptions } from './types/story-factory-options';

export class StoryFactory<TComponent extends FC<any> | FC<any>> {
  private _getTitle = () => {
    const { folder, category } = this._options;

    return folder ? `${category}/${folder}` : category;
  };
  public readonly title = this._getTitle();
  private readonly Container: FC<PropsWithChildren> = ({ children }) => (
    <>{children}</>
  );

  readonly definition = {
    component: this._component,
  };

  constructor(
    private readonly _component: TComponent,
    private readonly _options: StoryFactoryOptions
  ) {
    if (this._options.Container) this.Container = this._options.Container;
  }

  private _createRender: () => StoryFn<ComponentProps<TComponent>> =
    (): any =>
    ({
      _ContainerProps_ = {},
      ...props
    }: ComponentProps<TComponent> & { _ContainerProps_?: any }) =>
      (
        <this.Container {..._ContainerProps_}>
          <this._component {...(props as any)} />
        </this.Container>
      );

  createStory<
    TStoryProps extends ComponentProps<TComponent> = ComponentProps<TComponent>
  >(render = this._createRender()) {
    return new StoryBuilder(new StoryDefinition<TStoryProps>(render as any));
  }
}
