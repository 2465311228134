import { StoryFn } from '@storybook/react';
import { flatten, unflatten } from 'flat';
import merge from 'lodash/merge';

type TDefinitionProps = 'args' | 'argTypes' | 'parameters';

export class StoryDefinition<TComponentProps> {
  public render: StoryFn<TComponentProps> = ((props, context) =>
    this._render(unflatten(props), context)) as any;

  constructor(private readonly _render: StoryFn<TComponentProps>) {}

  public get hasControlsSet() {
    return this.render.argTypes || this.render.args;
  }

  private _mergeRenderProp = (propName: TDefinitionProps, value: any) => {
    if (!this.render[propName]) this.render[propName] = {};
    merge(this.render[propName], value);
    return this;
  };

  setParameter = (value: any) => {
    this._mergeRenderProp('parameters', value);
  };
  setArgTypeProp = (name: keyof TComponentProps, value: any) => {
    this._mergeRenderProp('argTypes', {
      [name]: value,
    });
  };
  setProp<TName extends keyof TComponentProps>(
    name: TName,
    value: TComponentProps[TName]
  ) {
    this._mergeRenderProp('args', {
      [name]: value,
    });
  }
  setPropObject(content: Partial<TComponentProps>) {
    const flatContent = flatten(content);
    Object.keys(flatContent).forEach((propName: any) =>
      this.setProp(propName, flatContent[propName])
    );
  }

  setName = (name: string) => {
    this.render.storyName = name;
  };
}
