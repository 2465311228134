import { styled } from '@ui-furniture/style';

export const Label = styled.labelBox`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  font-weight: 600;
  text-align: left;
  > * + * {
    margin-top: xs;
  }
`;
