import { FC, PropsWithChildren } from 'react';

import { useLocalStorage } from 'usehooks-ts';

import { flowManagerContext } from './context';
import { Callback, CallbackNames } from './types';

import { errorService } from '../services/error';

export const FlowManagerProvider: FC<
  PropsWithChildren<{
    callbacks: Callback[];
  }>
> = ({ children, callbacks }) => {
  const [callback, setRegisteredCallback] = useLocalStorage<
    Callback | undefined
  >('registeredCallback', undefined);

  const registerCallback = (
    callbackName: CallbackNames,
    options: Partial<Callback>
  ) => {
    const cb = callbacks.find((c) => c.name === callbackName);
    if (cb) {
      const newCb = { ...cb, ...options };
      setRegisteredCallback(newCb);

      return newCb;
    }

    errorService.captureException(
      new Error(`Could not register ${callbackName} because not found`),
      {
        extra: {
          callbacks,
        },
      }
    );
    return null;
  };

  const resetStorage = () => {
    setRegisteredCallback(undefined);
  };

  return (
    <flowManagerContext.Provider
      value={{ registerCallback, callback, resetStorage }}
    >
      {children}
    </flowManagerContext.Provider>
  );
};
