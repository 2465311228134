import { GetComponentProps } from '@support/types/utility/get-component-props';

import { motion } from 'framer-motion';

import { StyledComponent, useColor } from '@ui-furniture/style';

import { InputBorder as InputBorderComponent } from './components';

export const InputBorder: StyledComponent<
  GetComponentProps<typeof motion.div>
> = (props) => {
  const negativeColor = useColor('brand-negative') as string;
  return (
    <InputBorderComponent
      {...props}
      as={motion.div}
      variants={{
        error: {
          borderColor: negativeColor,
        },
      }}
    />
  );
};
