import 'numeral/locales/en-gb';
import numeral from 'numeral';

numeral.locale('en-gb');

class CurrencyFormatter {
  format = (value: number, { removeSymbol = false } = {}) =>
    numeral(value).format(removeSymbol ? `0,0` : `$0,0`);
  parse = (text: string) => numeral(text).value();
}

export const currencyFormatter = new CurrencyFormatter();
