import React from 'react';

import { x } from '@ui-furniture/style';
import { ArrowToggle } from '@ui-furniture/ui/arrow-toggle';

export const overrideComponents = {
  DropdownIndicator: () => (
    <x.div
      w='input'
      display='flex'
      alignItems='center'
      justifyContent={'center'}
    >
      <ArrowToggle size={24} initialDir='down' isToggled={false} />
    </x.div>
  ),
  IndicatorSeparator: () => (
    <x.div
      alignSelf='stretch'
      w='1px'
      backgroundColor='palette-border'
      marginBottom='8px'
      marginTop='8px'
    />
  ),
};
