import CalendarSvg from '@ui-furniture/assets/iconly/Calendar.svg';
import { Button } from '@ui-furniture/elements';
import { StyledComponent, x } from '@ui-furniture/style';

export const CalendarButton: StyledComponent<{
  isHighlighted?: boolean;
  onClick: () => void;
}> = ({ isHighlighted, onClick, ...rest }) => (
  <Button
    type='button'
    variant='blank'
    onClick={onClick}
    color={
      {
        _: isHighlighted ? 'palette-primary' : 'primary-text',
        hover: 'palette-primary',
        focus: 'palette-primary',
      } as any
    }
    w='26px'
    h='26px'
    mx='md'
    transition='base'
  >
    <x.svg {...rest} as={CalendarSvg} />
  </Button>
);
