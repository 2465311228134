import { styled, css } from '@ui-furniture/style';

export const Input = styled.inputBox`
  border: none;
  outline: none;
  background: transparent;
  padding: md;
  width: 20px;
  height: 20px;
  opacity: 0;
  z-index: 1;
`;

export const Check = styled.divBox<{
  $isChecked: boolean;
}>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: base;
  overflow: hidden;
  :after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 12px;
    height: 12px;
    background-image: url('/icons/check.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
  ${({ $isChecked }) =>
    $isChecked
      ? css`
          transition: fast;
          opacity: 1;
          right: 0;
        `
      : css`
          opacity: 0;
          right: 100%;
        `}
`;
