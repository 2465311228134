import config from '@ui-furniture/config';

import { AnalyticsSubscriber } from '../analytics/types/analytics-subscriber';

const { metaId } = config.analytics;

export const metaSubscriber: AnalyticsSubscriber = ({ eventSubject }) => {
  if (metaId) {
    eventSubject.subscribe(({ name, ...rest }) => {
      if (typeof fbq !== 'undefined') {
        fbq('trackCustom', name, {
          ...rest,
        });
      }
    });
  }
};
