import type { ActionMeta } from 'react-select';

import { useCallback } from 'react';

import { useGetAddressLazyQuery } from '@graphql/requests';

import { AddressFields } from './use-address-fields-registration';

export const useSelectAddress = (fields: AddressFields) => {
  const [getAddress] = useGetAddressLazyQuery();
  return useCallback(
    async (
      option: { label: string; value: string },
      { action }: ActionMeta<any>
    ) => {
      if (action === 'select-option') {
        const addressId = option.value;
        const response = await getAddress({
          variables: {
            id: addressId,
          },
        });
        const address = response.data?.address;
        if (!address)
          throw new Error(`Address ${addressId} could not be found`);

        const setValueOptions = {
          shouldValidate: true,
          shouldDirty: true,
          shouldTouch: true,
        };

        fields.id.setValue(address.id, setValueOptions);
        fields.line1.setValue(address.line1, setValueOptions);
        fields.line2.setValue(address.line2 || '', setValueOptions);
        fields.line3.setValue(address.line3 || '', setValueOptions);
        fields.city.setValue(address.city || '', setValueOptions);
        fields.postcode.setValue(address.postcode, setValueOptions);
      }
    },
    [fields]
  );
};
