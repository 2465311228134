import { FC } from 'react';

import { useDashboard } from '@/features/layout/state/dashboard';
import { DrawerOption } from '@/features/layout/types/drawer-option';

import { ResetUserInfoView } from '@ui-furniture/common/auth/views/reset-user-info';
import { SignInView } from '@ui-furniture/common/auth/views/sign-in';
import { SignUpView } from '@ui-furniture/common/auth/views/sign-up';
import { UpgradeAccountView } from '@ui-furniture/common/auth/views/upgrade-account';
import { ContentLayout } from '@ui-furniture/ui/layout/content-layout/content-layout';
import { Drawer } from '@ui-furniture/ui/layout/drawer/drawer';

import { AccountDrawer } from './account-drawer';
import { DrawerContent } from './drawer-content';

const getContent = ({
  activeDrawer,
  setActiveDrawer,
  closeDrawer,
}: {
  activeDrawer: DrawerOption | undefined;
  setActiveDrawer: (name: string) => void;
  closeDrawer: () => void;
}) => {
  switch (activeDrawer) {
    case 'sign-in':
      return (
        <DrawerContent>
          <SignInView
            onSuccess={closeDrawer}
            openSignUp={() => setActiveDrawer('sign-up')}
          />
        </DrawerContent>
      );
    case 'sign-up':
      return (
        <DrawerContent>
          <SignUpView
            onSuccess={closeDrawer}
            openSignIn={() => setActiveDrawer('sign-in')}
          />
        </DrawerContent>
      );
    case 'upgrade':
      return (
        <DrawerContent>
          <UpgradeAccountView />
        </DrawerContent>
      );
    case 'reset-user-info':
      return (
        <DrawerContent>
          <ResetUserInfoView />
        </DrawerContent>
      );
    case 'account':
      return null;
    default:
      return null;
  }
};

export const AccountDrawerWrapper: FC = () => {
  const { activeDrawer, closeDrawer, setActiveDrawer } = useDashboard();

  const activeDrawerContent = getContent({
    activeDrawer,
    closeDrawer,
    setActiveDrawer,
  });
  return (
    <Drawer isActive={!!activeDrawer} close={closeDrawer}>
      <ContentLayout drawer={<AccountDrawer />}>
        {activeDrawerContent ? <>{activeDrawerContent}</> : null}
      </ContentLayout>
    </Drawer>
  );
};
