import { Faker, faker } from '@faker-js/faker';

const toNumbers = (str: string) => {
  let out = 0;
  const len = str.length;
  let pos = len - 1;
  while (pos > -1) {
    out += (str.charCodeAt(pos) - 64) * 26 ** (len - 1 - pos);
    pos -= 1;
  }
  return out;
};

export const seedFromWord = (seedWord: string): Faker => {
  faker.seed(toNumbers(seedWord));
  return faker;
};
