export const getIsAnalyticsDisabled = () => {
  if (typeof window === 'undefined') {
    return false;
  }
  const query = new URLSearchParams(window.location.search);
  if (query.get('analytics') === 'disabled') {
    localStorage.setItem('analytics-disabled', 'true');
  }
  if (query.get('analytics') === 'enabled') {
    localStorage.setItem('analytics-disabled', 'false');
  }
  return localStorage.getItem('analytics-disabled') === 'true';
};
