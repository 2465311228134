import { FC, ReactNode } from 'react';

import { VStack } from '@ui-furniture/elements';
import { x } from '@ui-furniture/style';
import { ChildAnimation } from '@ui-furniture/ui/animation/child';

import { DrawerMenuHeader } from './drawer-menu-header';

const variants = {
  regular: {
    w: '320px',
    position: 'relative',
  },
  flow: {
    w: '262px',
  },
};

export const DrawerMenu: FC<{
  title?: string;
  customHeader?: ReactNode;
  top?: ReactNode;
  bottom?: ReactNode;
  py?: string;
  px?: string;
  variant?: keyof typeof variants;
}> = ({ title, top, bottom, py = '0', customHeader, variant = 'regular' }) => {
  return (
    <x.div {...variants[variant]} aria-label='drawer' h='screen-height'>
      <VStack justifyContent='space-between' minH='100%' py={py}>
        <VStack>
          <ChildAnimation>
            <DrawerMenuHeader title={title} customHeader={customHeader} />
          </ChildAnimation>
          {top}
        </VStack>
        {bottom}
      </VStack>
    </x.div>
  );
};
