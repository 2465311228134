import { FC } from 'react';

import { x } from '@/style';

import { emptyFn } from '@ui-furniture/common/utils/emptyFn';
import { HStack } from '@ui-furniture/elements';
import { Breadcrumbs } from '@ui-furniture/ui/breadcrumbs';
import { TextButtonWithIcon } from '@ui-furniture/ui/buttons/text-button-with-icon';

import { PreviousButton } from './previous-button';

export const Header: FC<{
  activePos: number;
  stepCount: number;
  decrement: () => any;
  close?: () => any;
  disableClose: boolean;
  disablePreviousButton: boolean;
  isPreviousDisabledOnLastStep: boolean;
}> = ({
  activePos,
  stepCount,
  decrement,
  disableClose,
  disablePreviousButton,
  isPreviousDisabledOnLastStep,
  close,
}) => {
  const isCloseEnabled = close !== undefined;

  return (
    <HStack
      my={{ _: 'md', tablet: 'xl' }}
      px='lg'
      w='100%'
      alignItems='center'
      justifyContent='space-between'
    >
      <PreviousButton
        isDisabled={disablePreviousButton}
        isPreviousDisabledOnLastStep={isPreviousDisabledOnLastStep}
        activePos={activePos}
        totalPos={stepCount}
        decrementPos={decrement}
        showTextBreakpoint={'tablet'}
      />

      <Breadcrumbs activePos={activePos} count={stepCount} />

      {!disableClose ? (
        <TextButtonWithIcon
          iconName='close'
          text='Close'
          onClick={close || emptyFn}
          isDisabled={!isCloseEnabled}
          showTextBreakpoint={'tablet'}
          isReverse
          animationVariant='scale'
        />
      ) : (
        <x.div flex='1' />
      )}
    </HStack>
  );
};
