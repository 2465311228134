import { styled } from '@ui-furniture/style';

import { variants } from './variants';

type TVersion = 'primary' | 'secondary';

export const Button = styled.buttonBox<{
  type: 'button' | 'submit' | 'reset';
  variant?: keyof typeof variants;
  version?: TVersion;
}>`
  background: transparent;
  color: inherit;
  text-align: center;
  min-height: button;
  :disabled {
    pointer-events: none;
  }
  ${({ variant = 'rounded' }) => variants[variant]}
`;
