import { FC, PropsWithChildren } from 'react';

import { useDynamicSetup } from '@ui-furniture/common/hooks/use-dynamic-setup';

export const DynamicSetupContainer: FC<PropsWithChildren> = ({ children }) => {
  const isReady = useDynamicSetup([
    {
      name: 'Mock Apis',
      isNeeded: process.env.NEXT_PUBLIC_MOCK_APIS === 'true',
      runTask: async () => import('@support/setup-mock-apis'),
    },
  ]);

  return isReady ? <>{children}</> : null;
};
