import { useMemo } from 'react';

import { createHandleErrorLink } from '@ui-furniture/common/gql-links/create-handle-error-link';

import { useGoToPage } from './use-go-to-page';

export const useAuthenticationErrorLink = () => {
  const { goToPage } = useGoToPage();

  const onAuthenticationError = useMemo(
    () =>
      createHandleErrorLink('UNAUTHENTICATED', () => {
        goToPage('dashboard');
      }),
    []
  );

  return onAuthenticationError;
};
