import { randomPhoto, randomProfilePhoto } from '@support/lib/fake-data';

import { Faker } from '@faker-js/faker';
import times from 'lodash/times';

export const addCustomFields = (faker: Faker) => {
  const custom = {
    photo: randomPhoto(faker),
    profilePhoto: randomProfilePhoto(faker),
  };
  return {
    custom,
    repeat: times,
    ...faker,
  };
};

export type FakeDataWithCustomFields = ReturnType<typeof addCustomFields>;
