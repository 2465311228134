import { StyledProps } from '@ui-furniture/style';

export const variantProps: Record<
  'primary' | 'secondary' | 'scrollspy',
  {
    button?: { selected?: StyledProps; notSelected?: StyledProps };
    div?: { selected?: StyledProps; notSelected?: StyledProps };
  }
> = {
  primary: {
    button: {
      selected: {
        backgroundColor: {
          _: 'rgba(65, 79, 101, 0.12)',
          hover: 'rgba(65, 79, 101, 0.12)',
        },
        boxShadow: {
          _: '4px 4px 10px 0px rgba(65, 79, 101, 0.15)',
        },
      },
      notSelected: {
        backgroundColor: {
          _: 'overlay-weak',
          hover: 'rgba(65, 79, 101, 0.12)',
        },
        boxShadow: {
          hover: '4px 4px 10px 0px rgba(65, 79, 101, 0.15)',
        },
      },
    },
    div: {
      selected: {
        boxShadow: 'inset -3px 0 0 rgba(235, 110, 70, 1)',
      },
      notSelected: {
        paddingRight: 'md',
      },
    },
  },
  secondary: {
    button: {
      selected: {
        backgroundColor: {
          _: 'rgba(65, 79, 101, 0.15)',
          hover: 'rgba(65, 79, 101, 0.12)',
        },
        boxShadow: {
          _: '4px 4px 10px 0px rgba(65, 79, 101, 0.15)',
        },
      },
      notSelected: {
        backgroundColor: {
          hover: 'rgba(65, 79, 101, 0.12)',
        },
        boxShadow: {
          hover: '4px 4px 10px 0px rgba(65, 79, 101, 0.15)',
        },
      },
    },

    div: {
      selected: {
        boxShadow: 'inset -3px 0 0 rgba(235, 110, 70, 1)',
      },
      notSelected: { paddingRight: 'md' },
    },
  },
  scrollspy: {
    div: {
      selected: {
        marginLeft: 'md',
        backgroundColor: '#FCFBF9',
        border: '1px solid',
        borderColor: 'brand-beige',
        borderRadius: 'sm 0 0 sm',
        borderRight: 'none',
        boxShadow: '4px 4px 10px 0px rgba(65, 79, 101, 0.15)',
      },
    },
  },
};
