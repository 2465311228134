import { ReactNode } from 'react';

import { HStack, Text } from '@ui-furniture/elements';
import {
  useFieldRegistration,
  FormInputComponent,
  FormInput,
} from '@ui-furniture/lib/form';

import { Input, Check } from './components';

import { Label, InputBorder, ErrorMessage } from '../common';

export const Checkbox: FormInputComponent<{
  label: NonNullable<ReactNode>;
}> = ({ name, registerOptions, label, ...rest }) => {
  const { registration, error, value } = useFieldRegistration(
    name,
    registerOptions
  );

  return (
    <FormInput error={error?.message}>
      <Label>
        <HStack alignItems='flex-start' justifyContent='stretch'>
          <InputBorder minH='unset' borderRadius='sm'>
            <Input {...rest} {...registration} type='checkbox' />
            <Check $isChecked={value} />
          </InputBorder>
          <Text alignSelf='center'>{label}</Text>
        </HStack>
        <ErrorMessage />
      </Label>
    </FormInput>
  );
};
