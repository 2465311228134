import { FC } from 'react';

import { Controller, ControllerProps, useFormContext } from 'react-hook-form';

import { FormInput } from './form-input';
import { FormInputRegistrationProps } from './types/form-input-registration-props';

type RenderHandler = ControllerProps['render'];
export const ControlledFormInput: FC<
  FormInputRegistrationProps & {
    render: RenderHandler;
  }
> = ({ name, registerOptions, render }) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      rules={registerOptions}
      render={(renderProps) => (
        <FormInput error={renderProps.fieldState.error?.message}>
          {render(renderProps)}
        </FormInput>
      )}
    />
  );
};
