import { forwardRef } from 'react';

import ReactSelect, { Props } from 'react-select';

import { useOverrideStyles } from '@ui-furniture/common/react-select';
import { overrideComponents } from '@ui-furniture/common/react-select/override-components';

export const BaseSelect = forwardRef<ReactSelect, Props>(
  ({ components, ...rest }, ref) => {
    const overrideStyles = useOverrideStyles();
    return (
      <ReactSelect
        ref={ref as any}
        components={{
          ...overrideComponents,
          ...components,
        }}
        {...rest}
        styles={overrideStyles}
      />
    );
  }
);
