import { FC, PropsWithChildren } from 'react';

import { AppProps } from 'next/app';

export const RenderPageLayout: FC<
  PropsWithChildren<Pick<AppProps, 'Component'>> & {
    defaultLayout: FC<PropsWithChildren>;
  }
> = ({ Component, defaultLayout, children }) => {
  const Layout = (Component as any).Layout || defaultLayout;

  return <Layout>{children}</Layout>;
};
