import { useCallback } from 'react';

import { useApolloClient } from '@apollo/client';
import { useRouter } from 'next/router';

import { apiRestClient } from '@ui-furniture/clients/api-rest-client';
import { useAnalyticsReportingService } from '@ui-furniture/common/analytics';
import { PATHS } from '@ui-furniture/constants/paths';

import { useCurrentUser } from './use-current-user';

export const useLogout = () => {
  const { push } = useRouter();
  const { reset: resetAnalytics } = useAnalyticsReportingService();
  const client = useApolloClient();
  const { refresh: refreshUser } = useCurrentUser();

  return useCallback(async () => {
    await apiRestClient.logout();
    await client.clearStore();
    await refreshUser();
    sessionStorage.clear();
    resetAnalytics();
    void push(PATHS.webApp.dashboard);
  }, []);
};
