import { style } from '@xstyled/styled-components';

import sizes from '../theme/sizes';

const widthOptions = {
  '2xs': sizes['content-width-2xs'],
  xs: sizes['content-width-xs'],
  sm: sizes['content-width-sm'],
  md: sizes['content-width'],
  lg: sizes['content-width-lg'],
  full: 'unset',
};

export interface ContentWidthProps {
  contentWidth?: keyof typeof widthOptions;
}

export const contentWidth = style({
  prop: 'contentWidth',
  css: (val) => ({
    maxWidth: widthOptions[val],
  }),
});
