import { FC, PropsWithChildren, useEffect, useMemo, useRef } from 'react';

import { AnalyticsService } from './analytics-service';
import { analyticsServiceContext } from './analytics-service-context';
import { AnalyticsSubscriber } from './types/analytics-subscriber';

export const AnalyticsServiceProvider: FC<
  PropsWithChildren<{
    subscribers: Array<AnalyticsSubscriber>;
  }>
> = ({ children, subscribers }) => {
  const sessionIds: Record<string, string> = {};

  const analyticsService = useMemo(() => {
    const service = new AnalyticsService();
    service.sessionIdSubject.subscribe(({ source, id }) => {
      sessionIds[source] = id;
    });
    return service;
  }, []);
  const isSubscribed = useRef(false);

  useEffect(() => {
    if (!isSubscribed.current) {
      analyticsService.subscribe(subscribers);
      isSubscribed.current = true;
    }
  }, []);

  return (
    <analyticsServiceContext.Provider
      value={{
        report: analyticsService,
        sessionIds,
      }}
    >
      {children}
    </analyticsServiceContext.Provider>
  );
};
