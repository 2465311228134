import { createContext, PropsWithChildren, useContext } from 'react';

import { ModalControls } from '../types/controls';

const modalContext = createContext<ModalControls>(null as any);

export const ModalControlsProvider = ({
  controls,
  children,
}: PropsWithChildren<{
  controls: ModalControls;
}>) => {
  return (
    <modalContext.Provider value={controls}>{children}</modalContext.Provider>
  );
};

export const useModalControls = () => {
  const context = useContext(modalContext);
  if (!context) {
    throw new Error(`You aren't currently in a modal`);
  }
  return context;
};
