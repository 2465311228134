interface AddressFields {
  line1?: string;
  line2?: string;
  line3?: string;
  city?: string;
  postCode?: string;
}

export type AutoCompleteType = 'none' | 'home';

const addressAutocompleteValues: Record<AutoCompleteType, AddressFields> = {
  none: {},
  home: {
    line1: 'address-line1',
    line2: 'address-line2',
    line3: 'address-line3',
    city: 'address-level2',
    postCode: 'post-code',
  },
};

export const getAutocompleteValues = (
  autoCompleteType: AutoCompleteType
): AddressFields => addressAutocompleteValues[autoCompleteType];
