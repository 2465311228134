import { FC, useCallback } from 'react';

import { BasicForm } from '@ui-furniture/common/form';
import { useToast } from '@ui-furniture/common/toast';
import { Button, Text, VStack } from '@ui-furniture/elements';
import { useLogout } from '@ui-furniture/hooks/use-logout';
import { Container } from '@ui-furniture/ui/layout/container';

export const ResetUserInfoView: FC = () => {
  const logout = useLogout();
  const { showToast } = useToast();

  const handleSubmit = useCallback(async () => {
    await logout();
    showToast({
      severity: 'success',
      message: 'Your data has been reset',
    });
  }, []);

  return (
    <Container variant='content' maxW='xs'>
      <BasicForm onSubmit={handleSubmit}>
        <VStack gap='md'>
          <VStack>
            <Text variant='subTitle' textAlign='center'>
              Are you sure?
            </Text>
            <Text textAlign='center'>
              Please confirm you want to reset the information you’ve provided
              so far.
            </Text>
          </VStack>
          <Button type='submit'>Confirm</Button>
        </VStack>
      </BasicForm>
    </Container>
  );
};
