import { FC } from 'react';

import { x } from '@/style';

import { CallbackNames } from '@ui-furniture/common/flow-manager/types';
import { useFlowControls } from '@ui-furniture/hooks/use-flow-controls';

import { AnimationContainer } from './animation-container';
import { Header } from './header';
import { Step } from './step';

import { useFlowHistory } from '../hooks/use-flow-history';
import { StepConfig } from '../types/step-config';

export const MultiStepFlow: FC<{
  steps: Array<StepConfig>;
  onComplete?: () => void;
  flowName?: CallbackNames;
  close?: () => any;
  disableClose?: boolean;
  disablePreviousButton?: boolean;
  isPreviousDisabledOnLastStep?: boolean;
  hideHeader?: boolean;
}> = ({
  steps,
  onComplete,
  close,
  flowName,
  disableClose = false,
  disablePreviousButton = false,
  isPreviousDisabledOnLastStep = false,
  hideHeader = false,
}) => {
  const lastPos = steps.length - 1;
  const { pos: activePos, increment, decrement } = useFlowHistory();
  const { closeFlow, moveToStep } = useFlowControls();

  const finishFlow = () => {
    if (flowName) closeFlow(flowName);
    if (close) close();
  };

  const prev = () => {
    moveToStep(activePos - 1, flowName);
    decrement();
  };

  return (
    <x.div
      display='flex'
      flexDirection='column'
      flex='1'
      minH='100%'
      aria-label='multi-step-flow'
      overflow='hidden'
      w='100%'
    >
      {!hideHeader && (
        <Header
          activePos={activePos}
          stepCount={steps.length}
          close={finishFlow}
          disableClose={disableClose}
          disablePreviousButton={disablePreviousButton}
          isPreviousDisabledOnLastStep={isPreviousDisabledOnLastStep}
          decrement={prev}
        />
      )}

      <AnimationContainer>
        {steps.map((stepData, pos) =>
          activePos === pos ? (
            <Step
              key={stepData.id}
              data={stepData}
              flowName={flowName}
              status={{
                pos,
                activePos,
                isFirst: pos === 0,
                isLast: pos === lastPos,
              }}
              onComplete={onComplete}
              increment={increment}
              decrement={decrement}
            />
          ) : null
        )}
      </AnimationContainer>
    </x.div>
  );
};
