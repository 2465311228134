import { FC } from 'react';

import { LayoutGroup, motion } from 'framer-motion';

import ArrowUp from '@ui-furniture/assets/iconly/Arrow - Up 2.svg';

type Direction = 'up' | 'down' | 'left' | 'right';
const rotationMap: Record<Direction, number> = {
  up: 0,
  right: 90,
  down: 180,
  left: 270,
};

export const ArrowToggle: FC<{
  isToggled: boolean;
  initialDir?: Direction;
  size?: number;
  strokeWidth?: number;
  color?: string;
}> = ({
  isToggled,
  initialDir = 'up',
  size = 28,
  strokeWidth = 1,
  color = '#374668',
}) => {
  const initialRotation = rotationMap[initialDir];

  return (
    <LayoutGroup>
      <motion.span
        initial={'initial'}
        variants={{
          initial: {
            rotate: initialRotation,
          },
          toggled: {
            rotate: initialRotation + 180,
          },
        }}
        animate={isToggled ? 'toggled' : 'initial'}
        style={{
          minWidth: `${size}px`,
          minHeight: `${size}px`,
          width: `${size}px`,
          height: `${size}px`,
          display: 'inline-block',
        }}
      >
        <ArrowUp
          style={{
            width: '100%',
            height: '100%',
            strokeWidth: `${strokeWidth}px`,
            color: `${color}`,
          }}
        />
      </motion.span>
    </LayoutGroup>
  );
};
