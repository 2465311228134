import { useCallback, useRef, useState } from 'react';

export const useImport = <T>(
  importCallback: () => Promise<T>
): [T | null, () => Promise<void>] => {
  const ref = useRef<T | null>(null);
  const [, setIsImported] = useState(false);
  const requestImport = useCallback(async () => {
    if (ref.current === null) {
      ref.current = await importCallback();
      setIsImported(true);
    }
  }, []);

  return [ref.current, requestImport];
};
