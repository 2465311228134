import { useState } from 'react';

import { useRequestSignUpMutation } from '@graphql/requests';

import { useAnalyticsReportingService } from '@ui-furniture/common/analytics';
import { useReferralCode } from '@ui-furniture/common/referral';
import { useToast } from '@ui-furniture/common/toast';
import { useCurrentUser } from '@ui-furniture/hooks/use-current-user';
import { useFlowControls } from '@ui-furniture/hooks/use-flow-controls';
import { useLoadingCallback } from '@ui-furniture/hooks/use-loading-callback';

import { useCodeVerification } from './use-code-verification';

import { CodeVerificationFormHandler } from '../ui/code-verification-form';
import { SignUpFormHandler } from '../ui/sign-up-form';

export const useSignUp = ({ onSuccess }: { onSuccess?: () => void }) => {
  const [token, setToken] = useState<string | undefined>();

  const { verify } = useCodeVerification();
  const [signUpMutation] = useRequestSignUpMutation();
  const { reportEvent, setUserPropertyAction } = useAnalyticsReportingService();

  const { referralCode, removeReferralCode } = useReferralCode();
  const { completeFlowSuccessfully } = useFlowControls();
  const { refresh: refreshUser } = useCurrentUser();
  const { showToast } = useToast();

  const [isRequestingToken, requestToken] =
    useLoadingCallback<SignUpFormHandler>(
      async ({
        firstName,
        lastName,
        email,
        subscribeToNewsletter,
        resetPersonalProfile = false,
      }) => {
        const res = await signUpMutation({
          variables: {
            userDetails: {
              email,
              firstName,
              lastName,
              subscribeToNewsletter,
              shouldLinkPersonalProfile: !resetPersonalProfile,
              referralCode,
            },
          },
        });
        const newToken = res.data?.requestSignUp;
        if (!newToken) throw new Error('Token was not returned');

        reportEvent({
          name: 'Sign Up',
          email,
          preferredName: firstName,
          isSignUp: 1,
        });
        setUserPropertyAction({
          type: 'SET_ONCE',
          key: 'unverifiedEmail',
          value: email,
        });

        setToken(newToken);
      },
      [referralCode]
    );

  const [isVerifyingCode, verifyCode] =
    useLoadingCallback<CodeVerificationFormHandler>(
      async ({ code }) => {
        if (!token) throw new Error('Token is not defined');

        const { success } = await verify({ token, code });
        if (success) {
          await refreshUser();
          removeReferralCode();
          reportEvent({
            name: 'Create Account Code Verified',
          });
          setUserPropertyAction({
            type: 'SET_ONCE',
            key: 'hasVerifiedAccount',
            value: true,
          });
          completeFlowSuccessfully({
            callbackName: 'create-account',
            conversion: true,
          });
          showToast({
            severity: 'success',
            message: `You've successfully signed up`,
          });
          onSuccess?.();
        }
      },
      [token]
    );

  return {
    requestToken,
    isRequestingToken,
    isVerifyingCode,
    verifyCode,
    hasToken: !!token,
  };
};
