import { FC } from 'react';

import { useRouter } from 'next/router';

import { useDashboard } from '@/features/layout/state/dashboard';

import { AuthStatus } from '@graphql/requests';

import { useAnalyticsReportingService } from '@ui-furniture/common/analytics';
import { useToast } from '@ui-furniture/common/toast';
import { HStack, VStack } from '@ui-furniture/elements';
import { useAuthStatus } from '@ui-furniture/hooks/use-auth-status';
import { useLogout } from '@ui-furniture/hooks/use-logout';
import { SideMenuButton } from '@ui-furniture/ui/layout/side-menu/side-menu-button';
import { LineIcon } from '@ui-furniture/ui/line-icon';

const AlwaysVisibleMenuItems: FC = () => {
  const { push } = useRouter();
  const { reportEvent } = useAnalyticsReportingService();
  return (
    <>
      <SideMenuButton
        onClick={() => {
          reportEvent({
            name: 'Click',
            on: 'Sidebar - Get in touch',
          });
          push('mailto:hi@housecure.co.uk');
        }}
        variant='secondary'
      >
        <HStack alignItems='center'>
          <LineIcon name='mail' mr='sm' size='sm' />
          Get in touch
        </HStack>
      </SideMenuButton>
      <SideMenuButton
        onClick={() => {
          reportEvent({
            name: 'Click',
            on: 'Sidebar - Request a feature',
          });
          push('mailto:support@housecure.co.uk?subject=Feature request');
        }}
        variant='secondary'
      >
        <HStack alignItems='center'>
          <LineIcon name='stars' mr='sm' size='sm' />
          Request a feature
        </HStack>
      </SideMenuButton>
    </>
  );
};

const SignUpMenuButton: FC = () => {
  const { setActiveDrawer, activeDrawer } = useDashboard();
  const { reportEvent } = useAnalyticsReportingService();
  return (
    <SideMenuButton
      isSelected={activeDrawer === 'sign-up'}
      onClick={() => {
        setActiveDrawer('sign-up');
        reportEvent({
          name: 'Click',
          on: 'Sidebar - Sign Up',
        });
      }}
    >
      <HStack alignItems='center'>
        <LineIcon name='userCreate' mr='sm' size='sm' />
        Sign up
      </HStack>
    </SideMenuButton>
  );
};

const SignInMenuButton: FC = () => {
  const { setActiveDrawer, activeDrawer } = useDashboard();
  const { reportEvent } = useAnalyticsReportingService();
  return (
    <SideMenuButton
      isSelected={activeDrawer === 'sign-in'}
      variant='secondary'
      onClick={() => {
        setActiveDrawer('sign-in');
        reportEvent({
          name: 'Click',
          on: 'Sidebar - Sign In',
        });
      }}
    >
      <HStack alignItems='center'>
        <LineIcon name='login' mr='sm' size='sm' />
        Sign in
      </HStack>
    </SideMenuButton>
  );
};

const ResetUserInfoButton: FC = () => {
  const { setActiveDrawer, activeDrawer } = useDashboard();
  const { reportEvent } = useAnalyticsReportingService();
  return (
    <SideMenuButton
      isSelected={activeDrawer === 'reset-user-info'}
      onClick={() => {
        setActiveDrawer('reset-user-info');
        reportEvent({
          name: 'Click',
          on: 'Sidebar - Reset user info',
        });
      }}
      variant='secondary'
    >
      <HStack alignItems='center'>
        <LineIcon name='userDelete' mr='sm' size='sm' />
        Reset user info
      </HStack>
    </SideMenuButton>
  );
};

const UpgradeAccountButton: FC = () => {
  const { setActiveDrawer, activeDrawer } = useDashboard();
  const { reportEvent } = useAnalyticsReportingService();
  return (
    <SideMenuButton
      isSelected={activeDrawer === 'upgrade'}
      onClick={() => {
        setActiveDrawer('upgrade');
        reportEvent({
          name: 'Click',
          on: 'Sidebar - Upgrade',
        });
      }}
      variant='secondary'
    >
      <HStack alignItems='center'>
        <LineIcon name='userUpgrade' mr='sm' size='sm' />
        Upgrade to a full account
      </HStack>
    </SideMenuButton>
  );
};

const SignOutButton: FC = () => {
  const { reportEvent } = useAnalyticsReportingService();
  const logout = useLogout();
  const { showToast } = useToast();

  return (
    <SideMenuButton
      onClick={async () => {
        reportEvent({
          name: 'Click',
          on: 'Sidebar - Sign Out',
        });
        await logout();
        showToast({
          severity: 'success',
          message: 'You have been signed out',
        });
      }}
      variant='secondary'
    >
      <HStack alignItems='center'>
        <LineIcon name='logout' mr='sm' size='sm' />
        Sign out
      </HStack>
    </SideMenuButton>
  );
};

export const AccountMenuItems: FC = () => {
  const authStatus = useAuthStatus();

  if (authStatus === AuthStatus.None) {
    return (
      <VStack spacing='md'>
        <AlwaysVisibleMenuItems />
        <SignInMenuButton />
        <SignUpMenuButton />
      </VStack>
    );
  }

  if (authStatus === AuthStatus.AnonymousVisitor) {
    return (
      <VStack spacing='md'>
        <AlwaysVisibleMenuItems />
        <ResetUserInfoButton />
        <SignInMenuButton />
        <SignUpMenuButton />
      </VStack>
    );
  }

  if (authStatus === AuthStatus.IdentifiedVisitor) {
    return (
      <VStack spacing='md'>
        <AlwaysVisibleMenuItems />
        <SignOutButton />
        <SignInMenuButton />
        <UpgradeAccountButton />
      </VStack>
    );
  }

  return (
    <VStack spacing='md'>
      <AlwaysVisibleMenuItems />
      <SignOutButton />
    </VStack>
  );
};
