import { FC } from 'react';

import { motion } from 'framer-motion';

import { Button, HStack, Text } from '@ui-furniture/elements';
import { time } from '@ui-furniture/lib/utils';
import { Breakpoint } from '@ui-furniture/style/types';
import { LineIcon, IconName } from '@ui-furniture/ui/line-icon';

const scaleAdjustment = 0.2;
const animationVariants = {
  x: {
    standard: {
      icon: {
        hover: { x: -3, opacity: 1 },
        visible: { x: 0, opacity: 1 },
        hidden: { x: 3, opacity: 0 },
      },
      text: {
        hover: { x: 0, opacity: 1 },
        visible: { x: 0, opacity: 1 },
        hidden: { x: 3, opacity: 0 },
      },
    },
    reverse: {
      icon: {
        hover: { x: 3, opacity: 1 },
        visible: { x: 0, opacity: 1 },
        hidden: { x: -3, opacity: 0 },
      },
      text: {
        hover: { x: 0, opacity: 1 },
        visible: { x: 0, opacity: 1 },
        hidden: { x: -3, opacity: 0 },
      },
    },
  },
  scale: {
    standard: {
      icon: {
        hover: { scale: 1 + scaleAdjustment, opacity: 1 },
        visible: { scale: 1, opacity: 1 },
        hidden: { scale: 1 - scaleAdjustment, opacity: 0 },
      },
      text: {
        hover: { x: 0, opacity: 1 },
        visible: { x: 0, opacity: 1 },
        hidden: { x: 1 - scaleAdjustment, opacity: 0 },
      },
    },
    reverse: {
      icon: {
        hover: { scale: 1 + scaleAdjustment, opacity: 1 },
        visible: { scale: 1, opacity: 1 },
        hidden: { scale: 1 - scaleAdjustment, opacity: 0 },
      },
      text: {
        hover: { x: 0, opacity: 1 },
        visible: { x: 0, opacity: 1 },
        hidden: { x: 1 - scaleAdjustment, opacity: 0 },
      },
    },
  },
};

export const TextButtonWithIcon: FC<{
  animationVariant?: keyof typeof animationVariants;
  iconName: IconName;
  isDisabled: boolean;
  isReverse?: boolean;
  onClick: () => any;
  showTextBreakpoint?: Breakpoint;
  text: string;
}> = ({
  iconName,
  text,
  onClick,
  isDisabled,
  isReverse,
  showTextBreakpoint,
  animationVariant = 'x',
}) => {
  const isVisible = !isDisabled;
  const animations =
    animationVariants[animationVariant][isReverse ? 'reverse' : 'standard'];

  return (
    <Button
      as={motion.button}
      type='button'
      variant='blank'
      onClick={onClick}
      whileHover='hover'
      disabled={!isVisible}
      animate={isVisible ? 'visible' : 'hidden'}
      initial='hidden'
      variants={{
        visible: {
          transition: {
            staggerChildren: time.md,
          },
        },
      }}
    >
      <HStack
        spacing='sm'
        alignItems='center'
        dir={isReverse ? 'row-reverse' : 'row'}
      >
        <motion.span variants={animations.icon}>
          <LineIcon size='sm' name={iconName} />
        </motion.span>
        <motion.span variants={animations.text}>
          <Text
            display={
              showTextBreakpoint
                ? { _: 'none', [showTextBreakpoint]: 'inline-block' }
                : undefined
            }
          >
            {text}
          </Text>
        </motion.span>
      </HStack>
    </Button>
  );
};
