import { css, styled } from '@ui-furniture/style';

const variants = {
  color: css`
    font-style: unset;
    font-weight: unset;
    color: palette-primary;
  `,
  style: css`
    font-style: italic;
  `,
};

export const Em = styled.emBox<{
  variant?: keyof typeof variants;
}>`
  ${({ variant = 'color' }) => variants[variant]}
`;
