import { FC } from 'react';

import { x } from '@ui-furniture/style';

import { IconName, LineIcon } from './line-icon';

export const SideBarIcon: FC<{
  iconName: IconName;
  isSelected: boolean;
  isDisabled?: boolean;
}> = ({ iconName, isSelected, isDisabled }) => (
  <x.div
    w='side-bar-tile'
    h='side-bar-tile'
    borderRadius='8px'
    p='8px'
    transition='base'
    color={
      isSelected ? 'palette-primary' : isDisabled ? 'gray-400' : 'palette-text'
    }
    backgroundColor={{
      _: isSelected ? 'overlay-weak' : 'transparent',
      hover: 'overlay-weak',
    }}
  >
    <LineIcon name={iconName} size='100%' />
  </x.div>
);
