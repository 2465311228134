import { useCallback } from 'react';

import { useRootModalContext } from './use-root-modal-context';

import { ModalControls } from '../types/controls';

export const useQueryParamModalControls = ({
  id,
  onClose,
  onOpen,
}: {
  id: string;
  onOpen?: () => void;
  onClose?: () => void;
}): ModalControls => {
  const { activeModals, setQueryParamModalOpen } = useRootModalContext();

  const open = useCallback(() => {
    const { didChange } = setQueryParamModalOpen(id, true);
    if (didChange) onOpen?.();
  }, [setQueryParamModalOpen]);

  const close = useCallback(() => {
    const { didChange } = setQueryParamModalOpen(id, false);
    if (didChange) onClose?.();
  }, [setQueryParamModalOpen]);

  const setOpen = useCallback(
    (is: boolean) => setQueryParamModalOpen(id, is),
    [setQueryParamModalOpen]
  );

  return {
    id,
    open,
    close,
    isOpen: activeModals.includes(id),
    setOpen,
  };
};
