import { FC } from 'react';

import { useAppStateContext } from '@ui-furniture/common/app-state/use-app-state-context';
import { HStack } from '@ui-furniture/elements';

import { CreateAccountBanner } from './create-account-banner';
import { UpgradeAccountBanner } from './upgrade-account-banner';

type Props = {
  withIcon?: boolean;
};

export const AccountVisibleBanner: FC<Props> = ({ withIcon }) => {
  const { visibleBannerType } = useAppStateContext();

  if (visibleBannerType === 'upgrade-account') {
    return (
      <HStack p={2}>
        <UpgradeAccountBanner withIcon={withIcon} />
      </HStack>
    );
  }

  if (visibleBannerType === 'create-account') {
    return (
      <HStack p={2}>
        <CreateAccountBanner withIcon={withIcon} />
      </HStack>
    );
  }

  return null;
};
