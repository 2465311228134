import { FC, PropsWithChildren } from 'react';

import { x } from '@ui-furniture/style';

import { InputBorder } from '../input-border';

export const FieldGroupContainer: FC<PropsWithChildren> = ({ children }) => (
  <InputBorder>
    <x.div display='flex' alignItems='stretch' w='100%' flexDirection='column'>
      {children}
    </x.div>
  </InputBorder>
);
