import { UICustomizationOptions } from 'onfido-sdk-ui';
import tc from 'tinycolor2';

import { Theme, useColor, useTheme } from '@ui-furniture/style';

export const useStyles = ({
  modalBg = 'palette-bg',
  modalBorder = 'palette-border',
} = {}): UICustomizationOptions => {
  const theme: Theme = useTheme();
  const modalBgColor =
    useColor(modalBg)?.toString() || theme.colors['palette-bg'];
  const modalBorderColor =
    useColor(modalBorder)?.toString() || theme.colors['palette-border'];

  const primaryColor = tc(theme.colors['palette-primary']);
  const primaryColorHover = primaryColor.clone().darken(10);
  const primaryColorActive = primaryColor.clone().darken(20);

  const secondaryColor = tc(theme.colors['palette-secondary']);
  const secondaryColorHover = secondaryColor.clone().darken(10);
  const secondaryColorActive = secondaryColor.clone().darken(20);

  return {
    // Modal
    borderRadiusSurfaceModal: theme.radii.lg,
    colorBackgroundSurfaceModal: modalBgColor,
    colorBorderSurfaceModal: modalBorderColor,

    // Fonts
    // Title
    fontFamilyTitle: theme.fonts.headings,
    fontSizeTitle: theme.fontSizes.title,
    colorContentTitle: theme.colors['palette-text'],

    // Subtitle
    fontFamilySubtitle: theme.fonts.headings,
    fontSizeSubtitle: theme.fontSizes.subTitle,
    colorContentSubtitle: theme.colors['palette-text'],

    // Body
    fontFamilyBody: theme.fonts.base,
    fontSizeBody: theme.fontSizes.body,
    colorContentBody: theme.colors['palette-text'],

    // Buttons
    // Base
    borderRadiusButton: theme.radii.base,

    // Primary
    colorContentButtonPrimaryText: theme.colors['palette-primary-text'],
    colorBorderButtonPrimary: theme.colors['palette-primary-border'],
    colorBackgroundButtonPrimary: primaryColor.toString(),
    colorBackgroundButtonPrimaryHover: primaryColorHover.toString(),
    colorBackgroundButtonPrimaryActive: primaryColorActive.toString(),

    // Secondary
    colorContentButtonSecondaryText: theme.colors['palette-secondary-text'],
    colorBorderButtonSecondary: theme.colors['palette-secondary-border'],
    colorBackgroundButtonSecondary: secondaryColor.toString(),
    colorBackgroundButtonSecondaryHover: secondaryColorHover.toString(),
    colorBackgroundButtonSecondaryActive: secondaryColorActive.toString(),

    // Document
    colorContentDocTypeButton: theme.colors['palette-text'],
    colorBackgroundDocTypeButton: theme.colors['brand-bright-white'],
    colorBorderDocTypeButton: theme.colors['palette-border'],
    colorBorderDocTypeButtonHover: primaryColor.toString(),
    colorBorderDocTypeButtonActive: primaryColorActive.toString(),

    // Camera
    colorBackgroundButtonCameraActive: primaryColorActive.toString(),
    colorBackgroundButtonCameraHover: primaryColor.toString(),

    // Link
    colorBorderLinkUnderline: primaryColor.toString(),
    colorContentLinkTextHover: theme['palette-text'],
    colorBackgroundLinkActive: primaryColorActive.toString(),
    colorBackgroundLinkHover: primaryColor.toString(),

    // Other
    // Pill
    colorBackgroundInfoPill: primaryColor.toString(),
    colorContentInfoPill: theme.colors['palette-primary-text'],

    // Icon
    colorBackgroundIcon: theme.colors['brand-beige'],

    // QR
    colorBackgroundQRCode: 'transparent',
  };
};
