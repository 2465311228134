import { breakpoints, styled, css } from '@ui-furniture/style';

const variants = {
  h1: css`
    font-weight: 700;
    font-size: 4xl;
    line-height: 4xl;
    ${breakpoints({
      phablet: css`
        font-size: 5xl;
        line-height: 5xl;
      `,
    })}
  `,
  h2: css`
    font-weight: 700;
    font-size: 3xl;
    line-height: 3xl;
    ${breakpoints({
      phablet: css`
        font-size: 4xl;
        line-height: 4xl;
      `,
    })}
  `,
  h3: css`
    font-weight: 700;
    font-size: 2xl;
    line-height: 2xl;
    ${breakpoints({
      phablet: css`
        font-size: 3xl;
        line-height: 3xl;
      `,
    })}
  `,
  title: css`
    font-weight: 600;
    font-size: xl;
    line-height: xl;
    ${breakpoints({
      phablet: css`
        font-size: 2xl;
        line-height: 2xl;
      `,
    })}
  `,
  subTitle: css`
    font-weight: 600;
    font-size: lg;
    line-height: lg;
    ${breakpoints({
      phablet: css`
        font-size: xl;
        line-height: xl;
      `,
    })}
  `,
  bodyBig: css`
    font-weight: 500;
    font-size: lg;
    line-height: lg;
  `,
  body: css`
    font-weight: 500;
    font-size: md;
    line-height: md;
  `,
  bodyHeavy: css`
    font-weight: 700;
    font-size: md;
    line-height: md;
  `,
  bodySmall: css`
    font-weight: 500;
    font-size: sm;
    line-height: sm;
  `,
  button: css`
    font-weight: 700;
    font-size: button;
    line-height: button;
  `,
  smallComment: css`
    font-weight: 500;
    font-size: sm;
    line-height: sm;
  `,
  labelSmall: css`
    font-weight: 700;
    font-size: sm;
    line-height: sm;
  `,
};

export const Text = styled.pBox<{
  variant?: keyof typeof variants;
}>`
  ${({ variant = 'body' }) => variants[variant]}
`;
