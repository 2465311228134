import { FC } from 'react';

import { useFormInput } from '@ui-furniture/lib/form';

import { ErrorMessageComponent } from './component';

export const ErrorMessage: FC = () => {
  const { error } = useFormInput();
  return <ErrorMessageComponent error={error} />;
};
