import { FC, PropsWithChildren } from 'react';

import { motion } from 'framer-motion';

import { FormInputProvider } from './form-input-context';
import { FormInputError } from './types/form-input-error';

export const FormInput: FC<
  PropsWithChildren<{
    error: FormInputError | null;
  }>
> = ({ children, error }) => (
  <FormInputProvider error={error}>
    <motion.div animate={error ? 'error' : 'normal'}>{children}</motion.div>
  </FormInputProvider>
);
