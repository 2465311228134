import { Button, Link } from '@ui-furniture/elements';
import { StyledComponent, x } from '@ui-furniture/style';

export type ClickableAction =
  | {
      type: 'link';
      href: string;
      onClick?: () => any;
    }
  | {
      type: 'button';
      onClick: () => any;
    };

export const Clickable: StyledComponent<{
  action: ClickableAction | null;
  variant?: 'blank' | 'simpleLink' | 'rounded' | 'color';
  disabled?: boolean;
}> = ({ action, children, variant = 'blank', disabled, ...rest }) => {
  if (action === null)
    return (
      <x.span textAlign='center' {...rest}>
        {children}
      </x.span>
    );
  switch (action.type) {
    case 'link':
      return (
        <Link
          {...rest}
          draggable={false}
          variant={variant}
          href={action.href}
          disabled={disabled}
          onClick={action.onClick}
        >
          {children}
        </Link>
      );
    case 'button':
      return (
        <Button
          variant={variant}
          type='button'
          {...rest}
          onClick={action.onClick}
          disabled={disabled}
        >
          {children}
        </Button>
      );
  }
  return null;
};
