import { FC } from 'react';

import { Text, VStack } from '@ui-furniture/elements';
import { Container } from '@ui-furniture/ui/layout/container';

import { useUpgradeAccount } from '../hooks/use-upgrade-account';
import { UpgradeAccountForm } from '../ui/upgrade-account-form';

export const UpgradeAccountView: FC = () => {
  const { upgradeAccount, isUpgradingAccount } = useUpgradeAccount();

  return (
    <Container variant='content'>
      <VStack textAlign='center' spacing='xl' maxW='xl'>
        <Text variant='h3' as='h1'>
          Upgrade to a full account
        </Text>
        <Text variant='body' maxW='md' mx='auto'>
          We’ve already verified your email address so if you would like to get
          full access to our services we need you to accept our terms and
          conditions
        </Text>
        <UpgradeAccountForm
          submit={upgradeAccount}
          isSubmitting={isUpgradingAccount}
        />
      </VStack>
    </Container>
  );
};
