import { Dayjs } from 'dayjs';
import { useController } from 'react-hook-form';

import { FormInput, FormInputComponent } from '@ui-furniture/lib/form';

import { StyledDatePickerField } from './styled-field';
import { DatePickerFieldProps } from './types/field-props';
import { useControlledOpen } from './use-controlled-open';

import { Label, LabelText, ErrorMessage } from '../common';

export const DatePicker: FormInputComponent<
  Omit<DatePickerFieldProps, 'value' | 'onChange'> & {
    isOptional?: boolean;
    informationTooltip?: JSX.Element;
    defaultDate?: Dayjs;
  }
> = ({
  name,
  registerOptions,
  label,
  inputFormat = 'DD/MM/YYYY',
  placeholder = inputFormat,
  isOptional = false,
  informationTooltip,
  defaultDate,
  ...rest
}) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    defaultValue: null,
    rules: registerOptions,
  });
  const openControls = useControlledOpen();

  return (
    <FormInput error={error?.message}>
      <Label>
        <LabelText
          isOptional={isOptional}
          informationTooltip={informationTooltip}
        >
          {label}
        </LabelText>
        <StyledDatePickerField
          inputFormat={inputFormat}
          placeholder={placeholder}
          defaultDate={defaultDate}
          {...rest}
          {...openControls}
          {...field}
        />
        <ErrorMessage />
      </Label>
    </FormInput>
  );
};
