import { style } from '@xstyled/styled-components';

export interface FlexCenteredContentProps {
  flexCenteredContent?: boolean;
}

export const flexCenteredContent = style({
  prop: 'flexCenteredContent',
  css: () => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
});
