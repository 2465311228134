import { useMemo } from 'react';

import { createHandleErrorLink } from '@ui-furniture/common/gql-links/create-handle-error-link';
import { errorService } from '@ui-furniture/common/services/error';

export const useServerErrorLink = () => {
  const onServerError = useMemo(
    () =>
      createHandleErrorLink('INTERNAL_SERVER_ERROR', (errors) => {
        errors.forEach((error) => {
          errorService.captureMessage(error.message, {
            level: 'error',
            extra: {
              error,
            },
          });
        });
      }),
    []
  );

  return onServerError;
};
