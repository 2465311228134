import useRouterState from './useRouterState';

export const useQueryParam = (key: string) => {
  const [queryParamText, setQueryParams] = useRouterState(key);
  const queryParamValues = queryParamText?.split(',') || [];

  const add = async (value: string) => {
    if (!queryParamValues.includes(value)) {
      await setQueryParams([...queryParamValues, value].join(','));
      return { didChange: true };
    }
    return { didChange: false };
  };

  const remove = async (value: string) => {
    if (queryParamValues.includes(value)) {
      await setQueryParams(
        queryParamValues.filter((param) => param !== value).join(',')
      );
      return { didChange: true };
    }
    return { didChange: false };
  };

  const set = async (value?: string) => {
    if (queryParamValues.length !== 1 && queryParamValues[0] !== value) {
      await setQueryParams(value);
      return { didChange: true };
    }
    return { didChange: false };
  };

  return {
    queryParamValues,
    add,
    remove,
    set,
  };
};
