import { css, styled } from '@ui-furniture/style';

const variants = {
  semantic: css`
    font-weight: unset;
  `,
  bold: css`
    font-weight: 600;
  `,
  color: css`
    color: palette-primary;
  `,
};

export const Strong = styled.strongBox<{
  variant?: keyof typeof variants;
}>`
  ${({ variant = 'color' }) => variants[variant]}
`;
