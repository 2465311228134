import { createGlobalStyle } from '@ui-furniture/style';

export default createGlobalStyle`
  html {
    color: text;
    font-size: 16px;
    overflow: hidden;
    width: 100vw;
  }
  body {
    color: text;
    background-color: background;
    overflow: auto;
    position: relative;
    height: 100vh;
    height: --webkit-fill-available;
    height: 100dvh;
    width: 100vw;
    font-family: base !important;
    font-size: base;
  }
  
  // Override user agent
  input, button, textarea, select, optgroup {
    font-family: unset;
    font-weight: unset;
    /** input size needs to be 16px otherwise ios zooms on focus */
    font-size: input;
    line-height: unset;
    color: unset;
  }

  
  /* Remove focus outline */
  :root {
    --webkit-focus-ring-color: transparent !important;
  }
  button, link {
    :focus {
      outline: none;
    }
  }

  /* Text Selection Effect */
  ::selection {
    background-color: primary; /* WebKit/Blink Browsers */
    color: text;
  }
  ::-moz-selection {
    background-color: primary; /* Gecko Browsers */
    color: text;
  }

  .pointer-events-none {
    pointer-events: none !important;
    * {
      pointer-events: none !important;
    }
  }
`;
