export default function createScreens<TScreenNames extends string>(
  screens: Record<TScreenNames | 'sm' | 'md' | 'lg' | 'xl' | '2xl', number>
) {
  return {
    ...screens,
    phone: 320,
    largePhone: 520,
    phablet: screens.sm,
    tablet: screens.md,
    largeTablet: 920,
    laptop: screens.lg,
    desktop: screens.xl,
    largeDesktop: screens['2xl'],
  } as const;
}
