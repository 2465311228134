import { FC } from 'react';

import { CallbackNames } from '@ui-furniture/common/flow-manager/types';

import { StepAnimationContainer } from './step-animation-container';

import { stepContext } from '../context/step-context';
import { useStepControls } from '../hooks/use-step-controls';
import { useStepHooks } from '../hooks/use-step-hooks';
import { StepConfig } from '../types/step-config';
import { StepStatus } from '../types/step-status';

export const Step: FC<{
  status: StepStatus;
  data: StepConfig;
  flowName?: CallbackNames;
  increment: (pos: number) => void;
  decrement: () => void;
  onComplete?: () => void;
}> = ({ status, data, flowName, increment, decrement, onComplete }) => {
  const { pos, activePos } = status;
  const { id, Screen } = data;
  const isActive = pos === activePos;

  const { prev, next, setPosition } = useStepControls({
    status,
    flowName,
    increment,
    decrement,
    onComplete,
  });
  useStepHooks({
    isActive,
    hooks: data,
  });

  return (
    <stepContext.Provider
      value={{ id, pos, isActive, next, prev, setPosition }}
    >
      <StepAnimationContainer
        id={`screen-${pos + 1}`}
        role='tabpanel'
        aria-labelledby={`step-${pos + 1}`}
        tabIndex={0}
        hidden={!isActive}
      >
        <Screen />
      </StepAnimationContainer>
    </stepContext.Provider>
  );
};
