import { useRouter } from 'next/router';

const parseMap = {
  string: (value: string) => value,
  int: (value: string) => parseInt(value, 10),
  boolean: (value: string) => value === 'true',
};
type ParseType = keyof typeof parseMap;
type ParseMapReturnType<TKey extends ParseType> = ReturnType<
  (typeof parseMap)[TKey]
>;

export const useParamOnMount = <
  TKey extends string,
  TParseType extends ParseType
>(
  key: TKey,
  type: TParseType
): ParseMapReturnType<TParseType> => {
  const { query, isReady } = useRouter();
  if (!isReady)
    throw new Error('Please wrap this component in withWaitForReady hoc');

  const value = query[key];
  const parse = parseMap[type];
  const parsedValue = parse(value as string);
  return parsedValue as ParseMapReturnType<TParseType>;
};
