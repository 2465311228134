import { useRouter } from 'next/router';

export const useSelectedRoute = () => {
  const route = useRouter();

  if (route.pathname === '/') {
    return 'home';
  }
  if (route.pathname.startsWith('/properties')) {
    return 'properties';
  }
  if (route.pathname.startsWith('/launch')) {
    return 'launch';
  }
  if (route.pathname.startsWith('/refer')) {
    return 'refer';
  }

  return null;
};
