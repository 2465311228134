import { ReactNode } from 'react';

import { HStack } from '@ui-furniture/elements';
import { StyledComponent, x } from '@ui-furniture/style';
import { LineIcon } from '@ui-furniture/ui/line-icon';
import { Tooltip } from '@ui-furniture/ui/tooltip';

export const LabelText: StyledComponent<{
  isOptional: boolean;
  informationTooltip?: ReactNode;
}> = ({ children, isOptional, informationTooltip, ...rest }) => (
  <x.div
    display='flex'
    flexDirection='row'
    justifyContent='space-between'
    w='100%'
    fontSize='md'
    {...rest}
  >
    <x.span fontWeight='600'>{children}</x.span>
    <HStack as='span'>
      {isOptional ? (
        <x.span fontWeight='400' fontStyle='italic' opacity={0.6}>
          optional
        </x.span>
      ) : null}
      {informationTooltip ? (
        <x.span display='flex' alignItems='center' maxH='16px'>
          <Tooltip tooltipContent={informationTooltip} placement='left'>
            <LineIcon
              name='questionMarkCircle'
              size='sm'
              color='palette-text'
            />
          </Tooltip>
        </x.span>
      ) : null}
    </HStack>
  </x.div>
);
