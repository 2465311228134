import { style } from '@xstyled/styled-components';

export interface AllowWordBreakProps {
  allowWordBreak?: boolean;
}

export const allowWordBreak = style({
  prop: 'allowWordBreak',
  css: () => ({
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    wordBreak: 'break-word',
  }),
});
