import { utils } from '@ui-furniture/lib/Palette';

import { brandColors } from '../brand-colors';

export default {
  text: brandColors['brand-text'],
  lightText: brandColors['brand-white'],
  darkText: brandColors['brand-text'],
  background: brandColors['brand-white'],
  primary: brandColors['brand-orange'],
  overlay: '#414f65c2',
  'border-blue': 'rgb(65,76,101,0.2)',
  'overlay-opaque': '#414f65e8',
  'overlay-weak': '#414f650a',
  'overlay-text': brandColors['brand-white'],
  placeholder: '#414f6580',
  ...brandColors,
  ...utils.mapPaletteToThemeVariables({
    bg: brandColors['brand-white'],
    text: brandColors['brand-text'],
    accent: brandColors['brand-orange'],
    subtle: brandColors['brand-beige-light'],
    border: brandColors['brand-beige'],
    primary: brandColors['brand-orange'],
    primaryText: brandColors['brand-white'],
    primaryBorder: '#CB5029',
    secondary: brandColors['brand-white'],
    secondaryText: brandColors['brand-text'],
    secondaryBorder: brandColors['brand-beige'],
  }),
};
