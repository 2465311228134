import { useCallback } from 'react';

import { useSubmitUserIdentityChecksMutation } from '@graphql/requests';

import { useAnalyticsReportingService } from '@ui-furniture/common/analytics';

export const useSubmitKycUserIdentityChecks = () => {
  const [completeKyc] = useSubmitUserIdentityChecksMutation({
    refetchQueries: ['GetKycUserIdentityCheckStatus'],
  });
  const { setUserPropertyAction } = useAnalyticsReportingService();

  return useCallback(async () => {
    await completeKyc();

    setUserPropertyAction({
      type: 'SET_ONCE',
      key: 'Kyc Submitted',
      value: true,
    });
  }, []);
};
