import { useEffect } from 'react';

import { StepHooks } from '../types/step-hooks';

export const useStepHooks = ({
  hooks: { onActivated, onDeactivated },
  isActive,
}: {
  hooks: StepHooks;
  isActive: boolean;
}) => {
  useEffect(() => {
    if (isActive) {
      onActivated?.();
    } else {
      onDeactivated?.();
    }
  }, [isActive]);
};
