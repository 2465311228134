import { FC } from 'react';

import { z } from 'zod';

import {
  BasicForm,
  Input,
  SubmitFormHandler,
  useForm,
} from '@ui-furniture/common/form';
import { useFullResetOnSuccess } from '@ui-furniture/lib/form';
import { useFocusByIdOnMount } from '@ui-furniture/lib/hooks/use-focus-by-id-on-mount';
import { LoadingButton } from '@ui-furniture/ui/loading/button';

const signInSchema = z.object({
  email: z
    .string()
    .email({ message: `This doesn't look like a valid email address` }),
});

export type SignInFormHandler = SubmitFormHandler<typeof signInSchema>;

export const SignInForm: FC<{
  submit: SignInFormHandler;
  isSubmitting: boolean;
}> = ({ submit, isSubmitting }) => {
  const formHandler = useForm(signInSchema);
  useFullResetOnSuccess(formHandler);
  const id = useFocusByIdOnMount();

  return (
    <BasicForm schema={signInSchema} onSubmit={submit}>
      <Input
        id={id}
        name='email'
        type='email'
        label='Email'
        autoComplete='email'
        placeholder='What is your email?'
      />
      <LoadingButton type='submit' isLoading={isSubmitting}>
        Sign In
      </LoadingButton>
    </BasicForm>
  );
};
