import { FC } from 'react';

import { z } from 'zod';

import {
  Form,
  Input,
  SubmitFormHandler,
  useForm,
} from '@ui-furniture/common/form';
import { VStack, Text, Em, HStack } from '@ui-furniture/elements';
import { LoadingButton } from '@ui-furniture/ui/loading/button';

const CodeVerificationSchema = z.object({
  code: z.coerce.string().length(6, 'Code must be 6 digits'),
});

export type CodeVerificationFormHandler = SubmitFormHandler<
  typeof CodeVerificationSchema
>;

export const CodeVerificationForm: FC<{
  submit: CodeVerificationFormHandler;
  isSubmitting: boolean;
}> = ({ submit, isSubmitting }) => {
  const formHandler = useForm(CodeVerificationSchema, {});
  const { handleSubmit } = formHandler;

  return (
    <Form form={formHandler} onSubmit={handleSubmit(submit)}>
      <VStack>
        <Text>
          You should have received an email with a 6 digit code. Please enter
          the code below to continue
        </Text>
        <Input
          name='code'
          type='number'
          label='Your Security Code'
          placeholder='123456'
        />
        <Text>
          (If you don’t see the email in your inbox{' '}
          <Em>please check your spam folder</Em>)
        </Text>
        <HStack justifyContent='center' mt='xl'>
          <LoadingButton
            type='submit'
            isLoading={isSubmitting}
            variant='rounded'
            version='primary'
          >
            Verify
          </LoadingButton>
        </HStack>
      </VStack>
    </Form>
  );
};
