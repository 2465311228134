/*eslint-disable*/
import { useRouter } from 'next/router';

import { useAnalyticsReportingService } from '@ui-furniture/common/analytics';
import { Callback, CallbackNames } from '@ui-furniture/common/flow-manager/types';
import { useFlowManagerContext } from '@ui-furniture/common/flow-manager/use-flow-manager-context';
import { errorService } from '@ui-furniture/common/services/error';
import { useAppStateContext } from '@ui-furniture/common/app-state/use-app-state-context';

export const useFlowControls = () => {
  const { registerCallback, resetStorage, callback } = useFlowManagerContext();
  const { reportEvent } = useAnalyticsReportingService();
  const { push } = useRouter();
  const { setUserCreatedNewData } = useAppStateContext();

  const register = (
    callbackName: CallbackNames,
    options?: Partial<Callback>
  ) => {
    const cb = registerCallback(callbackName, options);
    if (cb?.registerTracking) {
      cb.registerTracking.forEach((event) => {
        reportEvent(event);
      });
    }
  };

  const moveToStep = (step: number, callbackName?: CallbackNames) => {
    if (!callbackName) return;
    if (callback && callback.name !== callbackName) {
      errorService.captureException(
        new Error(
          `Callback name ${callbackName} does not match the current callback name ${callback.name}`
        )
      );
      return;
    }
    reportEvent({
      name: 'Flow Step Change',
      toStep: step,
      flowName: callbackName,
    });
    if (callback?.nextStepTracking?.[step]) {
      callback.nextStepTracking[step].forEach((event) => {
        reportEvent(event);
      });
    }
  };

  const resetFlow = (fallbackUrl?: string) => {
    resetStorage();
    if (fallbackUrl) push(fallbackUrl);
  };

  const completeFlowSuccessfully = ({
    callbackName,
    conversion,
    close,
    fallbackUrl,
  }: {
    callbackName: CallbackNames;
    conversion: boolean;
      close?: () => void;
    fallbackUrl?: string;
  }) => {
    try {
      if (callback && callback.name !== callbackName) {
        errorService.captureException(
          new Error(
            `Callback name ${callbackName} does not match the current callback name ${callback.name}`
          )
        );
        return;
      }
      if (callback?.successTracking) {
        callback.successTracking.forEach((event) => {
          reportEvent({ ...event, conversion });
        });
      }

      if (close) close();
      else if (callback?.successRedirect) push(callback.successRedirect);
      else if (fallbackUrl) push(fallbackUrl);
    } catch (err) {
      errorService.captureException(err);
    } finally {
      setUserCreatedNewData();
      resetFlow();
    }
  };

  const closeFlow = (callbackName: CallbackNames, fallbackUrl?: string) => {
    try {
      if (callback && callback.name !== callbackName) {
        errorService.captureException(
          new Error(
            `Callback name ${callbackName} does not match the current callback name ${callback.name}`
          )
        );
        return;
      }
      if (callback?.closeTracking) {
        callback.closeTracking.forEach((event) => {
          reportEvent(event);
        });
      }
      if (callback?.closeRedirect) push(callback.closeRedirect);
      else if (fallbackUrl) push(fallbackUrl);
    } catch (err) {
      errorService.captureException(err);
    } finally {
      resetFlow();
    }
  };

  return {
    register,
    completeFlowSuccessfully,
    closeFlow,
    resetFlow,
    moveToStep,
  };
};
