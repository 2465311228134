import { ApolloClient, ApolloLink, from, InMemoryCache } from '@apollo/client';

import { ItemUnion } from '@graphql/requests';

import { apiHttpLink } from '@ui-furniture/common/gql-links/api-http-link';
import { isExhaustiveArray } from '@ui-furniture/lib/utils/is-exhaustive-array';

export const createApiGqlClient = ({
  links = [],
}: {
  links?: Array<ApolloLink>;
} = {}) =>
  new ApolloClient({
    link: from([...links, apiHttpLink]),
    cache: new InMemoryCache({
      typePolicies: {
        Purchase: {
          keyFields: ['collectionKey'],
        },
        ConveyancingSaleDetails: {
          keyFields: ['requestId'],
        },
        ConveyancingPuchaseDetails: {
          keyFields: ['requestId'],
        },
        ConveyancingEstimate: {
          keyFields: ['recommendationId', 'conveyancerId'],
        },
      },
      possibleTypes: {
        ItemUnion: isExhaustiveArray<
          Exclude<ItemUnion['__typename'], undefined>
        >()([
          'PersonalProtectPolicies',
          'ProtectInvitations',
          'ConveyancingRecommendations',
        ]),
        BusinessUnion: ['ConveyancingBusiness'],
      },
    }),
  });
