import { FC } from 'react';

import { x } from '@xstyled/styled-components';
import tc from 'tinycolor2';

export const ColorSwatch: FC<{
  color: string;
  name: string;
}> = ({ color: colorText, name }) => {
  const color = tc(colorText);
  const textColor = color.isLight() ? 'darkText' : 'lightText';
  return (
    <x.div
      bg={colorText}
      w='100%'
      maxW={300}
      style={{ aspectRatio: '1 / 1' }}
      display='flex'
      alignItems='flex-end'
      justifyContent='flex-end'
      p='md'
      m='md'
    >
      <x.div textAlign='right' color={textColor}>
        <x.div mb='sm'>{color.toString('rgb')}</x.div>
        <x.div mb='sm'>{color.toString('hex')}</x.div>
        <x.div>
          <x.strong>{name}</x.strong>
        </x.div>
      </x.div>
    </x.div>
  );
};
