import { ComponentProps, FC, useCallback } from 'react';

import { components as DefaultComponents } from 'react-select';
import ReactSelectAsync from 'react-select/async';

import { overrideComponents } from './override-components';
import { OverrideStyleConfig, useOverrideStyles } from './use-override-styles';

type ReactSelectAsyncProps = ComponentProps<typeof ReactSelectAsync>;

export const Typeahead: FC<
  Omit<ReactSelectAsyncProps, 'styles'> & {
    inputValue: string;
    setInputValue: (value: string, options?: any) => void;
    minCharLengthBeforeSuggestions?: number;
    autoComplete?: string;
    styles?: OverrideStyleConfig;
  }
> = ({
  inputValue,
  setInputValue,
  minCharLengthBeforeSuggestions = 2,
  components,
  onInputChange,
  autoComplete,
  styles,
  className,
  ...rest
}) => {
  const allowMenuOpen =
    inputValue && inputValue.length > minCharLengthBeforeSuggestions;
  const menuIsOpen = allowMenuOpen ? undefined : false;
  const Input = useCallback(
    (props) => (
      <DefaultComponents.Input
        autoComplete={autoComplete}
        {...props}
        className={className ? `read ${className}` : 'read'}
        isHidden={false}
      />
    ),
    [autoComplete]
  );
  const reactSelectStyles = useOverrideStyles(styles);

  return (
    <ReactSelectAsync
      styles={reactSelectStyles}
      {...rest}
      components={{
        ...overrideComponents,
        ...components,
        Input,
        DropdownIndicator: null,
      }}
      menuIsOpen={menuIsOpen}
      value={inputValue}
      inputValue={inputValue}
      openMenuOnClick={false}
      defaultOptions
      onInputChange={(value, meta) => {
        onInputChange?.(value, meta);
        if (meta.action === 'input-change') {
          setInputValue(value);
        }
      }}
      controlShouldRenderValue={false}
    />
  );
};
