import { style } from '@xstyled/styled-components';

export interface FullScreenProps {
  fullScreen?: boolean;
}

export const fullScreen = style({
  prop: 'fullScreen',
  css: () => ({
    display: 'flex',
    minH: 'screen-height',
    width: '100vw',
  }),
});
