import { PropsWithChildren, useCallback, useMemo } from 'react';

import { useSessionStorage } from 'usehooks-ts';

import { AuthStatus } from '@graphql/requests';

import { useAuthStatus } from '@ui-furniture/hooks/use-auth-status';

import { appStateContext } from './context';
import { BannerType } from './types';

export const AppStateProvider = ({ children }: PropsWithChildren) => {
  const [userCreatedNewData, setUserCreatedNewDataSessionStorage] =
    useSessionStorage('user-created-new-data', false);
  const authStatus = useAuthStatus();
  const visibleBannerType = useMemo<BannerType | null>(() => {
    if (authStatus === AuthStatus.IdentifiedVisitor) return 'upgrade-account';
    if (authStatus === AuthStatus.AnonymousVisitor && userCreatedNewData)
      return 'create-account';
    return null;
  }, [userCreatedNewData, authStatus]);

  const setUserCreatedNewData = useCallback(
    () => setUserCreatedNewDataSessionStorage(true),
    []
  );

  return (
    <appStateContext.Provider
      value={{
        visibleBannerType,
        setUserCreatedNewData,
      }}
    >
      {children}
    </appStateContext.Provider>
  );
};
