import { FC, PropsWithChildren } from 'react';

export function withProviders<TProps>(
  Providers: FC<PropsWithChildren>,
  Component: FC<TProps>
): FC<TProps> {
  return (props: TProps) => (
    <Providers>
      <Component {...(props as any)} />
    </Providers>
  );
}
