import { useCallback } from 'react';

import { useOnKeyUp } from '@ui-furniture/lib/hooks/use-on-key-up';
import { KeyIdentifier } from '@ui-furniture/lib/utils/key-indentifier';

import { ModalControls } from '../types/controls';

export const useCloseOnEscape = (
  controls: ModalControls,
  {
    isDisabled = false,
  }: {
    isDisabled?: boolean;
  } = {}
) => {
  const closeOnEscape = useCallback(
    (event) => {
      const key = KeyIdentifier.identifyFromEvent(event);
      if (key.isEsc) {
        if (controls.isOpen) {
          if (!isDisabled) controls.close();
        }
      }
    },
    [controls, isDisabled]
  );

  useOnKeyUp(closeOnEscape);
};
