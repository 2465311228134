import { x, styled, StyledComponent, StyledProps } from '@ui-furniture/style';
import { IconName, LineIcon } from '@ui-furniture/ui/line-icon';

import { roundedButtonCss } from '../css/rounded-button-css';

// import { variants } from './variants';

const RoundedButton = styled.buttonBox`
  ${roundedButtonCss}
`;

const variants = {
  plain: {
    p: 0,
  },
  rounded: {
    as: RoundedButton,
  },
} as const satisfies Record<string, StyledProps & { as?: any }>;

export const IconButton: StyledComponent<{
  iconName: IconName;
  onClick: () => any;
  size?: number;
  variant?: keyof typeof variants;
}> = ({ size = 46, variant = 'plain', iconName, ...rest }) => {
  return (
    <x.button
      type='button'
      backgroundColor='transparent'
      color={{ _: 'inherit', hover: 'palette-primary' }}
      textAlign='center'
      pointerEvents={{ disabled: 'none' }}
      w={`${size}px`}
      h={`${size}px`}
      borderRadius={`${size / 2}px`}
      p='sm'
      transition='base'
      {...variants[variant]}
      {...rest}
    >
      <LineIcon name={iconName} size='100%' />
    </x.button>
  );
};
