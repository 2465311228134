import axios, { AxiosInstance } from 'axios';

import config from '@ui-furniture/config';

class ApiRestClient {
  private readonly _client: AxiosInstance;
  constructor(domain: string) {
    this._client = axios.create({
      baseURL: domain,
      withCredentials: true,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
    });
  }

  logout = () => this._client.post(`/auth/logout`);
  authenticateVisitor = () => this._client.post(`/auth/authenticate-visitor`);
  download = (url: string) => this._client.get(url, { responseType: 'blob' });
  useToken = (params: { token: string; code: string }) =>
    this._client.get(`/token/use?token=${params.token}&code=${params.code}`);
  sendSlackNotification = (templateName: string, data: any) =>
    this._client.post(`/notifications/slack`, { templateName, data });
  setProfile = (partnerId: string | null) =>
    this._client.post(`/auth/set-profile`, { partnerId });
}

export const apiRestClient = new ApiRestClient(config.backendDomain);
