import * as Sentry from '@sentry/nextjs';

import config from '@ui-furniture/config';

import { logger } from './logger';

class ErrorService {
  private readonly _doLogErrors: boolean;

  constructor({ doLogErrors = false }: { doLogErrors?: boolean } = {}) {
    this._doLogErrors = doLogErrors;
  }

  private _logError = (message: string, data: any) => {
    // eslint-disable-next-line no-console
    if (this._doLogErrors) logger.error(message, data);
  };

  flush: (typeof Sentry)['flush'] = (ms) => Sentry.flush(ms);

  captureException: (typeof Sentry)['captureException'] = (
    exception,
    context
  ) => {
    const result = Sentry.captureException(exception, context);
    this._logError(exception.message, {
      exception,
      context,
    });
    return result;
  };

  captureMessage: (typeof Sentry)['captureMessage'] = (message, context) => {
    const result = Sentry.captureMessage(message, context);
    this._logError(message, {
      context,
    });
    return result;
  };

  captureEvent: (typeof Sentry)['captureEvent'] = (event) => {
    const result = Sentry.captureEvent(event);
    this._logError(event.message || '', event);
    return result;
  };
}

export const errorService = new ErrorService({
  doLogErrors: config.logErrors,
});
