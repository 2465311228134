import { PATHS } from '@ui-furniture/constants/paths';

import { AnalyticsEvent } from '../analytics/types/analytics-event';

export type CallbackNames =
  | 'create-account'
  | 'create-purchase'
  | 'create-move'
  | 'create-sale'
  | 'create-offer'
  | 'book-mip-appointment'
  | 'accept-offer'
  | 'decline-offer'
  | 'kyc'
  | 'leasehold-review'
  | 'protect'
  | 'find-conveyancer';

export type Callback = {
  name: CallbackNames;
  successRedirect?: string;
  closeRedirect?: string;
  registerTracking?: AnalyticsEvent[];
  successTracking?: AnalyticsEvent[];
  closeTracking?: AnalyticsEvent[];
  failTracking?: AnalyticsEvent[];
  nextStepTracking?: {
    [step: number]: AnalyticsEvent[];
  };
};

export const callbacks: Callback[] = [
  {
    name: 'create-account',
    registerTracking: [
      {
        name: 'Create Account Started',
      },
    ],
    successTracking: [
      {
        name: 'Create Account Completed',
      },
    ],
    closeTracking: [
      {
        name: 'Create Account Closed',
      },
    ],
  },
  {
    name: 'create-purchase',
    closeRedirect: PATHS.webApp.dashboard,
    registerTracking: [
      {
        name: 'Purchase Started',
        isPurchaseStarted: 1,
      },
    ],
    successTracking: [
      {
        name: 'Purchase Completed',
        isPurchaseCompleted: 1,
      },
      {
        name: 'Purchase Chatbot Completed',
      },
    ],
    closeTracking: [
      {
        name: 'Purchase Closed',
      },
    ],
    failTracking: [
      {
        name: 'Purchase Failed',
      },
    ],
    nextStepTracking: {
      1: [
        {
          name: 'Purchase Chatbot Started',
        },
      ],
    },
  },
  {
    name: 'create-move',
    closeRedirect: PATHS.webApp.dashboard,
    registerTracking: [
      {
        name: 'Move Started',
        isMoveStarted: 1,
      },
    ],
    successTracking: [
      {
        name: 'Move Completed',
        isMoveCompleted: 1,
      },
      {
        name: 'Move Chatbot Completed',
      },
    ],
    closeTracking: [
      {
        name: 'Move Closed',
      },
    ],
    failTracking: [
      {
        name: 'Move Failed',
      },
    ],
    nextStepTracking: {
      1: [
        {
          name: 'Move Chatbot Started',
        },
      ],
    },
  },
  {
    name: 'create-sale',
    closeRedirect: PATHS.webApp.dashboard,
    registerTracking: [
      {
        name: 'Sale Started',
        isSaleStarted: 1,
      },
    ],
    successTracking: [
      {
        name: 'Sale Completed',
        isSaledCompleted: 1,
      },
      {
        name: 'Sale Chatbot Completed',
      },
    ],
    closeTracking: [
      {
        name: 'Sale Closed',
      },
    ],
    failTracking: [
      {
        name: 'Sale Failed',
      },
    ],
    nextStepTracking: {
      1: [
        {
          name: 'Sale Chatbot Started',
        },
      ],
    },
  },
  {
    name: 'create-offer',
    registerTracking: [
      {
        name: 'Offer Started',
      },
    ],
    successTracking: [
      {
        name: 'Offer Completed',
      },
    ],
    closeTracking: [
      {
        name: 'Offer Closed',
      },
    ],
    failTracking: [
      {
        name: 'Offer Failed',
      },
    ],
  },
  {
    name: 'book-mip-appointment',
    registerTracking: [
      {
        name: 'MIP Started',
      },
    ],
    successTracking: [
      {
        name: 'MIP Completed',
      },
    ],
    closeTracking: [
      {
        name: 'MIP Closed',
      },
    ],
    failTracking: [
      {
        name: 'MIP Failed',
      },
    ],
  },
  {
    name: 'accept-offer',
    successRedirect: PATHS.webApp.dashboard,
    registerTracking: [
      {
        name: 'Offer Accepted Started',
      },
    ],
    successTracking: [
      {
        name: 'Offer Accepted Completed',
      },
    ],
    closeTracking: [
      {
        name: 'Offer Accepted Closed',
      },
    ],
    failTracking: [
      {
        name: 'Offer Accepted Failed',
      },
    ],
  },
  {
    name: 'decline-offer',
    successRedirect: PATHS.webApp.dashboard,
    registerTracking: [{ name: 'Offer Declined Started' }],
    successTracking: [{ name: 'Offer Declined Completed' }],
    closeTracking: [{ name: 'Offer Declined Closed' }],
    failTracking: [{ name: 'Offer Declined Failed' }],
  },
  {
    name: 'kyc',
    registerTracking: [{ name: 'KYC Started' }],
    successTracking: [{ name: 'KYC Completed' }],
    closeTracking: [{ name: 'KYC Closed' }],
    failTracking: [{ name: 'KYC Failed' }],
  },
  {
    name: 'leasehold-review',
    registerTracking: [{ name: 'AI Leasehold Review Started' }],
    successTracking: [{ name: 'AI Leasehold Review Completed' }],
    closeTracking: [{ name: 'AI Leasehold Review Closed' }],
    failTracking: [{ name: 'AI Leasehold Review Failed' }],
  },
  {
    name: 'protect',
    successRedirect: PATHS.webApp.dashboard,
    registerTracking: [{ name: 'Create Protect Started' }],
    successTracking: [{ name: 'Create Protect Completed' }],
    closeTracking: [{ name: 'Create Protect Closed' }],
    failTracking: [{ name: 'Create Protect Failed' }],
  },
];
