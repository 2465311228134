import { FC, PropsWithChildren } from 'react';

import { HStack } from '@ui-furniture/elements';

const alignment = {
  center: 'center',
  end: 'flex-end',
};

export const ButtonBarFooter: FC<
  PropsWithChildren<{
    mt?: string;
    align?: keyof typeof alignment;
  }>
> = ({ mt = 'lg', align = 'end', children }) => (
  <HStack mt={mt} justifyContent={alignment[align]} alignItems='center'>
    {children}
  </HStack>
);
