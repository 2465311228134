import { useState } from 'react';

export const useControlledOpen = ({
  onBlur = () => undefined,
  onClose = () => undefined,
  onCalendarClick = () => undefined,
}: {
  open?: boolean;
  onBlur?: () => void;
  onClose?: () => void;
  onCalendarClick?: () => void;
} = {}) => {
  const [isOpen, setIsOpen] = useState(false);

  return {
    open: isOpen,
    setOpen: setIsOpen,
    onClose: () => {
      setIsOpen(false);
      onClose();
    },
    onCalendarClick: () => {
      setIsOpen(true);
      onCalendarClick();
    },
    onBlur: () => {
      onBlur();
    },
  };
};
