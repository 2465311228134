import { useCallback, useId } from 'react';

import { useRootModalContext } from './use-root-modal-context';

import { ModalControls } from '../types/controls';

export const useStateModalControls = ({
  onClose,
  onOpen,
}: {
  onOpen?: () => void;
  onClose?: () => void;
} = {}): ModalControls => {
  const id = useId();
  const { activeModals, setStateModalOpen } = useRootModalContext();

  const open = useCallback(() => {
    const { didChange } = setStateModalOpen(id, true);
    if (didChange) onOpen?.();
  }, [setStateModalOpen]);

  const close = useCallback(() => {
    const { didChange } = setStateModalOpen(id, false);
    if (didChange) onClose?.();
  }, [setStateModalOpen]);

  const setOpen = useCallback(
    (is: boolean) => setStateModalOpen(id, is),
    [setStateModalOpen]
  );

  return {
    id,
    open,
    close,
    isOpen: activeModals.includes(id),
    setOpen,
  };
};
