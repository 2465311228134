import { ReplaySubject, BehaviorSubject, filter, map } from 'rxjs';

import {
  AnalyticsEvent,
  ObjectiveEvent,
  Objectives,
} from './types/analytics-event';
import { AnalyticsReportingService } from './types/analytics-reporting-service';
import { AnalyticsSubscribeService } from './types/analytics-subscribe-service';
import { AnalyticsSubscriber } from './types/analytics-subscriber';
import { SessionIdData } from './types/session-id-data';
import { UserPropertyAction } from './types/user-property-action';

export class AnalyticsService
  implements AnalyticsSubscribeService, AnalyticsReportingService
{
  private readonly _providerSubject = new ReplaySubject<{
    providerName: string;
    name: string;
    type: string;
    data: any;
  }>();
  readonly eventSubject = new ReplaySubject<AnalyticsEvent>();
  readonly objectiveSubject = new ReplaySubject<ObjectiveEvent<Objectives>>();
  readonly userIdSubject = new BehaviorSubject<string | null>(null);
  readonly userPropertyActionsSubject = new ReplaySubject<UserPropertyAction>();
  readonly resetSubject = new BehaviorSubject<boolean>(false);
  readonly sessionIdSubject = new ReplaySubject<SessionIdData>();

  subscribe = (subscriptions: Array<AnalyticsSubscriber>) => {
    subscriptions.forEach((subscribe) => subscribe(this));
  };

  reportEvent: AnalyticsReportingService['reportEvent'] = (event) => {
    this.eventSubject.next(event);
  };

  reportObjective: AnalyticsReportingService['reportObjective'] = (event) => {
    this.objectiveSubject.next(event as any);
  };

  setUserId: AnalyticsReportingService['setUserId'] = (userId: string) => {
    this.userIdSubject.next(userId);
  };

  setUserPropertyAction: AnalyticsReportingService['setUserPropertyAction'] = (
    action: UserPropertyAction
  ) => {
    this.userPropertyActionsSubject.next(action);
  };

  reset: AnalyticsReportingService['reset'] = () => {
    this.resetSubject.next(true);
  };

  sendTo: AnalyticsReportingService['sendTo'] = (providerName, event) => {
    this._providerSubject.next({ providerName, ...event });
  };

  createProviderObservable: AnalyticsSubscribeService['createProviderObservable'] =
    (providerName: string) => {
      return this._providerSubject
        .pipe(filter((e) => e.providerName === providerName))
        .pipe(map(({ providerName: name, ...rest }) => rest));
    };
}
