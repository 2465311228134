import type { StoryBuilder } from './story-builder';

import { StoryDefinition } from './story-definition';
import { ControlDefinition } from './types/control-definition';
import { ControlOptions } from './types/control-options';
import { OptionalControlOptions } from './types/optional-control-options';

export class StoryControls<TProps> {
  constructor(
    private readonly _builder: StoryBuilder<TProps>,
    private readonly _definition: StoryDefinition<TProps>
  ) {}

  get and() {
    return this._builder;
  }

  addControl = ({ name, ...rest }: ControlDefinition<keyof TProps, any>) => {
    this._definition.setArgTypeProp(name, rest);
    return this;
  };

  addColor = ({
    options: { presetColors },
    ...rest
  }: ControlOptions<
    keyof TProps,
    {
      presetColors?: string[];
    }
  >) =>
    this.addControl({
      ...rest,
      control: { type: 'color', presetColors },
    });

  addSelect = ({
    options,
    ...rest
  }: ControlOptions<keyof TProps, Array<string>>) =>
    this.addControl({
      ...rest,
      options,
      control: { type: 'select' },
    });

  addText = (options: Omit<ControlOptions<keyof TProps>, 'options'>) =>
    this.addControl({
      ...options,
      control: {
        type: 'text',
      },
    });

  addToggle = (options: Omit<ControlOptions<keyof TProps>, 'options'>) =>
    this.addControl({
      ...options,
      control: {
        type: 'boolean',
      },
    });

  addNumber = ({
    options,
    ...rest
  }: OptionalControlOptions<
    keyof TProps,
    { min?: number; max?: number; step?: number }
  >) =>
    this.addControl({
      ...rest,
      control: { type: 'number', ...options },
    });

  disable(name: keyof TProps) {
    this._definition.setArgTypeProp(name, {
      control: false,
    });
    return this;
  }

  hide(name: keyof TProps) {
    this._definition.setArgTypeProp(name, {
      table: {
        disable: true,
      },
    });
    return this;
  }

  disableAll() {
    this._definition.setParameter({
      controls: {
        disable: true,
      },
    });
    return this;
  }
}
