import { ReactNode } from 'react';

import {
  ControlledFormInput,
  FormInputComponent,
} from '@ui-furniture/lib/form';
import { SystemProps } from '@ui-furniture/style';

import { BaseSelect } from './base-select';
import { BaseSelectProps } from './types/base-select-props';

import { Label, LabelText, InputBorder, ErrorMessage } from '../common';

export const Select: FormInputComponent<
  BaseSelectProps & {
    label: string;
    informationTooltip?: ReactNode;
    isOptional?: boolean;
    w?: SystemProps['w'];
  }
> = ({
  name,
  isOptional = false,
  registerOptions,
  label,
  informationTooltip,
  w,
  isSearchable = false,
  ...rest
}) => (
  <ControlledFormInput
    name={name}
    registerOptions={registerOptions}
    render={({ field }) => (
      <Label w={w}>
        <LabelText
          isOptional={isOptional}
          informationTooltip={informationTooltip}
        >
          {label}
        </LabelText>
        <InputBorder>
          <BaseSelect {...rest} {...field} isSearchable={isSearchable} />
        </InputBorder>
        <ErrorMessage />
      </Label>
    )}
  />
);
