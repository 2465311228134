import { styled } from '@ui-furniture/style';

export const InputBorder = styled.divBox`
  display: flex;
  min-height: input;
  align-items: center;
  border: 1px solid rgba(65, 79, 101, 0.3);
  border-radius: ${({ borderRadius = 'md', theme }) =>
    theme.radii[borderRadius as string] || borderRadius};
  font-size: input;
  line-height: input;
  font-weight: 500;
  background-color: white;
  transition: base;
  position: relative;
  color: brand-text;
  :hover {
    border-color: palette-primary;
  }

  :after {
    pointer-events: none;
    transition: base;
    content: '';
    border: 2px solid transparent;
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: -1px;
    right: -1px;
    border-radius: ${({ borderRadius = 'md', theme }) =>
      theme.radii[borderRadius as string] || borderRadius};
  }
  :focus-within,
  .focus-within {
    :after {
      border-color: palette-primary;
      border-width: 2px;
    }
  }
`;
