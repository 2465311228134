import { FC } from 'react';

import { useWatch } from 'react-hook-form';
import { z } from 'zod';

import {
  Checkbox,
  Input,
  SubmitFormHandler,
  useForm,
} from '@ui-furniture/common/form';
import { Stack, VStack } from '@ui-furniture/elements';
import { useEmailDeliverabilityDisclaimer } from '@ui-furniture/hooks/use-email-deliverability-disclaimer';
import { Form, useFullResetOnSuccess } from '@ui-furniture/lib/form';
import { useFocusByIdOnMount } from '@ui-furniture/lib/hooks/use-focus-by-id-on-mount';
import { x } from '@ui-furniture/style';
import { Disclaimer } from '@ui-furniture/ui/disclaimer';
import { LoadingButton } from '@ui-furniture/ui/loading/button';
import { TermsAndConditionsText } from '@ui-furniture/ui/terms-and-conditions-text';

const signUpSchema = z.object({
  firstName: z.string().min(2, 'You name must be at least 2 letters'),
  lastName: z.string().min(2, 'You name must be at least 2 letters'),
  email: z
    .string()
    .email({ message: `This doesn't look like a valid email address` }),
  subscribeToNewsletter: z.boolean(),
  acceptTermsAndConditions: z.boolean().refine((value) => value === true, {
    message: 'You need to accept the terms and conditions to create an account',
  }),
  resetPersonalProfile: z.boolean().optional(),
});

export type SignUpFormHandler = SubmitFormHandler<typeof signUpSchema>;

export const SignUpForm: FC<{
  submit: SignUpFormHandler;
  isSubmitting: boolean;
  hasAssociatedData?: boolean;
}> = ({ submit, isSubmitting, hasAssociatedData }) => {
  const form = useForm(signUpSchema);
  useFullResetOnSuccess(form);
  const id = useFocusByIdOnMount();

  const email = useWatch({
    control: form.control,
    name: 'email',
  });

  const emailDisclaimer = useEmailDeliverabilityDisclaimer(email);

  return (
    <Form form={form} onSubmit={form.handleSubmit(submit)}>
      <VStack>
        {hasAssociatedData && (
          <Disclaimer type='info'>
            Don&#39;t worry, we will save the progress you have made so far!
          </Disclaimer>
        )}
        <Stack flexDirection={{ _: 'column', phablet: 'row' }} w='100%'>
          <x.div flex='1'>
            <Input
              id={id}
              name='firstName'
              label='First Name'
              autoComplete='given-name'
              placeholder='What is your first name?'
            />
          </x.div>
          <x.div flex='1'>
            <Input
              name='lastName'
              label='Last Name'
              autoComplete='family-name'
              placeholder='What is your last name?'
            />
          </x.div>
        </Stack>
        <Input
          id={id}
          name='email'
          type='email'
          label='Email'
          autoComplete='email'
          placeholder='What is your email?'
        />
        {emailDisclaimer}
        <Checkbox
          name='subscribeToNewsletter'
          label='Keep me updated with useful information and news'
        />
        <Checkbox
          name='acceptTermsAndConditions'
          label={<TermsAndConditionsText />}
        />
        <LoadingButton type='submit' isLoading={isSubmitting}>
          Sign Up
        </LoadingButton>
      </VStack>
    </Form>
  );
};
