import {
  useFieldRegistration,
  createErrorListFromFields,
} from '@ui-furniture/lib/form';

export const useAddressFieldsRegistration = (name: string) => {
  const id = useFieldRegistration(`${name}.id`);
  const line1 = useFieldRegistration(`${name}.line1`);
  const line2 = useFieldRegistration(`${name}.line2`);
  const line3 = useFieldRegistration(`${name}.line3`);
  const city = useFieldRegistration(`${name}.city`);
  const postcode = useFieldRegistration(`${name}.postcode`);

  const error = createErrorListFromFields([
    line1,
    line2,
    line3,
    city,
    postcode,
  ]);

  return {
    error,
    fields: {
      id,
      line1,
      line2,
      line3,
      city,
      postcode,
    },
  };
};

export type AddressFields = ReturnType<
  typeof useAddressFieldsRegistration
>['fields'];
