import {
  ComponentProps,
  forwardRef,
  InputHTMLAttributes,
  ReactNode,
} from 'react';

import { NumericFormat } from 'react-number-format';

import { Text } from '@ui-furniture/elements';
import { FormInput, useFieldRegistration } from '@ui-furniture/lib/form';
import { FormInputRegistrationProps } from '@ui-furniture/lib/form/types/form-input-registration-props';

import { Input as InputElement } from './components';

import { Label, LabelText, InputBorder, ErrorMessage } from '../common';

type InnerInputProps = FormInputRegistrationProps & {
  className?: string;
};

const formatInputs = {
  none: forwardRef<HTMLInputElement, InnerInputProps>((props, ref) => (
    <InputElement ref={ref} {...props} />
  )),
  currency: forwardRef<HTMLInputElement, InnerInputProps>(
    ({ ...props }, ref) => {
      return (
        <NumericFormat
          {...props}
          getInputRef={ref}
          customInput={InputElement}
          thousandSeparator
          // defaultValue={1000}
          prefix='£'
        />
      );
    }
  ),
  formattedNumber: forwardRef<HTMLInputElement, InnerInputProps>(
    (props, ref) => (
      <NumericFormat
        {...props}
        getInputRef={ref}
        customInput={InputElement}
        thousandSeparator
      />
    )
  ),
};

type FormProps<T> = InputHTMLAttributes<any> &
  InnerInputProps & {
    label: string;
    prefix?: string;
    isOptional?: boolean;
    informationTooltip?: ReactNode;
  } & T;
type Props =
  | FormProps<{
      format?: 'none';
    }>
  | FormProps<
      {
        format: 'currency' | 'formattedNumber';
      } & ComponentProps<typeof NumericFormat>
    >;

export const Input = ({
  name,
  registerOptions,
  label,
  isOptional = false,
  prefix,
  informationTooltip,
  format = 'none',
  className = '',
  ...rest
}: Props) => {
  const { registration, error, value } = useFieldRegistration(
    name,
    registerOptions
  );

  const FormattedInput = formatInputs[format];

  return (
    <FormInput error={error?.message}>
      <Label>
        <LabelText
          isOptional={isOptional}
          informationTooltip={informationTooltip}
        >
          {label}
        </LabelText>
        <InputBorder>
          {prefix && (
            <Text fontSize='input' color='palette-text' ml='md'>
              {prefix}
            </Text>
          )}
          <FormattedInput
            className={className ? `read ${className}` : 'read'}
            defaultValue={value}
            {...rest}
            {...registration}
          />
        </InputBorder>
        <ErrorMessage />
      </Label>
    </FormInput>
  );
};
