import type { SdkOptions } from 'onfido-sdk-ui';

import { FC } from 'react';

import { createGlobalStyle, x } from '@ui-furniture/style';

import { useOnfido } from './use-onfido';

const containerId = 'onfido-mount';
const OnfidoStyling = createGlobalStyle`
  .onfido-sdk-ui-Theme-modalOverlay {
    z-index: popover;
  }
`;
export const OnfidoComponent: FC<
  Omit<SdkOptions, 'containerId' | 'containerEl'>
> = (sdkOptions) => {
  useOnfido({
    containerId,
    ...sdkOptions,
  });

  return (
    <>
      <OnfidoStyling />
      <x.div id={containerId} zIndex='popover' />
    </>
  );
};
