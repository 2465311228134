import { FC, useCallback } from 'react';

import {
  StepComponentAnimator,
  useStepContext,
  StepContainer,
} from '@/common/multi-step-flow';
import { x } from '@/style';

import { ButtonBarFooter } from '@ui-furniture/common/form';
import { KycComponent } from '@ui-furniture/common/kyc';
import { Text } from '@ui-furniture/elements';
import { LoadingButton } from '@ui-furniture/ui/loading/button';

import { useKycApplication } from '../hooks/use-kyc-application';
import { useSubmitKycUserIdentityChecks } from '../hooks/use-submit-kyc-user-identity-checks';

export const DocumentsStep: FC = () => {
  const { next } = useStepContext();
  const submitKyc = useSubmitKycUserIdentityChecks();

  const onComplete = useCallback(async () => {
    await submitKyc();

    next();
  }, []);

  const kycApplication = useKycApplication({
    onComplete,
  });

  return (
    <>
      <StepContainer>
        <StepComponentAnimator>
          <Text variant='subTitle'>
            And finally, we’ll need you to take a couple of photos
          </Text>
        </StepComponentAnimator>
        <x.div my='xl'>
          <StepComponentAnimator>
            <Text>
              Please have one form of ID ready so we can verify the details you
              provided us on the previous page.
            </Text>
          </StepComponentAnimator>
        </x.div>
        <StepComponentAnimator>
          <ButtonBarFooter>
            <LoadingButton
              type='button'
              onClick={kycApplication.start}
              disabled={!kycApplication.canStart}
              isLoading={kycApplication.isInProgress}
            >
              Next
            </LoadingButton>
          </ButtonBarFooter>
        </StepComponentAnimator>
      </StepContainer>
      <KycComponent {...kycApplication.componentProps} />
    </>
  );
};
