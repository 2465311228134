import { FC, PropsWithChildren } from 'react';

import { SoloAnimation } from '@ui-furniture/ui/animation/solo';
import { Container } from '@ui-furniture/ui/layout/container';

export const Drawer: FC<
  PropsWithChildren<{
    isActive: boolean;
    close: () => void;
  }>
> = ({ isActive, close, children }) => {
  return (
    <>
      {isActive ? (
        <Container variant='absolute-overlay' zIndex='drawer'>
          <SoloAnimation>
            <Container
              variant='absolute-overlay'
              bg='overlay'
              cursor='pointer'
              {...{ onClick: close }}
              zIndex='-1'
            />
          </SoloAnimation>
          {children}
        </Container>
      ) : null}
    </>
  );
};
