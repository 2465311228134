import { DrawerMenu } from '@ui-furniture/ui/layout/drawer/drawer-menu';

import { AccountMenuItems } from './account-menu-items';
import { AccountVisibleBanner } from './account-visible-banner';

export const AccountDrawer = () => {
  return (
    <DrawerMenu
      title='Account menu'
      top={<AccountVisibleBanner withIcon={false} />}
      py='md'
      bottom={<AccountMenuItems />}
    />
  );
};
