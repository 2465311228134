import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, UseFormProps } from 'react-hook-form';
import { AnyZodObject, z } from 'zod';

export const useZodForm = <TSchema extends AnyZodObject, TContext>(
  schema:
    | TSchema
    | ((
        values: z.infer<TSchema>,
        context: TContext | undefined,
        options
      ) => TSchema),
  config: Omit<UseFormProps<z.infer<TSchema>, TContext>, 'resolver'> = {}
) => {
  const formHandler = useForm({
    ...config,
    resolver:
      typeof schema === 'function'
        ? (values, context, options) => {
            return zodResolver(schema(values, context, options))(
              values as any,
              context,
              options
            );
          }
        : (zodResolver(schema) as any),
  });

  return formHandler;
};
