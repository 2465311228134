import { style } from '@xstyled/styled-components';

export interface ConcealProps {
  conceal?: boolean;
}

export const conceal = style({
  prop: 'conceal',
  css: (value?: boolean) =>
    value === undefined
      ? {}
      : value
      ? {
          transition: 'base',
          filter: 'blur(10px)',
          userSelect: 'none',
          pointerEvents: 'none',
        }
      : { transition: 'base' },
});
