import { ComponentProps, FC } from 'react';

import { HStack, Text } from '@ui-furniture/elements';
import { StyledComponent, x } from '@ui-furniture/style';

import { IconName, LineIcon } from './line-icon';

const variant = {
  info: {
    iconName: 'infoSquare',
    iconColor: 'brand-text',
    bgColor: '#dce1ec',
    borderColor: '#cad2e3',
    textColor: 'brand-text',
  },
  success: {
    iconName: 'success',
    iconColor: 'brand-text',
    bgColor: '#dce1ec',
    borderColor: '#cad2e3',
    textColor: 'brand-text',
  },
  warn: {
    iconName: 'errorDiamond',
    iconColor: 'brand-text',
    bgColor: '#eee0c0',
    borderColor: '#F0CD7C',
    textColor: 'brand-text',
  },
  // error: {
  //   iconName: 'errorDiamond',
  // },
  // fail: {
  //   iconName: 'fail'
  // },
} as const satisfies Record<
  string,
  {
    iconName: IconName;
    iconColor: string;
    borderColor: string;
    bgColor: string;
    textColor: string;
  }
>;
export const DisclaimerTitle: FC<ComponentProps<typeof Text>> = (props) => (
  <Text fontWeight='700' {...props} />
);

export const Disclaimer: StyledComponent<{
  type: keyof typeof variant;
}> = ({ type = 'info', children }) => {
  const { iconName, bgColor, borderColor, iconColor, textColor } =
    variant[type];
  return (
    <HStack
      bg={bgColor}
      borderRadius='md'
      alignItems='center'
      p='md'
      w='100%'
      border='1px solid'
      borderColor={borderColor}
    >
      <x.div>
        <LineIcon name={iconName} color={iconColor} size='md' />
      </x.div>
      <x.div color={textColor} textAlign='left' w='100%'>
        {typeof children === 'string' ? (
          <Text variant='body'>{children}</Text>
        ) : (
          children
        )}
      </x.div>
    </HStack>
  );
};
