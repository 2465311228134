import { FC, useCallback } from 'react';

import omit from 'lodash/omit';
import { NextRouter } from 'next/router';

import { useAnalyticsReportingService } from '../analytics/use-analytics-reporting-service';
import { usePathChange } from '../hooks/use-path-change';

export const AnalyticsPathChangeReporter: FC = () => {
  const { reportEvent, setUserPropertyAction } = useAnalyticsReportingService();
  const onPathChange = useCallback((path, router: NextRouter) => {
    reportEvent({
      name: 'Route Change',
      id: window.location.pathname,
      pathname: window.location.pathname,
      path,
      title: document.title,
      hash: window.location.hash,
      queryString: window.location.search,
      query: omit(router.query, ['path']),
    });
    setUserPropertyAction({
      type: 'SET_ONCE',
      key: 'initial_landing_page',
      value: window.location.pathname,
    });
  }, []);
  usePathChange({
    onPathChange,
  });

  return null;
};
