import Script from 'next/script';

const GA = ({ id = undefined }: { id?: string }) =>
  id ? (
    <>
      <Script
        id='ga'
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${id}`}
      />
      <Script id='ga-data-layer'>
        {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
        
            gtag('config', '${id}');
          `}
      </Script>
    </>
  ) : null;

export default GA;
