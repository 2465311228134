import { useState } from 'react';

import { useRequestSignInMutation } from '@graphql/requests';

import { useAnalyticsReportingService } from '@ui-furniture/common/analytics';
import { useToast } from '@ui-furniture/common/toast';
import { useCurrentUser } from '@ui-furniture/hooks/use-current-user';
import { useFlowControls } from '@ui-furniture/hooks/use-flow-controls';
import { useLoadingCallback } from '@ui-furniture/hooks/use-loading-callback';

import { useCodeVerification } from './use-code-verification';

import { CodeVerificationFormHandler } from '../ui/code-verification-form';
import { SignInFormHandler } from '../ui/sign-in-form';

export const useSignIn = ({ onSuccess }: { onSuccess?: () => void }) => {
  const [token, setToken] = useState<string | undefined>();
  const { resetFlow } = useFlowControls();
  const [signInMutation] = useRequestSignInMutation();
  const { reportEvent } = useAnalyticsReportingService();
  const { verify } = useCodeVerification();
  const { refresh: refreshUser } = useCurrentUser();
  const { showToast } = useToast();

  const [isRequestingToken, requestToken] =
    useLoadingCallback<SignInFormHandler>(async (values) => {
      const result = await signInMutation({
        variables: {
          email: values.email,
        },
      });
      const newToken = result.data?.requestSignIn;
      if (!newToken) throw new Error('Token was not returned');

      reportEvent({
        name: 'Sign In',
      });
      setToken(newToken);
    });

  const [isVerifyingCode, verifyCode] =
    useLoadingCallback<CodeVerificationFormHandler>(
      async ({ code }) => {
        if (!token) throw new Error('Token is not defined');
        const { success } = await verify({ code, token });
        if (success) {
          await refreshUser();
          reportEvent({
            name: 'Sign In Code Verified',
          });
          resetFlow();
          showToast({
            severity: 'success',
            message: `You've successfully signed in`,
          });
          onSuccess?.();
        }
      },
      [token]
    );

  return {
    requestToken,
    isRequestingToken,
    verifyCode,
    isVerifyingCode,
    hasToken: !!token,
  };
};
