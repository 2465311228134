import { FieldValues, UseFormReturn } from 'react-hook-form';

import { useResetOnSuccess } from './use-reset-on-success';

export const useFullResetOnSuccess = <TFieldValues extends FieldValues>(
  formHandler: UseFormReturn<TFieldValues>,
  isDisabled = false
) => {
  useResetOnSuccess(formHandler, isDisabled, undefined, {
    keepDirty: false,
    keepDirtyValues: false,
    keepErrors: false,
    keepIsSubmitted: false,
    keepIsValid: false,
    keepSubmitCount: false,
    keepTouched: false,
    keepValues: false,
  });
};
