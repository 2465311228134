import { css, createGlobalStyle } from '@ui-furniture/style';

const PickerButtonCss = css`
  color: palette-text;
  transition: base;
  border: 1px solid transparent;
  background-color: white !important;

  :not(.Mui-selected) {
    border-color: transparent;
  }

  &.Mui-active,
  &.Mui-focused,
  &.Mui-focusVisible,
  &:active,
  &:focus,
  &:hover {
    border-width: 1px;
    border-color: palette-primary;
    color: palette-text;
  }

  &.Mui-selected {
    background-color: palette-primary !important;
    color: palette-primary-text;
    border-color: palette-primary;

    &:hover {
      border-color: palette-text;
    }
  }
`;

const YearPickerCss = css`
  .MuiYearPicker-root {
    .PrivatePickersYear-yearButton {
      ${PickerButtonCss}
    }
  }
`;
const MonthPickerCss = css`
  .MuiMonthPicker-root {
    .PrivatePickersMonth-root {
      ${PickerButtonCss}
    }
  }
`;
const DayPickerCss = css`
  .MuiPickersDay-root {
    ${PickerButtonCss}
  }
`;

export const GlobalStyles = createGlobalStyle`
  .MuiCalendarPicker-root {
    font: inherit;
    color: palette-text;

    .MuiSvgIcon-root {
      color: palette-text;
    }

    .PrivatePickersSlideTransition-root {
      min-height: 208px;
    }
    
    ${YearPickerCss}
    ${MonthPickerCss}
    ${DayPickerCss}
  }
`;
