import { PropsWithChildren } from 'react';

import { StyledComponent, x } from '@ui-furniture/style';
import { StyleVariants } from '@ui-furniture/style/types/style-variants';

const props = {
  'full-screen': {
    w: '100%',
    h: 'screen-height',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    overflow: 'hidden',
  },
  'full-screen-center': {
    w: '100%',
    my: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'auto',
  },
  'absolute-overlay': {
    top: '0',
    bottom: '0',
    right: '0',
    left: '0',
    position: 'absolute',
  },
  content: {
    w: '100%',
    padding: {
      _: 'md',
      phablet: 'lg',
      tablet: 'xl',
      desktop: '2xl',
    },
    mb: '2xl',
    display: 'flex',
    justifyContent: 'center',
  },
} as const satisfies StyleVariants;

export const Container: StyledComponent<
  PropsWithChildren<{
    variant: keyof typeof props;
  }>
> = ({ variant, children, ...rest }) => (
  <x.div {...props[variant]} {...rest}>
    {children}
  </x.div>
);
