export const time = {
  sm: 0.05,
  md: 0.1,
  lg: 0.3,
};
// export const TimeLg = 0.3;
export const movement = {
  sm: 8,
  md: 14,
};
