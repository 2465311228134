import { createContext } from 'react';

import { StepControls } from '../types/step-controls';

interface StepContext extends StepControls {
  id: string;
  pos: number;
  isActive: boolean;
}

export const stepContext = createContext<StepContext>(undefined as any);
