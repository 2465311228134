import {
  SpaceProp,
  StyledComponent,
  createVariants,
  x,
} from '@ui-furniture/style';

const variants = createVariants({
  light: {
    borderBottom: '1px solid',
    borderColor: 'palette-text',
    opacity: 0.15,
  },
  thin: {
    borderBottom: '1px solid',
    borderColor: 'palette-text',
    opacity: 0.15,
    mx: 'auto',
    maxWidth: '60px',
  },
});

export const Hr: StyledComponent<{
  my?: SpaceProp;
  variant?: keyof typeof variants;
}> = ({ variant = 'light', my = 'md', ...rest }) => (
  <x.div>
    <x.hr w='100%' my={my} {...variants[variant]} {...rest} />
  </x.div>
);
