import { createVariants } from '@ui-furniture/style';

export const variants = createVariants({
  regular: {
    borderColor: 'palette-border',
    backgroundColor: 'white',
  },
  dark: {
    borderColor: 'brand-beige',
    backgroundColor: 'overlay-weak',
  },
  border: {
    borderColor: 'palette-border',
  },
  information: {
    backgroundColor: 'brand-beige',
  },
  button: {
    borderColor: 'brand-beige',
    backgroundColor: 'white',
  },
  highlight: {
    borderColor: 'brand-yellow',
    backgroundColor: 'white',
  },
  disabled: {
    opacity: 0.7,
    borderColor: 'palette-border',
    backgroundColor: 'white',
  },
  accent: {
    backgroundColor: 'brand-bg-accent',
    borderColor: 'palette-border',
  },
  important: {
    backgroundColor: 'overlay-weak',
    borderColor: 'primary',
    boxShadow: 'highlight',
  },
  didYouKnow: {
    backgroundColor: 'brand-beige-light',
    borderColor: 'brand-yellow',
    borderRadius: 'md',
  },
});
