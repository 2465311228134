// eslint-disable-next-line import/first
import { LogLevelNames } from 'loglevel';

import { shape, from, load } from '@ui-furniture/lib/ConfigLoader';

const config = load(
  shape({
    env: from(process.env.NEXT_PUBLIC_ENV)
      .string()
      .required()
      .oneOf([
        'production',
        'staging',
        'demo',
        'preview',
        'development',
        'test',
      ]),
    baseUrl: from(process.env.NEXT_PUBLIC_BASEURL || process.env.VERCEL_URL)
      .string()
      .required(),
    logErrors: from(process.env.NEXT_PUBLIC_LOG_ERRORS || 'false').boolean(),
    logLevel: from(process.env.NEXT_PUBLIC_LOG_LEVEL || 'silent')
      .string()
      .oneOf([
        'silent',
        'debug',
        'info',
        'warn',
        'error',
      ] as Array<LogLevelNames>),
    analytics: shape({
      amplitudeApiKey: from(process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY).string(),
      gaId: from(process.env.NEXT_PUBLIC_GA_ID).string(),
      metaId: from(process.env.NEXT_PUBLIC_META_ID).string(),
      activeCampaignId: from(
        process.env.NEXT_PUBLIC_ACTIVE_CAMPAIGN_ID
      ).string(),
      fullStoryId: from(process.env.NEXT_PUBLIC_FULL_STORY_ID).string(),
      hypertuneToken: from(process.env.NEXT_PUBLIC_HYPERTUNE_TOKEN).string(),
      shouldLog: from(
        process.env.NEXT_PUBLIC_ANALYTICS_SHOULD_LOG || 'false'
      ).boolean(),
    }),
    cookieYes: from(process.env.NEXT_PUBLIC_COOKIEYES).string(),
    hubspotId: from(process.env.NEXT_PUBLIC_HUBSPOT_ID).string(),
    imageCdn: from(process.env.NEXT_PUBLIC_IMAGE_CDN).string().required(),
    backendDomain: from(process.env.NEXT_PUBLIC_BACKEND_DOMAIN)
      .string()
      .required(),
    addressLookupApiKey: from(process.env.NEXT_PUBLIC_ADDRESS_LOOKUP_API_KEY)
      .string()
      .required(),
    bannerMessage: from(process.env.NEXT_PUBLIC_BANNER_MESSAGE).string(),
    services: shape({
      google: shape({
        jsSdkApiKey: from(process.env.NEXT_PUBLIC_GOOGLE_JS_SDK_API_KEY)
          .string()
          .required(),
      }),
    }),
    bytescalePublicKey: from(process.env.NEXT_PUBLIC_BYTESCALE_PUBLIC_KEY)
      .string()
      .required(),
    bytescaleAccountId: from(process.env.NEXT_PUBLIC_BYTESCALE_ACCOUNT_ID)
      .string()
      .required(),
    bytescaleRootFolder: from(process.env.NEXT_PUBLIC_BYTESCALE_ROOT_FOLDER)
      .string()
      .required()
      .oneOf(['dev', 'prod']),
  })
);
export default config;
