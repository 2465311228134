import { FC, ReactNode } from 'react';

import { Button, HStack, Text } from '@ui-furniture/elements';
import { useQueryParam } from '@ui-furniture/lib/hooks/use-query-param';
import { LineIcon } from '@ui-furniture/ui/line-icon';

export const DrawerMenuHeader: FC<{
  title?: string;
  customHeader?: ReactNode;
}> = ({ title, customHeader }) => {
  const { set } = useQueryParam('drawer');
  const hasHeader = title;

  if (customHeader) {
    return <>{customHeader}</>;
  }
  if (hasHeader) {
    return (
      <HStack alignItems='flex-start' p='md'>
        <Button type='button' variant='blank' onClick={() => set()}>
          <LineIcon name='arrowLeft' size='sm' />
        </Button>
        {title ? (
          <Text flex='1' mt='6px' variant='bodyBig'>
            {title}
          </Text>
        ) : null}
      </HStack>
    );
  }
  return null;
};
