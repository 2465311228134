import { FC, PropsWithChildren, createContext, useContext } from 'react';

import { FormInputError } from './types/form-input-error';

const formInputContext = createContext<{
  error: FormInputError | null;
}>(undefined as any);

export const FormInputProvider: FC<
  PropsWithChildren<{
    error: FormInputError | null;
  }>
> = ({ children, error }) => (
  <formInputContext.Provider value={{ error }}>
    {children}
  </formInputContext.Provider>
);

export function useFormInput() {
  const context = useContext(formInputContext);
  if (!context)
    throw new Error('useFormInput must be used within a FormInputProvider');
  return context;
}
