import { styled, css } from '@ui-furniture/style';

export const FieldGroupCell = styled.divBox<{
  $hasRightBorder?: boolean;
  $hasBottomBorder?: boolean;
}>`
  border: none;
  ${({ $hasRightBorder }) =>
    $hasRightBorder &&
    css`
      border-right: 1px solid;
      border-right-color: rgba(65, 79, 101, 0.3);
    `};
  ${({ $hasBottomBorder }) =>
    $hasBottomBorder &&
    css`
      border-bottom: 1px solid;
      border-bottom-color: rgba(65, 79, 101, 0.3);
    `};
`;
