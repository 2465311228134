import { FlattenInterpolation } from 'styled-components';

import { css } from '@ui-furniture/style';

import { outlineButtonCss } from '../css/outline-button-css';
import { roundedButtonCss } from '../css/rounded-button-css';
import { simpleLinkCss } from '../css/simple-link-css';

export const variants = {
  blank: css`
    padding: 0;
  `,
  color: css`
    padding: 0;
    transition: base;
    :hover {
      color: palette-primary;
    }
  `,
  input: css`
    display: flex;
    min-height: input;
    align-items: center;
    border: 1px solid rgba(65, 79, 101, 0.3);
    border-radius: md;
    font-size: input;
    line-height: input;
    font-weight: 500;
    background-color: white;
    transition: base;
    position: relative;
    color: brand-text;
    :hover {
      border-color: palette-primary;
    }

    :after {
      pointer-events: none;
      transition: base;
      content: '';
      border: 2px solid transparent;
      position: absolute;
      top: -1px;
      bottom: -1px;
      left: -1px;
      right: -1px;
      border-radius: md;
    }
    :focus-within,
    .focus-within {
      :after {
        border-color: palette-primary;
        border-width: 2px;
      }
    }
  `,
  simpleLink: css`
    ${simpleLinkCss}
  `,
  rounded: css`
    ${roundedButtonCss}
    padding: sm 2xl;
  `,
  outline: css`
    ${outlineButtonCss}
    padding: sm lg;
  `,
} as const satisfies Record<string, FlattenInterpolation<any>>;
