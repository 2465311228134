import { CSSProperties, style } from '@xstyled/styled-components';

export interface FilterProps {
  filter?: CSSProperties['filter'];
}

export const filter = style({
  prop: 'filter',
  css: (value) => ({
    filter: value,
  }),
});
