import { useCallback } from 'react';

export const useSafeUpdateArray = <T>({
  array,
  add,
  remove,
}: {
  array: Array<T>;
  add: (value: T) => any;
  remove: (value: T) => any;
}) => {
  return useCallback(
    (value: T, isOpen: boolean) => {
      if (isOpen) {
        if (!array.includes(value)) {
          add(value);
          return { didChange: true };
        }
      } else if (array.includes(value)) {
        remove(value);
        return { didChange: true };
      }
      return { didChange: false };
    },
    [array, add, remove]
  );
};
