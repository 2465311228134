import type { SdkOptions } from 'onfido-sdk-ui';

import { useCallback, FC } from 'react';

import { OnfidoComponent } from '@ui-furniture/lib/onfido';

import { KycSteps } from './types/steps';
import { useStyles } from './use-styles';

export const KycComponent: FC<{
  token: string | undefined;
  isOpen: boolean;
  steps: KycSteps;
  onClose: () => void;
  onComplete?: SdkOptions['onComplete'];
  onError?: SdkOptions['onError'];
  onUserExit?: SdkOptions['onUserExit'];
}> = ({ token, isOpen, steps, onClose, onComplete, onError, onUserExit }) => {
  const styles = useStyles();

  const onModalRequestClose = useCallback(() => {
    onClose();
  }, []);

  return (
    <OnfidoComponent
      token={token}
      steps={steps}
      isModalOpen={isOpen}
      useModal
      onComplete={onComplete}
      onError={onError}
      onUserExit={onUserExit}
      onModalRequestClose={onModalRequestClose}
      customUI={styles}
    />
  );
};
