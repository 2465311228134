import { ComponentProps, FC, PropsWithChildren } from 'react';

import omit from 'lodash/omit';

export function setPropsAndExtend<
  TPropsExtension extends PropsWithChildren,
  TProps extends PropsWithChildren,
  TComponent extends FC<TProps>,
  TFullProps = ComponentProps<TComponent> & Partial<TPropsExtension>
>(
  Component: TComponent,
  defaultOutputProps: (props: TFullProps) => ComponentProps<TComponent>,
  defaultInputProps: TPropsExtension = {} as any
): FC<TFullProps> {
  const TypedComponent = Component as FC<TProps>;
  return (props: TFullProps) =>
    (
      <TypedComponent
        {...omit(props as any, Object.keys(defaultInputProps))}
        {...defaultOutputProps({ ...defaultInputProps, ...props })}
      />
    ) as any;
}

export const themeColor =
  (name: string) =>
  ({ theme, ...rest }: any) =>
    theme.colors[rest[name] as any] || rest[name];
