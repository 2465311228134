import { Button } from '@ui-furniture/elements';
import { ExtendComponent } from '@ui-furniture/style/types/extend-component';

import { LoadingInline } from './inline';

export const LoadingButton: ExtendComponent<
  typeof Button,
  {
    isLoading: boolean;
  }
> = ({ isLoading, children, ...rest }) => (
  <Button {...rest} pointerEvents={isLoading ? 'none' : 'auto'}>
    <LoadingInline isLoading={isLoading}>{children}</LoadingInline>
  </Button>
);
