import { ComponentProps, ReactNode } from 'react';

import ReactSelectAsyncSelect from 'react-select/async';

import { useOverrideStyles } from '@ui-furniture/common/react-select';
import { overrideComponents } from '@ui-furniture/common/react-select/override-components';
import {
  ControlledFormInput,
  FormInputComponent,
} from '@ui-furniture/lib/form';
import { SystemProps } from '@ui-furniture/style';

import { Label, LabelText, InputBorder, ErrorMessage } from '../common';

export const AsyncSelect: FormInputComponent<
  ComponentProps<typeof ReactSelectAsyncSelect> & {
    label: string;
    informationTooltip?: ReactNode;
    w?: SystemProps['w'];
  }
> = ({
  name,
  registerOptions,
  label,
  informationTooltip,
  w,
  components,
  ...rest
}) => {
  const overrideStyles = useOverrideStyles();
  return (
    <ControlledFormInput
      name={name}
      registerOptions={registerOptions}
      render={({ field }) => (
        <Label w={w}>
          <LabelText isOptional={false} informationTooltip={informationTooltip}>
            {label}
          </LabelText>
          <InputBorder>
            <ReactSelectAsyncSelect
              components={{
                ...overrideComponents,
                ...components,
              }}
              {...rest}
              {...field}
              styles={overrideStyles}
            />
          </InputBorder>
          <ErrorMessage />
        </Label>
      )}
    />
  );
};
