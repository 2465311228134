import { useId } from 'react';

import { errorService } from '@ui-furniture/common/services/error';

export const useInitTemporaryId = () => {
  const randomId = useId();
  return new Promise((resolve) => {
    if (typeof window !== 'undefined') {
      const id = sessionStorage.getItem('temporaryId');
      if (sessionStorage.getItem('temporaryId')) {
        resolve(id);
        return;
      }
      fetch('https://jsonip.com')
        .then((response) => response.json())
        .then((data: { ip: string }) => {
          sessionStorage.setItem('temporaryId', data.ip);
          resolve(data.ip);
        })
        .catch((error) => {
          sessionStorage.setItem('temporaryId', randomId);
          resolve(randomId);
          errorService.captureException(error);
        });
    } else {
      resolve(null);
    }
  });
};

export const getTemporaryId = () => {
  return sessionStorage.getItem('temporaryId') || null;
};
