import { FC } from 'react';

import {
  StepComponentAnimator,
  StepContainer,
  useStepContext,
} from '@/common/multi-step-flow';

import { Text, VStack, Button, Em } from '@ui-furniture/elements';

import { useKycFlowManager } from '../context/kyc-flow-manager';

export const IntroductionStep: FC = () => {
  const { next } = useStepContext();
  const { setProgressing } = useKycFlowManager();
  return (
    <StepContainer>
      <StepComponentAnimator>
        <Text variant='title' as='h2'>
          Let’s
          <Em>
            {' '}
            verify
            <br /> your identity
          </Em>
        </Text>
      </StepComponentAnimator>
      <StepComponentAnimator>
        <VStack my='xl'>
          <Text fontWeight='bold'>
            Let’s get the boring but important bit out the way.
            <br /> It should only take a couple of minutes
          </Text>
          <Text>
            Every estate agency needs to perform some checks on you as a person.
            This is known as KYC (Know your customer) and it’s a legal
            requirement that protects you and the marketplace from fraud.
          </Text>
          <Text>
            Instead of having to do this for every estate agent, we want to save
            you time and hassle, whilst increasing your protections.
          </Text>
          <Text>
            We leverage the latest technology to make the process as simple as
            taking a selfie and a photo of an id (so make sure you have one to
            hand).
          </Text>
        </VStack>
      </StepComponentAnimator>
      <StepComponentAnimator>
        <Button
          aria-label='start'
          type='button'
          onClick={async () => {
            setProgressing();
            next();
          }}
        >
          Let’s start
        </Button>
      </StepComponentAnimator>
    </StepContainer>
  );
};
