import { FC, PropsWithChildren, useEffect } from 'react';

import { useRouter } from 'next/router';
import { useLocalStorage } from 'usehooks-ts';

import { useCurrentUser } from '@ui-furniture/hooks/use-current-user';

import { referralContext } from './referral-context';

export const ReferralProvider: FC<PropsWithChildren> = ({ children }) => {
  const { query } = useRouter();
  const { currentUser } = useCurrentUser();
  const [referralCode, setReferralCode] = useLocalStorage<string | undefined>(
    'referralCode',
    undefined
  );

  const removeReferralCode = () => {
    setReferralCode(undefined);
  };

  useEffect(() => {
    if (query.refer && query.refer !== currentUser?.referralCode) {
      setReferralCode(query.refer as string);
    }
  }, [query]);

  return (
    <referralContext.Provider
      value={{
        referralCode,
        removeReferralCode,
      }}
    >
      {children}
    </referralContext.Provider>
  );
};
