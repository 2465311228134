/* eslint-disable @typescript-eslint/member-ordering */

import { NextRouter } from 'next/router';

import { StoryControls } from './story-controls';
import { StoryDefinition } from './story-definition';

export class StoryBuilder<TProps> {
  constructor(public readonly definition: StoryDefinition<TProps>) {}

  public readonly controls = new StoryControls<TProps>(this, this.definition);

  private _disableControlsIfNone() {
    if (!this.definition.hasControlsSet) this.controls.disableAll();
  }
  private _setArgTypeProp = (name: keyof TProps, value: any) => {
    this.definition.setArgTypeProp(name, value);
    return this;
  };

  addAction = (name: keyof TProps, logName = name) =>
    this._setArgTypeProp(name, {
      action: logName,
    });

  setName = (name: string) => {
    this.definition.setName(name);
    return this;
  };
  setProp = <TName extends keyof TProps>({
    name,
    value,
    disableEdit,
  }: {
    name: TName;
    value: TProps[TName];
    disableEdit?: boolean;
  }) => {
    this.definition.setProp(name, value);
    if (disableEdit) this.controls.disable(name);
    return this;
  };
  setRoute = (routerProps: Partial<NextRouter>) => {
    this.definition.setParameter({
      nextjs: {
        router: routerProps,
      },
    });
    return this;
  };
  setContainerProps = (props: Object) => {
    const name = '_ContainerProps_';
    this.definition.setProp(name as any, props as any);
    this.controls.hide(name as any);
    return this;
  };
  setPropObject = (obj: Partial<TProps>) => {
    this.definition.setPropObject(obj);
    return this;
  };

  private _beforeRender = () => {
    this._disableControlsIfNone();
  };

  render = () => {
    this._beforeRender();

    return this.definition.render;
  };
}
