import { MotionProps, motion } from 'framer-motion';

import { movement } from '@ui-furniture/lib/utils';
import { MotionComponentWithStyledProps, x } from '@ui-furniture/style';

const motionVariants = {
  collapse: {
    initial: 'hide',
    animate: 'show',
    exit: 'hide',
    variants: {
      hide: {
        overflow: 'hidden',
        opacity: 0,
        height: 0,
      },
      show: {
        overflow: 'hidden',
        opacity: 1,
        height: 'unset',
      },
    },
  },
  'fade-up': {
    initial: 'hide',
    animate: 'show',
    exit: 'hide',
    variants: {
      hide: {
        opacity: 0,
        y: movement.sm,
      },
      show: {
        opacity: 1,
        y: 0,
      },
    },
  },
  fade: {
    initial: 'hide',
    animate: 'show',
    exit: 'hide',
    transition: {
      duration: 2,
    },
    variants: {
      hide: {
        opacity: 0,
      },
      show: {
        opacity: 1,
      },
    },
  },
} as const satisfies Record<string, MotionProps>;

export const SoloAnimation: MotionComponentWithStyledProps<{
  animation?: keyof typeof motionVariants;
}> = ({ animation = 'fade', ...rest }) => {
  return (
    <x.div {...{ as: motion.div }} {...rest} {...motionVariants[animation]} />
  );
};
