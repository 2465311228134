import { FC, PropsWithChildren } from 'react';

import { ThemeProvider, useTheme } from '@xstyled/styled-components';

import type { TPaletteFunction } from '@ui-furniture/lib/Palette';

import { mapPaletteToThemeVariables } from './utils';

const Provider: FC<
  PropsWithChildren<{
    createPalette?: TPaletteFunction;
  }>
> = ({ children, createPalette }) => {
  const theme = useTheme();

  let newTheme = theme;
  if (createPalette) {
    const newPalette = createPalette(theme.colors);

    newTheme = {
      ...theme,
      colors: {
        ...theme.colors,
        ...mapPaletteToThemeVariables(newPalette),
      },
    };
  }

  return <ThemeProvider theme={newTheme}>{children}</ThemeProvider>;
};

export default Provider;
