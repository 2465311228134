import { getAddressService } from '@ui-furniture/clients/get-address';

export const useFetchAddressSuggestions =
  ({ minLength = 3 } = {}) =>
  async (term: string) => {
    if (term?.length < minLength - 1) return [];
    const options = await getAddressService.autocomplete(term);
    return options.data.suggestions.map((o) => ({
      label: o.address,
      value: o.id,
    }));
  };
