import { FC, PropsWithChildren, createContext, useState } from 'react';

import { useQueryParam } from '@ui-furniture/lib/hooks/use-query-param';
import { useSafeUpdateArray } from '@ui-furniture/lib/hooks/use-safe-update-array';

export const rootContext = createContext<{
  activeModals: Array<string>;
  setStateModalOpen: (id: string, isOpen: boolean) => { didChange: boolean };
  setQueryParamModalOpen: (
    id: string,
    isOpen: boolean
  ) => { didChange: boolean };
  isAnyModalOpen: boolean;
}>(undefined as any);

export const RootModalProvider: FC<
  PropsWithChildren<{
    queryParamName?: string;
  }>
> = ({ queryParamName = 'm', children }) => {
  const {
    queryParamValues: queryParamActiveModals,
    add: addQueryParamModal,
    remove: removeQueryParamModal,
  } = useQueryParam(queryParamName);
  const [stateActiveModals, setStateActiveModals] = useState<Array<string>>([]);

  const addStateModal = (id: string) => {
    setStateActiveModals([...stateActiveModals, id]);
  };

  const removeStateModal = (id: string) => {
    setStateActiveModals((ids) => {
      const filteredIds = ids.filter((arrayId) => arrayId !== id);
      return filteredIds;
    });
  };

  const setStateModalOpen = useSafeUpdateArray({
    array: stateActiveModals,
    add: addStateModal,
    remove: removeStateModal,
  });
  const setQueryParamModalOpen = useSafeUpdateArray({
    array: queryParamActiveModals,
    add: addQueryParamModal,
    remove: removeQueryParamModal,
  });

  const activeModals = [...queryParamActiveModals, ...stateActiveModals];

  const isAnyModalOpen = activeModals.length > 0;

  return (
    <rootContext.Provider
      value={{
        activeModals,
        setStateModalOpen,
        setQueryParamModalOpen,
        isAnyModalOpen,
      }}
    >
      {children}
    </rootContext.Provider>
  );
};
