import { PropsWithChildren, FC } from 'react';

import { SnackbarProvider } from 'notistack';

import { createGlobalStyle } from '@ui-furniture/style';

import { ToastComponent } from '../toast/components/toasts';

export const NotistackGlobalStyles = createGlobalStyle`
  .toast-container {
    z-index: toast !important;
  }
`;

export const ToastProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <>
      <NotistackGlobalStyles />
      <SnackbarProvider
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        autoHideDuration={5000}
        Components={{
          success: ToastComponent,
          info: ToastComponent,
          warning: ToastComponent,
          error: ToastComponent,
        }}
        classes={{
          containerRoot: 'toast-container',
        }}
      >
        {children}
      </SnackbarProvider>
    </>
  );
};
