import { css } from '@ui-furniture/style';

export const simpleLinkCss = css<{
  color?: string;
}>`
  color: palette-primary;
  text-decoration: underline;
  font-weight: 600;
  :hover,
  :focus {
    text-decoration: none;
  }
`;
