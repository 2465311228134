import { FC, PropsWithChildren } from 'react';

import { BlurContainer } from './components/blur-container';
import { DisableScrollGlobalStyle } from './components/disable-scroll-global-style';
import { RootModalProvider } from './context/root';
import { useRootModalContext } from './hooks/use-root-modal-context';

const InnerContainer: FC<PropsWithChildren> = ({ children }) => {
  const { isAnyModalOpen } = useRootModalContext();
  return (
    <>
      <DisableScrollGlobalStyle />
      <BlurContainer $isBlurred={isAnyModalOpen}>{children}</BlurContainer>
    </>
  );
};

export const RootModalContainer: FC<
  PropsWithChildren<{
    queryParamName?: string;
  }>
> = ({ queryParamName = 'm', children }) => (
  <RootModalProvider queryParamName={queryParamName}>
    <InnerContainer>{children}</InnerContainer>
  </RootModalProvider>
);
