import { useEffect, useId } from 'react';

export const useFocusByIdOnMount = () => {
  const id = useId();

  useEffect(() => {
    document.getElementById(id)?.focus();
  }, [id]);

  return id;
};
