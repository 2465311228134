import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { AppProps } from 'next/app';
import Head from 'next/head';

import { DynamicSetupContainer } from '@/common/dynamic-setup-container';
import config from '@/config';
import { KycApplyModal } from '@/features/kyc/apply/ui/kyc-apply-modal';
import { DashboardLayout } from '@/features/layout/dashboard-layout';

import { AnalyticsServiceProvider } from '@ui-furniture/common/analytics';
import { AnalyticsPathChangeReporter } from '@ui-furniture/common/analytics-reporters/path-change-reporter';
import { AnalyticsServices } from '@ui-furniture/common/analytics-services';
import { amplitudeSubscriber } from '@ui-furniture/common/analytics-services/amplitude-subscriber';
import { consoleSubscriber } from '@ui-furniture/common/analytics-services/console-subscriber';
import { fullStorySubscriber } from '@ui-furniture/common/analytics-services/full-story-subscriber';
import { googleAnalyticsSubscriber } from '@ui-furniture/common/analytics-services/google-analytics-subscriber';
import { metaSubscriber } from '@ui-furniture/common/analytics-services/meta-subscriber';
import { AppStateProvider } from '@ui-furniture/common/app-state/app-state-provider';
import { SEO } from '@ui-furniture/common/data/seo';
import { FlowManagerProvider } from '@ui-furniture/common/flow-manager/flow-manager-provider';
import { callbacks } from '@ui-furniture/common/flow-manager/types';
import { RenderPageLayout } from '@ui-furniture/common/page-layout';
import { ApiApolloProvider } from '@ui-furniture/common/providers/api-apollo-provider';
import { StyleProvider } from '@ui-furniture/common/providers/style-provider';
import { ToastProvider } from '@ui-furniture/common/providers/toast-provider';
import { ReferralProvider } from '@ui-furniture/common/referral';
import { UI } from '@ui-furniture/constants/ui';
import { RootModalContainer } from '@ui-furniture/lib/modal';
import { useInitTemporaryId } from '@ui-furniture/lib/utils/temporary-id';
import CookieYes from '@ui-furniture/ui/snippets/cookieYes';
import { Favicons } from '@ui-furniture/ui/snippets/favicons';
import Hubspot from '@ui-furniture/ui/snippets/hubspot';
import SocialMeta from '@ui-furniture/ui/snippets/socialMeta';

function App({ Component, pageProps }: AppProps) {
  useInitTemporaryId();
  return (
    <DynamicSetupContainer>
      <Head>
        <title>{SEO.title}</title>
        <meta content='width=device-width, initial-scale=1' name='viewport' />
        <meta content='ie=edge' httpEquiv='x-ua-compatible' />

        <Favicons tileColor={UI.tileColor} name={SEO.companyName} />
        <SocialMeta
          baseUrl={config.baseUrl}
          title={SEO.title}
          description={SEO.description}
          image={SEO.imageUrl}
          keywords={SEO.keywords}
        />
        <Hubspot id={config.hubspotId} />
        <CookieYes id={config.cookieYes} />
      </Head>
      <AnalyticsServices />
      <StyleProvider>
        <ToastProvider>
          <ApiApolloProvider>
            <AnalyticsServiceProvider
              subscribers={[
                amplitudeSubscriber,
                fullStorySubscriber,
                consoleSubscriber,
                googleAnalyticsSubscriber,
                metaSubscriber,
              ]}
            >
              <AnalyticsPathChangeReporter />
              <AppStateProvider>
                <FlowManagerProvider callbacks={callbacks}>
                  <ReferralProvider>
                    <RootModalContainer>
                      <RenderPageLayout
                        defaultLayout={DashboardLayout}
                        Component={Component}
                      >
                        <Component {...pageProps} />
                      </RenderPageLayout>
                      <KycApplyModal />
                    </RootModalContainer>
                  </ReferralProvider>
                </FlowManagerProvider>
              </AppStateProvider>
            </AnalyticsServiceProvider>
          </ApiApolloProvider>
        </ToastProvider>
      </StyleProvider>
    </DynamicSetupContainer>
  );
}

export default App;
