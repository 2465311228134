import {
  StyleGenerator,
  compose,
  system,
  createCss,
  SystemProps,
} from '@xstyled/styled-components';

import { absoluteFull, AbsoluteFullProps } from './prop-utils/absolute-full';
import {
  allowWordBreak,
  AllowWordBreakProps,
} from './prop-utils/allow-word-break';
import { aspectRatio, AspectRatioProps } from './prop-utils/aspect-ratio';
import {
  borderRadiusTopLeft,
  borderRadiusTopRight,
  borderRadiusBottomLeft,
  borderRadiusBottomRight,
  BorderRadiusProps,
} from './prop-utils/border-radius';
import { borderVariant, BorderVariantProps } from './prop-utils/border-variant';
import { conceal, ConcealProps } from './prop-utils/conceal';
import { contentWidth, ContentWidthProps } from './prop-utils/content-width';
import { ellipsis, EllipsisProps } from './prop-utils/ellipsis';
import { filter, FilterProps } from './prop-utils/filter';
import {
  fixPaddingInScrollContainer,
  FixPaddingInScrollContainerProps,
} from './prop-utils/fix-padding-in-scroll-container';
import { fixedFull, FixedFullProps } from './prop-utils/fixed-full';
import {
  flexCenteredContent,
  FlexCenteredContentProps,
} from './prop-utils/flex-centered-content';
import {
  flexFillContent,
  FlexFillContentProps,
} from './prop-utils/flex-fill-content';
import { fullScreen, FullScreenProps } from './prop-utils/full-screen';
import { groupBorder, GroupBorderProps } from './prop-utils/group-border';

export const { css, styled, x, createGlobalStyle } = createCss<
  StyleGenerator<
    SystemProps &
      AbsoluteFullProps &
      AllowWordBreakProps &
      AspectRatioProps &
      BorderVariantProps &
      BorderRadiusProps &
      ContentWidthProps &
      ConcealProps &
      EllipsisProps &
      FilterProps &
      FixedFullProps &
      FixPaddingInScrollContainerProps &
      FlexCenteredContentProps &
      FlexFillContentProps &
      FullScreenProps &
      GroupBorderProps
  >
>(
  compose(
    system,
    absoluteFull,
    allowWordBreak,
    borderRadiusTopLeft,
    borderRadiusTopRight,
    borderRadiusBottomLeft,
    borderRadiusBottomRight,
    aspectRatio,
    borderVariant,
    contentWidth,
    conceal,
    ellipsis,
    filter,
    fixedFull,
    fixPaddingInScrollContainer,
    flexCenteredContent,
    flexFillContent,
    fullScreen,
    groupBorder
  )
);
