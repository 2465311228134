import { FC, PropsWithChildren } from 'react';

import { useDisableBodyScrollWhenMounted } from '@ui-furniture/lib/hooks/use-disable-body-scroll-when-mounted';

export const DisableBodyScrollWhenMounted: FC<PropsWithChildren> = ({
  children,
}) => {
  useDisableBodyScrollWhenMounted();

  return <>{children}</>;
};
