import { useContext } from 'react';

import { appStateContext } from './context';

export const useAppStateContext = () => {
  const c = useContext(appStateContext);
  if (!c)
    throw new Error('Cannot get appState context outside of AppStateProvider');
  return c;
};
