/* eslint-disable no-console */
import { useMemo, useState } from 'react';

export const useDynamicSetup = (
  setupTasks: Array<{
    name: string;
    isNeeded: boolean;
    runTask: () => Promise<any>;
  }>
) => {
  const tasksToRun = useMemo(
    () => setupTasks.filter(({ isNeeded }) => isNeeded),
    []
  );

  const [isReady, setIsReady] = useState(tasksToRun.length === 0);

  useMemo(() => {
    const fetchTasks = async () => {
      if (tasksToRun.length > 0) {
        console.info('Dynamic Setup Required - Loading Now');
        await Promise.all(
          tasksToRun.map(({ name, runTask }) => {
            console.info(`Loading ${name} ...`);
            return runTask();
          })
        );
        console.info('Dynamic Setup Complete');
        setIsReady(true);
      }
    };
    fetchTasks().catch((err) => {
      throw err;
    });
  }, []);

  return isReady;
};
