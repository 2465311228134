import { useRequestUpgradeAccountMutation } from '@graphql/requests';

import { useAnalyticsReportingService } from '@ui-furniture/common/analytics';
import { useReferralCode } from '@ui-furniture/common/referral';
import { useCurrentUser } from '@ui-furniture/hooks/use-current-user';
import { useFlowControls } from '@ui-furniture/hooks/use-flow-controls';
import { useLoadingCallback } from '@ui-furniture/hooks/use-loading-callback';

export const useUpgradeAccount = () => {
  const { referralCode, removeReferralCode } = useReferralCode();
  const [upgradeAccountMutation] = useRequestUpgradeAccountMutation();
  const { reportEvent } = useAnalyticsReportingService();
  const { completeFlowSuccessfully } = useFlowControls();
  const { refresh: refreshUser } = useCurrentUser();

  const [isUpgradingAccount, upgradeAccount] = useLoadingCallback(
    async ({
      firstName,
      lastName,
      preferredName,
      subscribeToNewsletter,
    }: {
      firstName: string;
      lastName: string;
      preferredName?: string;
      subscribeToNewsletter: boolean;
    }) => {
      await upgradeAccountMutation({
        variables: {
          userDetails: {
            firstName,
            lastName,
            preferredName,
            subscribeToNewsletter,
            referralCode,
          },
        },
      });
      reportEvent({
        name: 'Upgrade account',
        preferredName,
      });
      await refreshUser();
      removeReferralCode();
      completeFlowSuccessfully({
        callbackName: 'create-account',
        conversion: true,
        fallbackUrl: '/',
      });
    },
    [upgradeAccountMutation]
  );

  return {
    isUpgradingAccount,
    upgradeAccount,
  };
};
