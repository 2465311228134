import { motion } from 'framer-motion';

import { x, MotionComponentWithStyledProps } from '@/style';

export const StepAnimationContainer: MotionComponentWithStyledProps = (
  props
) => (
  <x.div
    {...props}
    as={motion.div}
    style={{ width: '100%', flex: '1', display: 'flex', overflow: 'auto' }}
    initial='stepHide'
    animate='stepShow'
    exit='stepHide'
    variants={{
      stepShow: {
        transition: {
          staggerChildren: 0.1,
        },
      },
      stepHide: {
        transition: {
          staggerChildren: 0.05,
        },
      },
    }}
  />
);
