import { Story } from '@storybook/react';

import { StorybookPlugin } from './plugins/base';
import { StoryBuilder } from './story-builder';
import { StoryFactory } from './story-factory';

export class StoryPluginDecorator<
  TStoryFactory extends StoryFactory<any>,
  TPluginProps extends string,
  TPluginCreator extends <T>(
    builder: StoryBuilder<T>
  ) => Record<TPluginProps, StorybookPlugin<T>>
> {
  constructor(
    private readonly _factory: TStoryFactory,
    private readonly _createPlugins: TPluginCreator
  ) {}

  createStory = <TComp extends Story<any>>(
    render?: TComp
  ): ReturnType<TStoryFactory['createStory']> & ReturnType<TPluginCreator> => {
    const builder = this._factory.createStory(render);
    return {
      ...this._createPlugins(builder),
      ...builder,
    } as any;
  };
}
