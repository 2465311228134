import { PropsWithChildren } from 'react';

import { StyledComponent, x } from '@ui-furniture/style';

export const OrderedList: StyledComponent<PropsWithChildren> = (props) => {
  return <x.ol listStyleType='decimal' pl='24px' py='md' {...props} />;
};

export const UnorderedList: StyledComponent<PropsWithChildren> = (props) => {
  return <x.ul listStyleType='disc' pl='24px' py='md' {...props} />;
};

export const ListItem: StyledComponent<PropsWithChildren> = (props) => {
  return <x.li {...props} />;
};
