export default {
  header: 80,
  input: '46px',
  button: 38,
  'side-bar-tile': 48,
  'content-width-2xs': 360,
  'content-width-xs': 520,
  'content-width-sm': 640,
  'content-width': 760,
  'content-width-lg': 980,
  'drawer-width': 560,
  'modal-width': 560,
  'section-height': 300,
  'screen-height': '100dvh',
};
