import { GqlErrorCode } from '@support/types/housecure-api';

import { GraphQLErrors } from '@apollo/client/errors';
import { onError as onErrorLink } from '@apollo/client/link/error';

import { identifyGqlError } from '../utils/identifiy-gql-error';

export const createHandleErrorLink = (
  code: GqlErrorCode,
  onError: (errors: GraphQLErrors) => void
) =>
  onErrorLink(({ graphQLErrors }) => {
    const matchingErrors = graphQLErrors?.filter(
      (error) => identifyGqlError(error).code === code
    );
    if (matchingErrors && matchingErrors.length > 0) {
      onError(matchingErrors);
    }
  });
