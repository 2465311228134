import { useEffect } from 'react';

export const useOnKeyUp = (onKeyUp: (event: KeyboardEvent) => void) => {
  useEffect(() => {
    function keyUpHandler(event: KeyboardEvent) {
      onKeyUp(event);
    }
    window.addEventListener('keyup', keyUpHandler, false);
    return () => window.removeEventListener('keyup', keyUpHandler);
  }, [onKeyUp]);
};
