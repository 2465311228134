import { useCallback } from 'react';

import { useStepContext, StepContainer } from '@/common/multi-step-flow';

import { LoadingButton } from '@ui-furniture/ui/loading/button';

import { KycForm } from './form';

import { useSubmitKycInfo } from '../hooks/use-submit-kyc-info';

export const FormStep = () => {
  const { next } = useStepContext();
  const onSuccess = useCallback(async () => {
    next();
  }, [next]);

  const { isLoading, submit } = useSubmitKycInfo({
    onSuccess,
  });

  return (
    <StepContainer>
      <KycForm
        submit={submit}
        buttons={
          <>
            <LoadingButton type='submit' isLoading={isLoading}>
              Save
            </LoadingButton>
          </>
        }
      />
    </StepContainer>
  );
};
