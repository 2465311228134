import { useCallback, useEffect, useMemo, useState } from 'react';

import { useCreateKycSessionMutation } from '@graphql/requests';

import { KycToken } from '@ui-furniture/common/kyc-token';

export const useKycSessionToken = () => {
  const [createKycSession, { data }] = useCreateKycSessionMutation();
  const [kycToken, setKycTokenState] = useState(new KycToken());

  const setKycToken = useCallback((value?: string) => {
    setKycTokenState(new KycToken(value));
  }, []);

  const refetch = useCallback(async () => {
    setKycToken();
    await createKycSession();
  }, []);

  useEffect(() => {
    refetch().catch((err) => {
      throw err;
    });
  }, []);

  useMemo(
    () => setKycToken(data?.createKycSession?.token),
    [data?.createKycSession?.token]
  );

  return {
    refetch,
    token: kycToken,
  };
};
