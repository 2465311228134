import { style } from '@xstyled/styled-components';

export interface FlexFillContentProps {
  flexFillContent?: boolean;
}

export const flexFillContent = style({
  prop: 'flexFillContent',
  css: () => ({
    display: 'flex',
    justifyContent: 'stretch',
    alignItems: 'stretch',
  }),
});
