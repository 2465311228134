import { css } from '@ui-furniture/style';

export const roundedButtonCss = css<{
  version?: 'primary' | 'secondary';
}>`
  display: inline-block;
  border-radius: default;
  transition: slow;
  font-weight: 600;
  font-family: mont;
  transition: default;
  font-size: button;
  line-height: button;

  box-shadow: 0px 2px 0px var(--box-shadow-color);
  transform: translateY(0);
  :hover,
  :focus {
    box-shadow: 0px 3px 0px var(--box-shadow-color);
    transform: translateY(-1px);
  }
  :active {
    box-shadow: 0px 0 0px var(--box-shadow-color);
    transform: translateY(2px);
    transition: fast;
  }
  :disabled {
    box-shadow: 0px 0 0px var(--box-shadow-color);
    transform: translateY(2px);
    transition: fast;
  }
  ${({ version }) => {
    switch (version) {
      case 'secondary':
        return css`
          --box-shadow-color: ${({ theme }) =>
            theme.colors['palette-secondary-border']};
          background-color: palette-secondary;
          color: palette-secondary-text;
          border: 1px solid var(--box-shadow-color);
          font-weight: 600;
          :active,
          :focus {
            color: palette-secondary-text;
          }
        `;
      case 'primary':
      default:
        return css`
          --box-shadow-color: ${({ theme }) =>
            theme.colors['palette-primary-border']};
          border: none;
          background-color: palette-primary;
          color: palette-primary-text;
          :active,
          :focus {
            color: palette-primary-text;
          }
          :disabled {
            color: rgba(255, 255, 255, 0.6);
          }
        `;
    }
  }}
`;
