import React from 'react';

import { x } from '@xstyled/styled-components';
import { SnackbarContent, SnackbarProviderProps } from 'notistack';

import { HStack, Text } from '@ui-furniture/elements';
import { SolidIcon } from '@ui-furniture/ui/solid-icon';

const variants = {
  success: {
    border: '2px solid',
    borderColor: 'brand-success',
  },
  info: {
    border: '2px solid',
    borderColor: 'brand-text',
  },
  warning: {
    border: '2px solid',
    borderColor: 'brand-warn',
  },
  error: {
    border: '2px solid',
    borderColor: 'brand-error',
  },
};

interface ToastComponentProps extends SnackbarProviderProps {
  message: string;
  variant: keyof typeof variants;
}

export const ToastComponent = React.forwardRef<
  HTMLDivElement,
  ToastComponentProps
>((props, ref) => {
  const { message, variant, style } = props;

  return (
    <SnackbarContent ref={ref} style={{ ...style, justifyContent: 'flex-end' }}>
      <x.div
        backgroundColor='white'
        borderRadius='sm'
        padding='md'
        gap='sm'
        boxShadow='4px 4px 10px 0px rgba(65, 79, 101, 0.15)'
        {...variants[variant]}
      >
        <HStack alignItems='center'>
          <SolidIcon name={variant} size='24px' />
          <Text> {message}</Text>
        </HStack>
      </x.div>
    </SnackbarContent>
  );
});
