import { z } from 'zod';

import { zod } from '@ui-furniture/lib/utils/zod-validation';

export const kycSchema = z.object({
  firstName: z
    .string()
    .nonempty({ message: 'Please provide your first name' })
    .min(2, { message: 'The first name is too short' }),
  middleNames: z.string(),
  lastName: z
    .string()
    .nonempty({ message: 'Please provide your last name' })
    .min(2, { message: 'The last name is too short' }),
  dob: zod.date(),
  address: zod.address(),
});
