import { KycSteps } from '@ui-furniture/common/kyc';

export const applySteps: KycSteps = [
  {
    type: 'document',
    options: {
      documentTypes: {
        driving_licence: true,
        passport: true,
        national_identity_card: true,
        residence_permit: true,
      },
      useLiveDocumentCapture: true,
      uploadFallback: true,
    },
  },
  {
    type: 'face',
  },
];
