/* eslint-disable @typescript-eslint/member-ordering */
import { PluginConfig } from '../types/plugin-config';

export class StorybookPlugin<T> {
  constructor(private readonly _builder: PluginConfig<T>) {}
  protected get _definition() {
    return this._builder.definition;
  }
  get and() {
    return this._builder;
  }
}
