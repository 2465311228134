type KeyName = 'esc';

export class KeyIdentifier {
  static identifyFromEvent = (event: KeyboardEvent) => new KeyIdentifier(event);

  keyName = this._getKeyName();

  isEsc = this.keyName === 'esc';

  private constructor(private readonly _event: KeyboardEvent) {}

  private _getKeyName(): KeyName | null {
    switch (this._event.keyCode) {
      case 27:
        return 'esc';
      default:
        return null;
    }
  }
}
