import { FC, PropsWithChildren } from 'react';

import { useDashboard } from '@/features/layout/state/dashboard';

import { Button, HStack } from '@ui-furniture/elements';
import { ParentAnimation } from '@ui-furniture/ui/animation/parent';
import { Container } from '@ui-furniture/ui/layout/container';
import { LineIcon } from '@ui-furniture/ui/line-icon';

export const DrawerContent: FC<
  PropsWithChildren<{
    variant?:
      | 'full-screen'
      | 'full-screen-center'
      | 'absolute-overlay'
      | 'content';
    disableClose?: boolean;
  }>
> = ({ variant = 'full-screen-center', disableClose, children, ...rest }) => {
  const { closeDrawer } = useDashboard();

  return (
    <ParentAnimation backgroundColor='palette-bg' w='100%'>
      {!disableClose && (
        <HStack
          justifyContent='flex-end'
          w='100%'
          position='absolute'
          top='0'
          right='0'
        >
          <Button
            type='button'
            variant='blank'
            onClick={closeDrawer}
            textAlign='right'
            p='md'
            display='flex'
          >
            <LineIcon name='close' size='md' m='md' />
          </Button>
        </HStack>
      )}
      <Container variant={variant} {...rest} backgroundColor='palette-bg'>
        {children}
      </Container>
    </ParentAnimation>
  );
};
