import { FormInputComponent, FormInput } from '@ui-furniture/lib/form';

import {
  AutoCompleteType,
  getAutocompleteValues,
} from './get-autocomplete-values';
import { SuggestionField } from './suggestion-field';
import { useAddressFieldsRegistration } from './use-address-fields-registration';

import { Label, LabelText, ErrorMessage } from '../common';
import { BlankInput } from '../common/blank-input';
import {
  FieldGroupContainer,
  FieldGroupColumns,
  FieldGroupCell,
} from '../common/field-group';

export const AddressInput: FormInputComponent<{
  label: string;
  autoCompleteType?: AutoCompleteType;
  isOptional?: boolean;
}> = ({ label, autoCompleteType = 'none', isOptional = false, name }) => {
  const { error, fields } = useAddressFieldsRegistration(name);
  const autocompleteValues = getAutocompleteValues(autoCompleteType);
  const { line2, line3, city, postcode } = fields;

  return (
    <FormInput error={error}>
      <Label>
        <LabelText isOptional={isOptional}>{label}</LabelText>
        <FieldGroupContainer>
          <FieldGroupCell $hasBottomBorder>
            <SuggestionField
              placeholder='House name or number'
              autoComplete={autocompleteValues.line1}
              fields={fields}
            />
          </FieldGroupCell>
          <FieldGroupCell $hasBottomBorder>
            <BlankInput
              placeholder='Address line 2'
              autoComplete={autocompleteValues.line2}
              {...line2.registration}
            />
          </FieldGroupCell>
          <FieldGroupCell $hasBottomBorder>
            <BlankInput
              placeholder='Address line 3'
              autoComplete={autocompleteValues.line3}
              {...line3.registration}
            />
          </FieldGroupCell>
          <FieldGroupColumns>
            <FieldGroupCell $hasRightBorder flex={1}>
              <BlankInput
                placeholder='City'
                autoComplete={autocompleteValues.city}
                {...city.registration}
              />
            </FieldGroupCell>
            <FieldGroupCell flex={1}>
              <BlankInput
                placeholder='Postcode'
                autoComplete={autocompleteValues.postCode}
                {...postcode.registration}
              />
            </FieldGroupCell>
          </FieldGroupColumns>
        </FieldGroupContainer>
        <ErrorMessage />
      </Label>
    </FormInput>
  );
};
