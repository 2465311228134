import { useEffect } from 'react';

import { FieldValues, UseFormReset, UseFormReturn } from 'react-hook-form';

export const useResetOnSuccess = <TFieldValues extends FieldValues>(
  formHandler: UseFormReturn<TFieldValues>,
  isDisabled = false,
  ...resetConfig: Parameters<UseFormReset<TFieldValues>>
) => {
  useEffect(() => {
    if (formHandler.formState.isSubmitSuccessful && !isDisabled) {
      formHandler.reset(...resetConfig);
    }
  }, [formHandler.formState, resetConfig, isDisabled]);
};
