import { FC, PropsWithChildren, createContext, useContext } from 'react';

import { AuthStatus } from '@graphql/requests';

import { errorService } from '@ui-furniture/common/services/error';
import { useAuthStatus } from '@ui-furniture/hooks/use-auth-status';
import { useFlowControls } from '@ui-furniture/hooks/use-flow-controls';
import useRouterState from '@ui-furniture/lib/hooks/useRouterState';

import { DrawerOption } from '../types/drawer-option';

const context = createContext<{
  activeDrawer: DrawerOption | undefined;
  setActiveDrawer: (option: DrawerOption) => void;
  closeDrawer: () => void;
  toggleDrawer: (option: DrawerOption) => void;
  openSignUpDrawer: () => boolean;
}>(null as any);

export const DashboardProvider: FC<PropsWithChildren> = ({ children }) => {
  const authStatus = useAuthStatus();
  const { resetFlow } = useFlowControls();

  const [activeDrawer, setActiveDrawer] =
    useRouterState<DrawerOption>('drawer');

  const openSignUpDrawer = () => {
    switch (authStatus) {
      case AuthStatus.AnonymousVisitor:
      case AuthStatus.None:
        setActiveDrawer('sign-up');
        return true;
      case AuthStatus.IdentifiedVisitor:
        setActiveDrawer('upgrade');
        return true;
      default:
        errorService.captureMessage('User is already logged in');
        return false;
    }
  };
  const closeDrawer = () => {
    resetFlow();
    setActiveDrawer();
  };
  const toggleDrawer = (option: DrawerOption) => {
    if (activeDrawer === option) {
      closeDrawer();
    } else {
      setActiveDrawer(option);
    }
  };

  return (
    <context.Provider
      value={{
        activeDrawer,
        setActiveDrawer,
        closeDrawer,
        toggleDrawer,
        openSignUpDrawer,
      }}
    >
      {children}
    </context.Provider>
  );
};

export const useDashboard = () => {
  const c = useContext(context);
  if (!c) {
    throw new Error('useDashboard must be used within DashboardProvider');
  }
  return c;
};
