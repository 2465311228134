import { StyledComponent, styled, x } from '@ui-furniture/style';

export const TableBox = styled.tableBox`
  border: 1px solid;
  border-color: palette-border;
  border-radius: lg;
  border-spacing: 0;
  overflow: hidden;
  width: 100%;
`;

export const TableHead = styled.theadBox`
  overflow: scroll;
`;

export const HeaderRow = styled.trBox`
  border-bottom: 1px solid;
  border-color: palette-border;
  background-color: palette-subtle;
`;

export const HeaderCell = styled.thBox`
  padding: md;
  vertical-align: top;
  border-right: 1px solid;
  border-color: palette-border;
  :last-child {
    border-right: 0;
  }
`;

export const TableBody = styled.tbodyBox``;

export const TableRow = styled.trBox`
  border-bottom: 1px solid;
  border-color: palette-border;
  :last-child {
    border-bottom: 0;
  }

  background-color: brand-bright-white;
  &:nth-child(even) {
    background-color: palette-subtle;
  }
`;

export const TableCell = styled.tdBox`
  padding: md;
  vertical-align: top;
  border-right: 1px solid;
  border-color: palette-border;
  :last-child {
    border-right: 0;
  }
`;

interface TableProps {
  thead?: string[];
  tbody: { [key: string]: any }[];
}

export const Table: StyledComponent<TableProps> = ({
  thead,
  tbody,
  ...rest
}) => {
  return (
    <x.div py='md' w='0' minWidth='100%' overflow='auto' {...rest}>
      <TableBox>
        {thead && (
          <TableHead>
            <HeaderRow>
              {thead.map((column) => {
                return <HeaderCell key={column}>{column}</HeaderCell>;
              })}
            </HeaderRow>
          </TableHead>
        )}
        <TableBody>
          {tbody.map((row, i) => {
            return (
              <TableRow key={i}>
                {row.map((cell, j) => {
                  return <TableCell key={j}>{cell}</TableCell>;
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </TableBox>
    </x.div>
  );
};
