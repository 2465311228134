import { useCallback } from 'react';

import { CallbackNames } from '@ui-furniture/common/flow-manager/types';
import { useFlowControls } from '@ui-furniture/hooks/use-flow-controls';

import { StepStatus } from '../types/step-status';

export const useStepControls = ({
  status: { pos, isFirst, isLast },
  flowName,
  onComplete,
  increment,
  decrement,
}: {
  status: StepStatus;
  flowName?: CallbackNames;
  onComplete?: () => void;
  increment: (pos: number) => void;
  decrement: () => void;
}) => {
  const { moveToStep } = useFlowControls();

  const prev = useCallback(() => {
    if (!isFirst) {
      decrement();
      moveToStep(pos - 1, flowName);
    }
  }, []);
  const next = useCallback(() => {
    if (!isLast) {
      moveToStep(pos + 1, flowName);
      increment(pos + 1);
    } else {
      onComplete?.();
    }
  }, []);
  const setPosition = useCallback((x: number) => {
    moveToStep(x, flowName);
    increment(x);
  }, []);

  return {
    next,
    prev,
    setPosition,
  };
};
