import { FC } from 'react';

import { useDashboard } from '@/features/layout/state/dashboard';

import { useAnalyticsReportingService } from '@ui-furniture/common/analytics';
import { HStack, VStack, Text } from '@ui-furniture/elements';
import { Clickable } from '@ui-furniture/ui/clickable';
import { LineIcon } from '@ui-furniture/ui/line-icon';
import { SolidIcon } from '@ui-furniture/ui/solid-icon';

type Props = {
  withIcon?: boolean;
};

export const UpgradeAccountBanner: FC<Props> = ({ withIcon }) => {
  const { setActiveDrawer } = useDashboard();
  const { reportEvent } = useAnalyticsReportingService();

  return (
    <Clickable
      w='100%'
      action={{
        type: 'button',
        onClick: () => {
          reportEvent({
            name: 'Click',
            on: 'Upgrade account banner',
          });
          setActiveDrawer('upgrade');
        },
      }}
    >
      <HStack
        p='md'
        bg='brand-orange'
        color='brand-white'
        alignItems='center'
        borderRadius='sm'
      >
        {withIcon && <SolidIcon name='profileSignedIn' size='lg' pr='sm' />}
        <VStack justifyContent='center' textAlign='left' spacing='lg'>
          <HStack alignItems='center' justifyContent='space-between'>
            <Text variant='button' color='white'>
              Upgrade to a full account for free
            </Text>
            <LineIcon name='arrowRightCircleFull' size='sm' />
          </HStack>
          <Text variant='smallComment' color='white'>
            You currently have a visitor account, all you need to get access to
            more features is to accept our terms and conditions.
          </Text>
        </VStack>
      </HStack>
    </Clickable>
  );
};
