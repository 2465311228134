import { useCallback } from 'react';

import { useRouter } from 'next/router';

export const useRefreshSsrData = () => {
  const router = useRouter();
  return useCallback(() => {
    return router.replace(router.asPath);
  }, [router.asPath]);
};
