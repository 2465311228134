import { motion } from 'framer-motion';

import { styled } from '@ui-furniture/style';

import CloseIcon from '../assets/Close.svg';
import { ModalVariant } from '../types/modal-variant';

export const flow: ModalVariant = {
  Container: styled(motion.div)`
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: secondaryText;
    z-index: modal;
    overflow-y: scroll;
    background-color: transparent;
    background-color: primary;
  `,
  Overlay: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: background;
    pointer-events: none;
  `,
  CloseButton: styled(CloseIcon)`
    position: fixed;
    z-index: foreground;
    top: 0;
    right: 0;
    width: 24px;
    height: 24px;
    margin-top: 18px;
    margin-right: 18px;
    cursor: pointer;
  `,
  Content: styled.divBox`
    position: relative;
    width: 100%;
    background: transparent;
    z-index: infront;
    padding: xl lg;
    display: flex;
    justify-content: center;
    min-height: 100%;
  `,
  animations: {
    container: {
      visible: {
        opacity: 1,
      },
      hidden: {
        opacity: 0,
      },
    },
    content: {
      visible: {
        top: 0,
      },
      hidden: {
        top: 10,
      },
    },
  },
};
