import { ComponentProps } from 'react';

import { styled } from '@/style';

const Path = styled.pathBox`
  transition: base;
`;
type Color = ComponentProps<typeof Path>['fill'];

export const AccountIcon = ({
  borderColor,
  profileColor,
}: {
  borderColor?: Color;
  profileColor?: Color;
}) => {
  return (
    <svg
      width='42'
      height='43'
      viewBox='0 0 42 43'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#filter0_i_1742_5161)'>
        <g filter='url(#filter1_i_1742_5161)'>
          <Path
            d='M41.32 21.48C41.32 10.3473 32.2948 1.32001 21.16 1.32001C10.0251 1.32001 1 10.3473 1 21.48C1 32.6148 10.0251 41.64 21.16 41.64C32.2948 41.64 41.32 32.6148 41.32 21.48Z'
            fill='#F9F8F4'
          />
        </g>
        <g filter='url(#filter2_d_1742_5161)'>
          <Path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M27.2491 19.9132C27.2491 23.2761 24.5226 26.0026 21.1597 26.0026C17.7968 26.0026 15.0703 23.2761 15.0703 19.9132C15.0703 16.5503 17.7968 13.8238 21.1597 13.8238C24.5226 13.8238 27.2491 16.5503 27.2491 19.9132Z'
            fill={profileColor}
          />
        </g>
        <mask
          id='mask0_1742_5161'
          style={{ maskType: 'alpha' }}
          maskUnits='userSpaceOnUse'
          x='1'
          y='1'
          width='41'
          height='41'
        >
          <Path
            d='M41.32 21.48C41.32 10.3473 32.2948 1.32001 21.16 1.32001C10.0251 1.32001 1 10.3473 1 21.48C1 32.6148 10.0251 41.64 21.16 41.64C32.2948 41.64 41.32 32.6148 41.32 21.48Z'
            fill={profileColor}
          />
        </mask>
        <g mask='url(#mask0_1742_5161)'>
          <g filter='url(#filter3_d_1742_5161)'>
            <Path
              d='M10.6406 38.6288C11.0591 35.3748 13.9534 31.7612 21.1413 31.7612C28.4054 31.7612 31.28 35.3945 31.6812 38.6878'
              fill={profileColor}
            />
            <Path
              d='M10.6406 38.6288C11.0591 35.3748 13.9534 31.7612 21.1413 31.7612C28.4054 31.7612 31.28 35.3945 31.6812 38.6878C24.6299 45.5334 17.3028 45.6678 10.6406 38.6288Z'
              fill={profileColor}
            />
          </g>
        </g>
        <Path
          d='M41.32 21.48C41.32 10.3473 32.2948 1.32001 21.16 1.32001C10.0251 1.32001 1 10.3473 1 21.48C1 32.6148 10.0251 41.64 21.16 41.64C32.2948 41.64 41.32 32.6148 41.32 21.48Z'
          stroke={borderColor}
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </g>
      <defs>
        <filter
          id='filter0_i_1742_5161'
          x='0.5'
          y='0.820007'
          width='45.3203'
          height='45.32'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='4' dy='4' />
          <feGaussianBlur stdDeviation='2' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.254902 0 0 0 0 0.309804 0 0 0 0 0.396078 0 0 0 0.15 0'
          />
          <feBlend
            mode='normal'
            in2='shape'
            result='effect1_innerShadow_1742_5161'
          />
        </filter>
        <filter
          id='filter1_i_1742_5161'
          x='1'
          y='1.32001'
          width='44.3203'
          height='44.32'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='4' dy='4' />
          <feGaussianBlur stdDeviation='2' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.254902 0 0 0 0 0.309804 0 0 0 0 0.396078 0 0 0 0.15 0'
          />
          <feBlend
            mode='normal'
            in2='shape'
            result='effect1_innerShadow_1742_5161'
          />
        </filter>
        <filter
          id='filter2_d_1742_5161'
          x='15.0703'
          y='13.8238'
          width='20.1797'
          height='20.1788'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='4' dy='4' />
          <feGaussianBlur stdDeviation='2' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.254902 0 0 0 0 0.309804 0 0 0 0 0.396078 0 0 0 0.15 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_1742_5161'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_1742_5161'
            result='shape'
          />
        </filter>
        <filter
          id='filter3_d_1742_5161'
          x='10.6406'
          y='31.7612'
          width='29.0391'
          height='20.1038'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='4' dy='4' />
          <feGaussianBlur stdDeviation='2' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.254902 0 0 0 0 0.309804 0 0 0 0 0.396078 0 0 0 0.15 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_1742_5161'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_1742_5161'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
  );
};
