import { AuthStatus } from '@graphql/requests';

import { Button, Hr, Text, VStack } from '@ui-furniture/elements';
import { useAuthStatus } from '@ui-furniture/hooks/use-auth-status';
import { BasicMessage } from '@ui-furniture/ui/basic-message';
import { Disclaimer } from '@ui-furniture/ui/disclaimer';
import { Container } from '@ui-furniture/ui/layout/container';

import { useSignIn } from '../hooks/use-sign-in';
import { CodeVerificationForm } from '../ui/code-verification-form';
import { SignInForm } from '../ui/sign-in-form';

export const SignInView = ({
  onSuccess,
  openSignUp,
}: {
  openSignUp: () => void;
  onSuccess?: () => void;
}) => {
  const authStatus = useAuthStatus();
  const hasAssociatedData = authStatus === AuthStatus.AnonymousVisitor;

  const {
    hasToken,
    requestToken,
    isRequestingToken,
    verifyCode,
    isVerifyingCode,
  } = useSignIn({
    onSuccess: () => {
      onSuccess?.();
    },
  });

  return (
    <Container variant='content' maxW='lg'>
      {!hasToken ? (
        <VStack textAlign='center' alignItems='center' spacing='lg' w='100%'>
          <Text variant='h3' as='h1'>
            Sign In
          </Text>
          {hasAssociatedData ? (
            <Disclaimer type='warn'>
              Please be aware the information you’ve given won’t be linked to
              your existing account.
            </Disclaimer>
          ) : null}
          <SignInForm submit={requestToken} isSubmitting={isRequestingToken} />
          <Hr />
          <VStack spacing='md'>
            <Text variant='body'>Don’t have an account yet?</Text>
            <Button
              type='button'
              variant='simpleLink'
              onClick={() => openSignUp()}
            >
              Sign Up
            </Button>
          </VStack>
        </VStack>
      ) : (
        <BasicMessage iconName='paperPlane' title='Enter your 6 digit code'>
          <CodeVerificationForm
            submit={verifyCode}
            isSubmitting={isVerifyingCode}
          />
        </BasicMessage>
      )}
    </Container>
  );
};
