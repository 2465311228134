import { FC } from 'react';

import { z } from 'zod';

import {
  BasicForm,
  Checkbox,
  Input,
  SubmitFormHandler,
  useForm,
} from '@ui-furniture/common/form';
import { Stack } from '@ui-furniture/elements';
import { useFullResetOnSuccess } from '@ui-furniture/lib/form';
import { useFocusByIdOnMount } from '@ui-furniture/lib/hooks/use-focus-by-id-on-mount';
import { x } from '@ui-furniture/style';
import { LoadingButton } from '@ui-furniture/ui/loading/button';
import { TermsAndConditionsText } from '@ui-furniture/ui/terms-and-conditions-text';

const upgradeAccountSchema = z.object({
  firstName: z.string().min(2, 'You name must be at least 2 letters'),
  lastName: z.string().min(2, 'You name must be at least 2 letters'),
  preferredName: z
    .string()
    .min(2, 'You name must be at least 2 letters')
    .optional(),
  subscribeToNewsletter: z.boolean(),
  acceptTermsAndConditions: z.boolean().refine((value) => value === true, {
    message: 'You need to accept the terms and conditions to create an account',
  }),
});

export type UpgradeAccountFormHandler = SubmitFormHandler<
  typeof upgradeAccountSchema
>;

export const UpgradeAccountForm: FC<{
  submit: UpgradeAccountFormHandler;
  isSubmitting: boolean;
}> = ({ submit, isSubmitting }) => {
  const formHandler = useForm(upgradeAccountSchema);
  useFullResetOnSuccess(formHandler);
  const id = useFocusByIdOnMount();

  return (
    <BasicForm schema={upgradeAccountSchema} onSubmit={submit}>
      <Stack flexDirection={{ _: 'column', phablet: 'row' }} w='100%'>
        <x.div flex='1'>
          <Input
            id={id}
            name='firstName'
            label='First Name'
            autoComplete='given-name'
            placeholder='What is your first name?'
          />
        </x.div>
        <x.div flex='1'>
          <Input
            name='lastName'
            label='Last Name'
            autoComplete='family-name'
            placeholder='What is your last name?'
          />
        </x.div>
      </Stack>
      <Checkbox
        name='subscribeToNewsletter'
        label='Keep me updated with useful information and news'
      />
      <Checkbox
        name='acceptTermsAndConditions'
        label={<TermsAndConditionsText />}
      />
      <LoadingButton type='submit' isLoading={isSubmitting}>
        Upgrade
      </LoadingButton>
    </BasicForm>
  );
};
