import { ComponentProps, PropsWithChildren } from 'react';

import { x } from '@xstyled/styled-components';
import { FieldValues, FormProvider, UseFormReturn } from 'react-hook-form';

export function Form<TForm extends FieldValues>({
  form,
  ...rest
}: PropsWithChildren<
  {
    form: UseFormReturn<TForm>;
  } & ComponentProps<typeof x.form>
>) {
  return (
    <FormProvider {...form}>
      <x.form w='100%' maxWidth='content-width-xs' {...rest} />
    </FormProvider>
  );
}
