import { motion } from 'framer-motion';

import { styled } from '@ui-furniture/style';

import CloseIcon from '../assets/Close.svg';
import { ModalVariant } from '../types/modal-variant';

export const popover: ModalVariant = {
  Container: styled(motion.div)`
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: modal;
    overflow-y: scroll;
    background-color: transparent;
    min-height: screen-height;
    padding: xl md;
  `,
  Overlay: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: overlay;
    cursor: pointer;
  `,
  CloseButton: styled(CloseIcon)`
    position: absolute;
    z-index: foreground;
    top: 0;
    right: 0;
    width: 12px;
    height: 12px;
    margin-top: 18px;
    margin-right: 18px;
    cursor: pointer;
  `,
  Content: styled.divBox`
    position: relative;
    max-width: modal-width;
    width: 100%;
    margin: auto;
    z-index: infront;
    border-radius: lg;
    background-color: brand-white;
    padding: 2xl;
    display: flex;
    flex-direction: column;
    justify-content: center;
  `,

  animations: {
    container: {
      visible: {
        opacity: 1,
      },
      hidden: {
        opacity: 0,
      },
    },
    content: {
      visible: {
        top: 0,
      },
      hidden: {
        top: 10,
      },
    },
  },
};
