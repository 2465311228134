import { AuthStatus } from '@graphql/requests';

import { Hr, Button, Text, VStack } from '@ui-furniture/elements';
import { useAuthStatus } from '@ui-furniture/hooks/use-auth-status';
import { BasicMessage } from '@ui-furniture/ui/basic-message';
import { Container } from '@ui-furniture/ui/layout/container';

import { useSignUp } from '../hooks/use-sign-up';
import { CodeVerificationForm } from '../ui/code-verification-form';
import { SignUpForm } from '../ui/sign-up-form';

export const SignUpView = ({
  onSuccess,
  openSignIn,
}: {
  openSignIn: () => void;
  onSuccess?: () => void;
}) => {
  const authStatus = useAuthStatus();
  const hasAssociatedData = authStatus === AuthStatus.AnonymousVisitor;

  const {
    requestToken,
    isRequestingToken,
    hasToken,
    verifyCode,
    isVerifyingCode,
  } = useSignUp({
    onSuccess: () => {
      onSuccess?.();
    },
  });

  return (
    <Container variant='content'>
      {!hasToken ? (
        <VStack textAlign='center' spacing='lg'>
          <Text variant='h3' as='h1'>
            Sign Up
          </Text>
          <SignUpForm
            submit={requestToken}
            isSubmitting={isRequestingToken}
            hasAssociatedData={hasAssociatedData}
          />
          <Hr />
          <VStack spacing='md'>
            <Text variant='body'>Already have an account?</Text>
            <Button
              type='button'
              variant='simpleLink'
              onClick={() => openSignIn()}
            >
              Sign In
            </Button>
          </VStack>
        </VStack>
      ) : (
        <BasicMessage iconName='paperPlane' title='Enter your 6 digit code'>
          <CodeVerificationForm
            submit={verifyCode}
            isSubmitting={isVerifyingCode}
          />
        </BasicMessage>
      )}
    </Container>
  );
};
