import { useGetPurchasesQuery } from '@graphql/requests';

import { useCurrentUser } from './use-current-user';

export const usePurchases = () => {
  const { isAuthenticated } = useCurrentUser();

  const { data, loading } = useGetPurchasesQuery({
    skip: !isAuthenticated,
    fetchPolicy: 'cache-and-network',
  });

  return {
    loading,
    purchases: data?.purchases || [],
  };
};
