import config from '@ui-furniture/config';

const marketingDomain = 'https://housecure.co.uk';
const helpDomain = 'https://housecure-helpdesk.super.site';

const removeLeadingSlash = (path: string) =>
  path.startsWith('/') ? path.slice(1) : path;

export const PATHS = {
  webApp: {
    signIn: '/?drawer=sign-in',
    signUp: '/?drawer=sign-up',
    dashboard: '/',
    launchpad: '/launch',
    profileSelect: '/profile/select',
    kycView: '/kyc/view',
    conveyancing: '/conveyancing/recommendation/create',
    createPurchase: '/properties/purchase/create',
    createSale: '/properties/sale/create',
    createMove: '/properties/move/create',
    properties: '/properties',
    salesPacks: '/seller/packs',
    stampDuty: '/stamp-duty',
    generalSurveys: '/survey/organise',
    specialistSurveys: '/survey/organise?type=specialist',
    createRoute: {
      fullUrl: (relativePath: string) =>
        `${config.baseUrl}/${removeLeadingSlash(relativePath)}`,
      property: (collectionKey: string) => `/properties/${collectionKey}`,
      createOffer: ({ collectionKey }: { collectionKey: string }) =>
        `/properties/${collectionKey}/offer/create`,
      viewOffer: (id: number) => `/view/offer/${id}`,
      acceptOffer: (id: number) => `/view/offer/${id}/accept`,
      declineOffer: (id: number) => `/view/offer/${id}/decline`,
    },
  },
  helpDesk: {
    home: `${marketingDomain}/help/home`,
    protect: `${marketingDomain}/help/protect`,
    suppliers: `${helpDomain}/suppliers`,
    referralFees: `${helpDomain}/suppliers/referral-fees`,
    cashback: `${helpDomain}/suppliers/cashback-scheme`,
  },
  marketingSite: {
    home: marketingDomain,
    termsAndConditions: `${marketingDomain}/legals/terms-and-conditions`,
    privacyPolicy: `${marketingDomain}/legals/privacy-policy`,
    cookiePolicy: `${marketingDomain}/legals/cookie-policy`,
    services: `${marketingDomain}/services`,
    protect: `${marketingDomain}/services/protect`,
  },
};
