import { styled } from '@ui-furniture/style';

export const BlurContainer = styled.divBox<{
  $isBlurred: boolean;
}>`
  transition: base;
  height: screen-height;
  width: 100%;
  ${({ $isBlurred }) =>
    $isBlurred ? `filter: blur(3px);` : `filter: blur(0);`}
`;
