export default {
  xs: 5,
  sm: 7,
  md: 12,
  lg: 19,
  xl: 31,
  '2xl': 51,
  '3xl': 82,
  '4xl': 133,
  '5xl': 215,
};
