export default {
  background: -20,
  behind: -10,
  infront: 10,
  foreground: 20,
  overlay: 30,
  drawer: 40,
  modal: 50,
  toast: 60,
  popover: 70,
};
