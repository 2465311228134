import { FC, PropsWithChildren } from 'react';

import GlobalStyles from '@ui-furniture/common/GlobalStyles';
import {
  ThemeProvider,
  Preflight,
  theme as defaultTheme,
} from '@ui-furniture/style';

import { MuiProvider } from '../mui';

export const StyleProvider: FC<
  PropsWithChildren<{
    theme?: typeof defaultTheme;
  }>
> = ({ theme = defaultTheme, children }) => (
  <ThemeProvider theme={theme}>
    <Preflight />
    <GlobalStyles />
    <MuiProvider>{children}</MuiProvider>
  </ThemeProvider>
);
