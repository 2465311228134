import { ComponentProps, FC } from 'react';

import { Typeahead } from '@ui-furniture/common/react-select';

import { AddressFields } from './use-address-fields-registration';
import { useFetchAddressSuggestions } from './use-fetch-address-suggestions';
import { useSelectAddress } from './use-select-address';

type TypeaheadProps = ComponentProps<typeof Typeahead>;
type SuggestionsProps = Omit<TypeaheadProps, 'setInputValue' | 'inputValue'> & {
  fields: AddressFields;
};

export const SuggestionField: FC<SuggestionsProps> = ({ fields, ...rest }) => {
  const { value, setValue } = fields.line1;
  const selectAddress = useSelectAddress(fields);
  const fetchAddressSuggestions = useFetchAddressSuggestions();

  return (
    <Typeahead
      {...rest}
      cacheOptions
      loadOptions={fetchAddressSuggestions}
      onChange={selectAddress}
      inputValue={value}
      setInputValue={setValue}
      noOptionsMessage={() => null}
    />
  );
};
