import { Fragment } from 'react';

import { FieldState } from './types/field-state';

export const createErrorListFromFields = (fields: Array<FieldState>) => {
  const errorMessages = fields
    .filter((field) => !!field.error)
    .map((field) => field.error?.message);

  if (errorMessages.length === 0) return null;
  return (
    <>
      {errorMessages.map((error) => (
        <Fragment key={error}>
          {error}
          <br />
        </Fragment>
      ))}
    </>
  );
};
