import { motion } from 'framer-motion';

import { MotionComponentWithStyledProps, x } from '@/style';

import { movement } from '@ui-furniture/lib/utils';

export const StepComponentAnimator: MotionComponentWithStyledProps = ({
  children,
  ...rest
}) => (
  <x.div
    {...rest}
    as={motion.div}
    variants={{
      stepShow: {
        opacity: 1,
        y: 0,
      },
      stepHide: {
        opacity: 0,
        y: movement.sm,
      },
    }}
  >
    {children}
  </x.div>
);
